import { TFunction } from "i18next";

import { clsx } from "core";
import { Container, Typo } from "components";

interface AccountManagementProps {
  t: TFunction;
  status: boolean;
  handleDelete: () => Promise<void>;
}
export function AccountManagement({
  t,
  status,
  handleDelete,
}: AccountManagementProps) {
  return (
    <Container className="rounded-xl">
      <div className="flex w-full items-center justify-between">
        <Typo
          type="wlc_inter_l"
          children="Votre statut"
          className="font-bold uppercase tracking-tight text-primary-600"
        />
        <div
          className={clsx(
            status ? "bg-primary-500" : "border-2 border-primary-300 bg-white",
            "w-fit rounded-lg px-3 py-2"
          )}
        >
          <Typo
            type="wlc_inter_m"
            children={status ? "Premium" : "Basic"}
            className={clsx(
              "font-semibold tracking-tighter",
              status ? "text-white" : "text-primary-300"
            )}
          />
        </div>
      </div>
      <div>
        <Typo
          onClick={handleDelete}
          className="w-fit cursor-pointer rounded-lg p-1 text-sm font-medium tracking-tight hover:bg-neutral-200"
        >
          {t("wordings.delete_account")}
        </Typo>
      </div>
    </Container>
  );
}
