import { VFC } from "react";
import { FieldValues } from "react-hook-form";

import { routes } from "routing";
import { Button, Widget } from "components";
import { FormBuilder } from "components/formBuilder";
import { setPatrimonialGoalsMutation } from "core/queries/mutation";
import {
  Page,
  IYield,
  InvestmentType,
  FormBuilderProps,
  InvestmentHorizon,
} from "@types";
import { NormalizeTranslationKeys } from "i18n";

// investmentCategories
const categoriesData: Record<string, NormalizeTranslationKeys> = {
  fructifier: "page.heritageObjectives.fructifier.label",
  rentes: "page.heritageObjectives.rentes.label",
  dynamiser_capital: "page.heritageObjectives.dynamiser_capital.label",
  retraite: "page.heritageObjectives.retraite.label",
  transmission_patrimoine:
    "page.heritageObjectives.transmission_patrimoine.label",
  solder_pret: "page.heritageObjectives.solder_pret.label",
  construire_patrimoine: "page.heritageObjectives.construire_patrimoine.label",
  millionaire: "page.heritageObjectives.millionaire.label",
};

// RENDEMENT
const yieldData: Record<string, NormalizeTranslationKeys> = {
  [IYield.low]: "page.heritageObjectives.yieldData.low",
  [IYield.medium]: "page.heritageObjectives.yieldData.medium",
  [IYield.high]: "page.heritageObjectives.yieldData.high",
};
// HORIZON DE PLACEMENT
const retraitCapitalData: Record<string, NormalizeTranslationKeys> = {
  [InvestmentHorizon.long]: "page.heritageObjectives.retraitCapitalData.long",
  [InvestmentHorizon.medium]:
    "page.heritageObjectives.retraitCapitalData.medium",
  [InvestmentHorizon.short]: "page.heritageObjectives.retraitCapitalData.short",
};
const aRendementData: Record<
  string,
  { label: NormalizeTranslationKeys; value: string }
> = {
  Crypto: {
    value: InvestmentType.dynamic,
    label: "page.heritageObjectives.aRendementData.crypto.label",
  },
  Bourse: {
    value: InvestmentType.dynamic,
    label: "page.heritageObjectives.aRendementData.bourse.label",
  },
  ISR: {
    value: InvestmentType.neutral,
    label: "page.heritageObjectives.aRendementData.isr.label",
  },
  Exotique: {
    value: InvestmentType.dynamic,
    label: "page.heritageObjectives.aRendementData.exotique.label",
  },
  "ETF/Forex": {
    value: InvestmentType.neutral,
    label: "page.heritageObjectives.aRendementData.etfForex.label",
  },
  "Start-up": {
    value: InvestmentType.dynamic,
    label: "page.heritageObjectives.aRendementData.startUp.label",
  },
  "Immo locatif": {
    value: InvestmentType.judicious,
    label: "page.heritageObjectives.aRendementData.immo.label",
  },
  SCPI: {
    value: InvestmentType.judicious,
    label: "page.heritageObjectives.aRendementData.scpi.label",
  },
  "épargne de précaution": {
    value: InvestmentType.judicious,
    label: "page.heritageObjectives.aRendementData.epargneDePrecaution.label",
  },
};
const bRendementData: Record<string, NormalizeTranslationKeys> = {
  [InvestmentType.judicious]:
    "page.heritageObjectives.bRendementData.judicious",
  [InvestmentType.neutral]: "page.heritageObjectives.bRendementData.neutral",
  [InvestmentType.dynamic]: "page.heritageObjectives.bRendementData.dynamic",
};
const cRendmentData: Record<string, NormalizeTranslationKeys> = {
  [InvestmentType.judicious]: "page.heritageObjectives.cRendmentData.judicious",
  [InvestmentType.dynamic]: "page.heritageObjectives.cRendmentData.dynamic",
};
const sortObjectEntries = (obj: object) => {
  const sortable = [];
  for (const val in obj) {
    sortable.push([val, obj[val as keyof typeof obj]]);
  }

  return sortable.sort(function (a: any, b: any) {
    return b[1] - a[1];
  })[0];
};
const getRendement = (formValues: FieldValues): InvestmentType | null => {
  const arr = [
    formValues["b_rendement"],
    formValues["c_rendement"],
    ...(formValues["a_rendement"] || []).map(
      (e: any) => aRendementData[e as keyof typeof aRendementData].value
    ),
  ].filter((e) => e);
  const obj = arr.reduce((cnt: any, cur) => {
    return (cnt[cur] = cnt[cur] + 1 || 1), cnt;
  }, {});
  const rest = sortObjectEntries(obj);

  return rest ? (rest[0] as InvestmentType) : null;
};
const mapData = (obj: any) =>
  Object.keys(obj).map((e) => ({
    value: e,
    label: obj[e as keyof typeof obj].label ?? obj[e as keyof typeof obj],
  }));

const items: FormBuilderProps<NormalizeTranslationKeys>["items"] = [
  {
    children: [
      {
        name: "categories",
        type: "checkbox",
        inline: false,
        label: "page.heritageObjectives.categories.label",
        options: mapData(categoriesData) as any[],
      },
    ],
  },
  {
    children: [
      {
        name: "_yield",
        type: "radio",
        label: "page.heritageObjectives._yield.label",
        options: mapData(yieldData) as any[],
      },
    ],
  },
  {
    children: [
      {
        name: "horizon",
        type: "radio",
        label: "page.heritageObjectives.horizon.label",
        options: mapData(retraitCapitalData) as any[],
      },
    ],
  },
  {
    children: [
      {
        name: "a_rendement",
        type: "checkbox",
        inline: false,
        label: "page.heritageObjectives.a_rendement.label",
        options: mapData(aRendementData) as any[],
      },
    ],
  },
  {
    children: [
      {
        name: "b_rendement",
        type: "radio",
        inline: false,
        label: "page.heritageObjectives.b_rendement.label",
        options: mapData(bRendementData) as any[],
      },
    ],
  },
  {
    children: [
      {
        name: "c_rendement",
        type: "radio",
        label: "page.heritageObjectives.c_rendement.label",
        options: mapData(cRendmentData) as any[],
      },
    ],
  },
];

export const HeritageObjectives: VFC<Page> = ({ navigate }) => {
  const onSubmit = async (formValues: FieldValues) => {
    try {
      const res = await setPatrimonialGoalsMutation({
        investmentHorizon: formValues["horizon"],
        investmentCategories: formValues["categories"],
        investmentType: getRendement(formValues),
        yield: formValues["_yield"],
      });
      if (res) navigate?.(routes.profile.path);
    } catch (error) {}
  };

  return (
    <Widget
      label="widget.heritageObjectives"
      className="h-fit w-full"
      containerClass="p-4"
    >
      <FormBuilder
        defaultRequired
        clasName="premium-form"
        mode="onChange"
        items={items}
        onSubmit={onSubmit}
        action={({ submit }) => (
          <Button label="actions.finish" onClick={submit} />
        )}
      />
    </Widget>
  );
};
