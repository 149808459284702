import { AssetType } from "@types";
import { Row } from "components/AssetRow";

type AssetRepartitionProps = {
  dataset: {
    title: string;
    value: number;
    icon?: string;
    onClick?: () => void;
    isUnderManagement?: boolean;
  }[];
  type: AssetType;
};

export function AssetRepartition({ dataset, type }: AssetRepartitionProps) {
  const total = dataset.reduce((acc, { value }) => acc + value, 0);

  return (
    <div className="flex flex-col gap-2">
      {dataset.map(
        ({ value, title, onClick, icon, isUnderManagement }, index) => (
          <div
            key={index}
            onClick={
              !isUnderManagement || type === AssetType.lifeInsurance
                ? onClick
                : undefined
            }
          >
            <Row
              title={title}
              value={value}
              assetType={type}
              icon={icon}
              percent={value / total}
              type="repartition"
              isUnderManagement={isUnderManagement}
            />
          </div>
        )
      )}
    </div>
  );
}
