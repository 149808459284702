import { getFirestore } from "firebase/firestore";
import { initializeAnalytics } from "firebase/analytics";
import { FirebaseOptions, getApp, initializeApp } from "firebase/app";

import { config } from "config";
import { pick } from "core/helpers/object";

const firebaseConfig = pick(config, [
  "apiKey",
  "authDomain",
  "projectId",
  "appId",
  "measurementId",
]);

function createFirebaseApp(config: FirebaseOptions) {
  try {
    const app = getApp();
    initializeAnalytics(app);
    return app;
  } catch {
    const initApp = initializeApp(config);
    initializeAnalytics(initApp);
    return initApp;
  }
}

export const firebaseApp = createFirebaseApp(firebaseConfig);
export const database = getFirestore(firebaseApp);
