import { totalWealthRetool } from "core";
import { useGQLQuery } from "core/queries/useQuery";
import { Error, Loading } from "components";

export const RetoolScreen = () => {
  const type = null;
  const { data, isLoading, error } = useGQLQuery(
    ["totalWealthRetool", type],
    totalWealthRetool(type)
  );
  if (isLoading) return <Loading />;
  if (error) return <Error err={error} />;

  return <div>{data.totalWealth.brut}</div>;
};

export default RetoolScreen;
