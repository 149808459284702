import { VFC } from "react";
import Chart from "react-apexcharts";
import Fr from "apexcharts/dist/locales/fr.json";

import { numberFormat } from "core/helpers/number";
import { AssetType } from "@types";
import { getAssetTypeColor } from "core";

interface Props {
  hasDecimal?: boolean;
  series: {
    x: number;
    y: number;
  }[];
  assetType?: AssetType;
}
export const AreasChart: VFC<Props> = ({
  hasDecimal = true,
  series = [],
  assetType,
}) => {
  const lineColor = getAssetTypeColor(assetType).color;
  const gradientColor = [lineColor, lineColor + "00"];

  return (
    <Chart
      type="area"
      {...{
        series: [{ name: "Valeur", data: series }],
        options: {
          chart: {
            locales: [Fr],
            defaultLocale: "fr",
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "smooth",
            width: [2, 2, 2, 2],
            colors: [lineColor],
          },
          xaxis: {
            type: "datetime",
            labels: {
              show: false, // Hide dates on X-axis
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            tickAmount: 4,
            floating: true, // Make Y-axis labels floating
            opposite: true, // Move prices (Y-axis labels) to the right
            labels: {
              formatter: (val) =>
                numberFormat(val, {
                  minimumFractionDigits: hasDecimal ? 2 : 0,
                }),
              style: {
                colors: "#4f5272",
              },
              offsetY: -7,
              offsetX: 0,
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },

          fill: {
            type: "gradient",
            colors: gradientColor,
            gradient: {
              shadeIntensity: 1,
              inverseColors: false,
              opacityFrom: 0.45,
              opacityTo: 0.05,
              stops: [20, 100, 100, 100],
            },
          },

          tooltip: {
            x: {
              format: "dd/MM/yy",
            },
            fixed: {
              enabled: false,
              position: "topRight",
            },
          },
          markers: {
            size: series.length < 2 ? 4 : 0,
            colors: lineColor,
            strokeColors: "#fff",
            strokeWidth: 2,
            strokeOpacity: 0.9,
            strokeDashArray: 0,
            fillOpacity: 1,
            discrete: [],
            shape: "circle",
            radius: 2,
            offsetX: 0,
            offsetY: 0,
            onClick: undefined,
            onDblClick: undefined,
            showNullDataPoints: true,
          },
        },
      }}
      width="100%"
      height="400px"
    />
  );
};
