import { VFC } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NormalizeTranslationKeys } from "i18n";

import { routes } from "routing";
import { isAllSetExcept } from "core";
import { AuthStrategy } from "@types";
import { tracker, Tracking } from "core/helpers/tracking";
import { authWithTokenMutation } from "core/queries/mutation";
import { AuthComponent, AuthFormType } from "components/Auth";
import {
  AuthService,
  SettingsService,
  firebaseAuthService,
} from "core/services";

interface Props {
  pageType: AuthFormType;
}
interface RedirectProps {
  idToken: string;
}
export const LoginScreen: VFC<Props> = ({ pageType }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const discountCode = urlParams.get("codePartenaire") || undefined;

  const isLogin = pageType === "login";
  const isForgotPassword = pageType === "forgot-password";

  const redirectAuthUser = async (
    { idToken }: RedirectProps,
    rememberMe = true
  ) => {
    const { authWithToken } = await authWithTokenMutation({
      token: idToken,
      discountCode,
    });
    const user = authWithToken.user;
    const token = authWithToken.token;
    if (!token || !user) return;

    tracker.registerUser(user.id);
    AuthService.setUser(user, !rememberMe);
    AuthService.setToken(token, !rememberMe);
    SettingsService.setSettings(user.layoutSettings || "");
    window.location.href =
      routes.home.path +
      (!isLogin && !isForgotPassword ? "?onboarding=true" : "");
  };
  const handleAuthWithForm = async ({ rememberMe, ...state }: any) => {
    try {
      if (!isForgotPassword) {
        if (
          !state.email ||
          !state.password ||
          (!isLogin && !isAllSetExcept(state, ["discountCode"]))
        )
          throw new Error("Veuillez remplir tous les champs");
        if (!isLogin && !state.cgupolitique) {
          throw new Error("Veuillez accepter les conditions générales");
        }

        const res = await (isLogin
          ? firebaseAuthService.authWithCredentials(state.email, state.password)
          : firebaseAuthService.authCreateWithCredentials(
              state.email,
              state.password
            ));

        if (!res)
          throw new Error("Une erreur est survenue, veuillez réessayer");

        tracker.log(isLogin ? Tracking.Event.LOGIN : Tracking.Event.SIGN);
        await redirectAuthUser(res, rememberMe);
      } else {
        await firebaseAuthService.resetPassword(state.email);
        navigate(routes.login.path);
        toast.success("Check your email for reset password link");
      }
    } catch (error: any) {
      toast.error(
        t(`firebase.${error.code}` as NormalizeTranslationKeys) as string
      );
    }
  };
  const handleAuthWithStrategy = (strategy: AuthStrategy) => async () => {
    try {
      const res = await firebaseAuthService.authWithStrategy(strategy);
      if (!res) return;
      redirectAuthUser(res);
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  return (
    <AuthComponent
      isLogin={isLogin}
      pageType={pageType}
      isForgotPassword={isForgotPassword}
      navigate={navigate}
      handleAuthWithForm={handleAuthWithForm}
      handleAuthWithStrategy={handleAuthWithStrategy}
      discountCode={discountCode}
    />
  );
};
