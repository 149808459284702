import { gql } from "graphql-request";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { client } from "core/queries/makeGQLQuery";
import { AssetType, FavoriteAssetType } from "@types";

async function getFavoriteAssets(start: Date | null, end: Date | null) {
  const query = gql`
    query FavoriteAssetTypes($start: DateTime, $end: DateTime) {
      favoriteAssetTypes {
        type
        amount {
          value
          instrument
        }
        performance(start: $start, end: $end) {
          evolution
          gain {
            value
            instrument
          }
        }
      }
    }
  `;

  const response = await client.request(query, { start, end });
  return response.favoriteAssetTypes as FavoriteAssetType[];
}

async function updateFavoriteAssetTypes(types: AssetType[]) {
  const mutation = gql`
    mutation UpdateFavoriteAssetTypes($types: [AssetType!]!) {
      updateFavoriteAssetTypes(input: { types: $types }) {
        type
      }
    }
  `;

  const response = await client.request(mutation, { types });
  return response.updateFavoriteAssetTypes as {
    types: AssetType[];
  };
}

export function useFavoriteAssets(
  start: Date | null = null,
  end: Date | null = null
) {
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery(
    ["favoriteAssets", start?.toISOString(), end?.toISOString()],
    () => getFavoriteAssets(start, end),
    {
      select: (favorites) =>
        favorites.sort((a, b) => a.type.localeCompare(b.type)),
    }
  );

  const addFavoriteMutation = useMutation<
    { types: AssetType[] },
    unknown,
    AssetType
  >(
    (assetType) => {
      const currentFavorites = data?.map((a) => a.type) ?? [];
      return updateFavoriteAssetTypes([...currentFavorites, assetType]);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("favoriteAssets");
        queryClient.invalidateQueries("wealth");
      },
    }
  );

  const removeFavoriteMutation = useMutation<
    { types: AssetType[] },
    unknown,
    AssetType
  >(
    (assetType) => {
      const currentFavorites = data?.map((a) => a.type) ?? [];
      return updateFavoriteAssetTypes(
        currentFavorites.filter((a) => a !== assetType)
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("favoriteAssets");
        queryClient.invalidateQueries("wealth");
      },
    }
  );

  const updateFavoritesMutation = useMutation(updateFavoriteAssetTypes, {
    onSuccess: () => {
      queryClient.invalidateQueries("favoriteAssets");
      queryClient.invalidateQueries("wealth");
    },
  });

  return {
    addFavorite: addFavoriteMutation.mutate,
    removeFavorite: removeFavoriteMutation.mutate,
    updateFavorites: updateFavoritesMutation.mutate,
    favoriteAssets: data,
    isLoading:
      isLoading ||
      addFavoriteMutation.isLoading ||
      removeFavoriteMutation.isLoading ||
      updateFavoritesMutation.isLoading,
    isFavorite: (asset: AssetType) => {
      return !!data?.some((a) => a.type === asset);
    },
  };
}
