import { FC } from "react";

import { NormalizeTranslationKeys } from "i18n";
import { useTranslation } from "react-i18next";

type InfoLineProps = {
  label: NormalizeTranslationKeys;
  value?: string | number;
  icon?: React.ReactNode;
};

export const InfoLine: FC<InfoLineProps> = ({ label, value, icon }) => {
  const { t } = useTranslation();

  return (
    <div className="my-2 flex flex-row items-center justify-between">
      <p className="mr-2 flex text-sm font-medium leading-none text-zinc-400">
        {t(label)}
      </p>
      <div className="flex shrink flex-row items-center pl-4 text-right">
        {icon ? <div className="mr-2">{icon}</div> : null}
        <p className="shrink text-sm font-medium leading-none text-slate-900">
          {value ?? "N/A"}
        </p>
      </div>
    </div>
  );
};
