import { VFC } from "react";
import { Disclosure } from "@headlessui/react";
import { PencilIcon } from "@heroicons/react/solid";

import {
  AssetType,
  ATable,
  ManualUpdateModalProps,
  PlatformAccount,
  PrivateCivilRealEstate,
} from "@types";
import { Icon, Typo, DropDown } from "components";
import DoubleLabel from "components/DoubleLabel/DoubleLabel";
import { numberFormat } from "core";

interface HeaderProps extends Omit<ATable, "mapDataKey" | "data"> {
  id: string;
  name: string;
  open: boolean;
  total: number;
  valuation?: number;
  totalValuation?: number;
  currentActivity?: number;
  elem: PlatformAccount | PrivateCivilRealEstate;
  exchange: string;
  handleEdition:
    | ((editType: ManualUpdateModalProps["editType"]) => void)
    | undefined;
}

const useAccordionHeader = ({
  id,
  name,
  open,
  total,
  exchange,
  valuation,
  queryType,
  totalValuation,
  currentActivity,
  handlePlatformDelete,
}: HeaderProps) => {
  return {
    id,
    name,
    open,
    total,
    exchange,
    valuation,
    queryType,
    totalValuation,
    currentActivity,
    handlePlatformDelete,
  };
};

export const AccordionHeader: VFC<HeaderProps> = (props) => {
  const {
    id,
    name,
    open,
    total,
    exchange,
    valuation,
    queryType,
    totalValuation,
    currentActivity,
    handlePlatformDelete,
  } = useAccordionHeader(props);

  return (
    <Disclosure.Button className="flex h-[50px] w-full items-center">
      <Icon
        type="wc_goutte_icon"
        className="h-4 w-4 fill-dark-100"
        rotate={open ? 360 : 270}
      />
      <div className="w-full overflow-hidden rounded-l-lg">
        <div className="whitespace-nowrappl-0 flex items-center text-sm font-medium text-gray-800">
          <Typo
            type="wlc_inter_l"
            className="mb-0 ml-0 truncate whitespace-nowrap p-4 text-sm font-semibold text-gray-800"
          >
            {`${name} ${exchange ? `(${exchange})` : ""}`}
          </Typo>
        </div>
      </div>
      {totalValuation != undefined && (
        <div className="mr-5 font-semibold">{numberFormat(totalValuation)}</div>
      )}
      <div className="flex items-center gap-2">
        {(queryType === AssetType.realEstate ||
          queryType === AssetType.privateCivilRealEstate ||
          queryType === AssetType.exotic) &&
          currentActivity !== undefined && (
            <div className="mr-2">{numberFormat(currentActivity)}</div>
          )}
        {queryType === AssetType.lifeInsurance ? (
          numberFormat(total)
        ) : (
          <div className="flex gap-x-2">
            <DoubleLabel
              data={{
                title: name,
                price: total,
                gain: total,
                percent: valuation,
              }}
              displayValue="gain"
            />
            <DoubleLabel
              data={{
                title: name,
                price: total,
                gain: total,
                percent: valuation,
              }}
              displayValue="percent"
            />
          </div>
        )}
        <DropDown
          title={<PencilIcon className="w-3 text-primary-500" />}
          buttonAs="div"
          items={[
            ...(!props.handleEdition
              ? []
              : [
                  {
                    name: "Editer",
                    label: "Editer",
                    onClick: () => props.handleEdition?.("update"),
                  },
                ]),
            {
              name: "Supprimer",
              label: "Supprimer",
              onClick: () => {
                if (!confirm("Confirmez-vous la suppression ?")) return;

                handlePlatformDelete?.(id);
              },
            },
          ]}
        />
      </div>
    </Disclosure.Button>
  );
};
