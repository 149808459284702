import { zodResolver } from "@hookform/resolvers/zod";
import { Dispatch, SetStateAction } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { Button, Dialog, Select } from "components";
import { UserDocumentCategory } from "./documents.logic";

export interface GenDialogProps {
  visible: boolean;
  isLoading?: boolean;
  onSubmit: (data: GenValidationSchema & { file: File }) => void;
  defaultCategory?: string;
  categories: UserDocumentCategory[];
  setVisible: Dispatch<SetStateAction<boolean>>;
}

const validationSchema = z.object({
  category: z.string({ required_error: "forms.rules.required" }),
  file: z.instanceof(FileList),
});

type GenValidationSchema = z.infer<typeof validationSchema>;

export function GedDialogAdd({
  visible,
  setVisible,
  onSubmit,
  categories,
  defaultCategory,
  isLoading,
}: GenDialogProps) {
  const { t, i18n } = useTranslation();
  const {
    formState: { isValid },
    reset,
    handleSubmit,
    setValue,
    resetField,
    register,
    watch,
  } = useForm<GenValidationSchema>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      category: defaultCategory,
    },
  });

  const formOnSubmit = async (data: GenValidationSchema) => {
    onSubmit({
      ...data,
      file: data.file[0] as any,
    });
    setVisible(false);
    reset();
  };

  const file = watch("file");

  return (
    <Dialog
      header={t("page.profile.documents.document_add.title")}
      open={!!visible}
      onOpenChange={() => setVisible(false)}
    >
      <form
        onSubmit={handleSubmit((data) => formOnSubmit(data))}
        className="flex w-96 flex-col gap-4"
      >
        <h2 className="mx-auto text-lg  font-bold">
          {t("page.profile.documents.document_add.chooseCategory")}
        </h2>
        <Select
          name="category"
          options={categories.map((category) => ({
            value: category.key,
            label: i18n.exists(`page.profile.documents.${category?.key}`)
              ? `page.profile.documents.${category?.key}`
              : category.name,
          }))}
          className="min-w-[150px]"
          defaultValue={defaultCategory}
          onChange={(value) => {
            setValue("category", value, {
              shouldValidate: true,
            });
          }}
        />
        <h2 className="mx-auto flex flex-col items-center justify-center text-lg font-bold">
          {t("page.profile.documents.document_add.upload_label")}
        </h2>
        <div className="mx-auto w-fit cursor-pointer rounded-md px-10 py-2 font-bold">
          <label
            htmlFor="fileInput"
            className="flex h-14 w-32 cursor-pointer items-center justify-center rounded border border-black/30 bg-[#F8F9FB]"
          >
            <i className="pi pi-download text-xl" />
          </label>
          <input
            id="fileInput"
            type="file"
            hidden
            accept="application/pdf"
            {...register("file")}
          />
        </div>
        {file && file.length > 0 ? (
          <div className="flex max-h-[170px] w-full flex-col gap-2 overflow-auto">
            <div className="flex w-full flex-row items-center justify-between gap-4 rounded bg-gray-200 px-4 py-2">
              <p className="line-clamp-1 overflow-ellipsis">{file[0].name}</p>
              <i
                className="pi pi-times cursor-pointer text-red-500"
                onClick={() => {
                  resetField("file");
                }}
              />
            </div>
          </div>
        ) : null}
        <Button
          type="submit"
          label="actions.save"
          disabled={!isValid || isLoading}
          // loading={isLoading}
          className="mx-auto"
        />
      </form>
    </Dialog>
  );
}
