import toast from "react-hot-toast";
import { t } from "i18next";
import { GraphQLClient, gql } from "graphql-request";

import { config } from "config";
import { AuthService } from "core/services";

export const makeMutation =
  <T>(chunks: string) =>
  async (variables?: any): Promise<T> => {
    try {
      const graphqlClient = new GraphQLClient(`${config.apiUrl}/graphql`, {
        headers: {
          authorization: `Bearer ${AuthService.getToken()}`,
        },
      });

      const mutation = gql`
        ${chunks}
      `;
      const data = await graphqlClient.request(mutation, variables);

      return data;
    } catch (error: any) {
      const message = error.response?.errors[0]?.message || error.message;
      const code = error.response?.errors[0]?.extensions?.code;

      toast.error(t(code || message));
      throw error;
    }
  };
