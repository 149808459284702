import { type FC } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import { routes } from "routing";
import { AssetType } from "@types";
import { InfoLine } from "../InfoLine";
import { dateFormat, numberFormat } from "core";
import { getLifeInsuranceById } from "./Assets.logic";
import { useAssetActions } from "./AssetActions/AssetActions";
import { AssetRepartition } from "components/AssetRepartition";
import { useDeleteAsset } from "core/helpers/hooks/useDeleteAsset";
import { ManualUpdateModal, ScrollableContainer } from "components";
import { AssetEvolution } from "components/AssetEvolution/AssetEvolution";

type LifeInsuranceViewProps = {
  id: string;
};

const LifeInsuranceView: FC<LifeInsuranceViewProps> = ({ id }) => {
  const lifeInsuranceQuery = useQuery(["lifeInsurance", id], () =>
    getLifeInsuranceById(id)
  );

  const navigation = useNavigate();
  const compositionActions = useAssetActions(["repartition", "delete"], {
    onDelete: async () => deleteAsset(id),
  });
  const { mutate: deleteAsset } = useDeleteAsset(() => {
    navigation(routes.lifeInsurance.path);
  });

  const informationActions = useAssetActions(["edit"]);

  const data = lifeInsuranceQuery.data;

  if (!data) {
    return null;
  }

  const investments = data.asset?.investments ?? [];

  return (
    <>
      <ScrollableContainer
        title="title.composition"
        Right={compositionActions.render}
      >
        {compositionActions.state.repartition ? (
          <AssetRepartition
            type={AssetType.lifeInsurance}
            dataset={investments.map((investment) => ({
              title: investment.label,
              value: investment.unitPrice,
            }))}
          />
        ) : (
          <AssetEvolution
            dataset={investments.map((investment) => {
              const buyingPrice = investment.unitPrice * investment.quantity;
              const valuation = investment.valuation ?? 0;
              const gain = valuation - buyingPrice;

              return {
                id: investment.id,
                assetType: AssetType.lifeInsurance,
                title: investment.label,
                value: investment.valuation,
                gain: gain,
                evolution: gain / buyingPrice || 0,
              };
            })}
          />
        )}
      </ScrollableContainer>
      <ScrollableContainer
        title="title.informations"
        className="mt-4 gap-2 p-4"
        Right={informationActions.render}
      >
        <div className="flex flex-col gap-2">
          <InfoLine
            label="formLabel.insuranceCompany"
            value={data.insuranceCompany}
          />
          <InfoLine
            label="formLabel.transfersAmount"
            value={numberFormat(data.transfersAmount)}
          />
          <InfoLine
            label="formLabel.efficiency"
            value={numberFormat(data.efficiency)}
          />
          <InfoLine
            label="formLabel.creation"
            value={dateFormat(data.creation)}
          />
        </div>
        {!!informationActions.state.edit && (
          <ManualUpdateModal
            selected={lifeInsuranceQuery.data}
            editType={"update"}
            queryType={AssetType.lifeInsurance}
            onClose={() => informationActions.dispatch({ type: "edit" })}
            handleUpdate={lifeInsuranceQuery.refetch}
            handleDelete={console.log}
            handleActivityDelete={console.log}
          />
        )}
      </ScrollableContainer>
    </>
  );
};

export default LifeInsuranceView;
