import { FC } from "react";

import { Icon } from "./Icon";
import { dateFormat, numberFormat } from "core";

type TransactionRowProps = {
  transaction: {
    id: string;
    date: string;
    name: string;
    amount: number;
  };
};

const TransactionRow: FC<TransactionRowProps> = ({ transaction }) => {
  const isPositive = transaction.amount > 0;

  return (
    <div className="flex h-16 w-full flex-row items-center">
      <div
        className="flex h-9 w-9 items-center justify-center rounded-full px-3"
        style={{
          backgroundColor: isPositive ? "#E3FCEF" : "#FCE3E3",
        }}
      >
        <Icon type="transation_arrow" rotate={isPositive ? -90 : 0} />
      </div>
      <div className="min-w-0 flex-grow pl-3 pr-4">
        <p className="text-content-primary truncate pb-1 font-sans text-sm">
          {transaction.name}
        </p>
        <p className="text-content-tertiary no-wrap font-sans text-xs font-medium">
          {dateFormat(transaction.date)}
        </p>
      </div>
      <p
        className="font-sans text-sm font-bold"
        style={{
          color: isPositive ? "#00875A" : "#C84747",
        }}
      >
        {numberFormat(transaction.amount, { maximumFractionDigits: 2 })}
      </p>
    </div>
  );
};

export function TransactionRowSkeleton() {
  return <div className="h-16 flex-row items-center">skeleton</div>;
}

export default TransactionRow;
