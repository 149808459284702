import { ActionPanel } from "components";
import { routes, RoutesType } from "routing";

export function PanelList({
  action,
}: {
  action: (key: RoutesType) => () => void;
}) {
  return (
    <div className="mx-auto mt-3 grid w-full grid-cols-1 gap-10 _700:grid-cols-2 lg:grid-cols-3 md_lg:grid-cols-3">
      {Object.keys(routes)
        .filter((key) => !!routes[key].description)
        .map((key) => {
          const element = routes[key];

          return (
            <ActionPanel
              key={key}
              assetType={element.type}
              description={element.description}
              heading={element.label}
              icon={element.menuIcon}
              onClick={action(key)}
            />
          );
        })}
    </div>
  );
}
