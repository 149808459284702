import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { routes } from "routing";
import PremiumCard from "./PremiumCard";

const RetirementSimulation = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <PremiumCard
      title={"retirementSimulation.title"}
      onClick={() => navigate(routes.profile.path + "#abonner-premium")}
    >
      <p className="text-content-tertiary my-3 mt-6 font-sans text-xs font-medium">
        {t("retirementSimulation.subtitle")}
      </p>
    </PremiumCard>
  );
};

export default RetirementSimulation;
