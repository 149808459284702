import {
  InvestorProfileListGraphqItem,
  InvestorProfileListItem,
} from "./utils";

// Question 1
export const q1Object = {
  "q1._1": "5",
  "q1._2": "2",
  "q1._3": "1",
  "q1._4": "4",
  "q1._5": "4",
  "q1._6": "2",
  "q1._7": "0",
};
// Question 2
export const q2Object = {
  "q2._1": "3",
  "q2._2": "2",
  "q2._3": "1",
  "q2._4": "0",
};

// Question 3 (A / B)
export const q3a: {
  key: string;
  list: {
    subTitle?: boolean;
    list: InvestorProfileListItem[];
  }[];
} = {
  key: "q3a",
  list: [
    { list: [{ value: "0" }, { value: "1" }, { value: "-1" }] },
    { list: [{ value: "1" }, { value: "0" }, { value: "-1" }], subTitle: true },
    { list: [{ value: "0" }, { value: "1" }, { value: "-1" }], subTitle: true },
    { list: [{ value: "0" }, { value: "1" }, { value: "-1" }], subTitle: true },
    { list: [{ value: "1" }, { value: "0" }, { value: "-1" }] },
    { list: [{ value: "1" }, { value: "0" }, { value: "-1" }] },
    { list: [{ value: "0" }, { value: "1" }, { value: "-1" }] },
    { list: [{ value: "1" }, { value: "0" }, { value: "-1" }] },
    { list: [{ value: "0" }, { value: "1" }, { value: "-1" }] },
    { list: [{ value: "0" }, { value: "1" }, { value: "-1" }] },
    { list: [{ value: "0" }, { value: "1" }, { value: "-1" }] },
  ],
};

// Question 3 B
export const q3b: {
  key: string;
  list: {
    subTitle?: boolean;
    list: InvestorProfileListItem[];
  }[];
} = {
  key: "q3b",
  list: [
    { list: [{ value: "1" }, { value: "2" }, { value: "3" }] },
    { list: [{ value: "1" }, { value: "2" }, { value: "3" }] },
    { list: [{ value: "1" }, { value: "2" }, { value: "3" }] },
    { list: [{ value: "1" }, { value: "2" }, { value: "3" }] },
    { list: [{ value: "1" }, { value: "2" }, { value: "3" }] },
    { list: [{ value: "1" }, { value: "2" }, { value: "3" }] },
    { list: [{ value: "1" }, { value: "2" }, { value: "3" }] },
    { list: [{ value: "1" }, { value: "2" }, { value: "3" }] },
    { list: [{ value: "1" }, { value: "2" }, { value: "3" }] },
    { list: [{ value: "1" }, { value: "2" }, { value: "3" }] },
    { list: [{ value: "1" }, { value: "2" }, { value: "3" }] },
  ],
};

// Question 4
export const q4List: InvestorProfileListItem[] = [
  { value: "0" },
  { value: "1" },
  { value: "2" },
  { value: "3" },
];

// Question 5
export const q5List: InvestorProfileListItem[] = [
  { value: "1" },
  { value: "2" },
  { value: "3" },
];

// Question 6
export const q6List: InvestorProfileListItem[] = [
  { value: "5" },
  { value: "4" },
  { value: "2" },
  { value: "1" },
];

// Question 7
export const q7List: InvestorProfileListGraphqItem[] = [
  { value: "4", icon: "/images/imgQ71.jpg", hasTitle: true },
  { value: "3", icon: "/images/imgQ72.jpg", hasTitle: true },
  { value: "1", icon: "/images/imgQ73.jpg", hasTitle: true },
];

// Question 8
export const q8List: InvestorProfileListGraphqItem[] = [
  { value: "5", icon: "/images/imgQ81.jpg", description: true },
  { value: "1", icon: "/images/imgQ82.jpg", description: true },
];

// Question 9 A
export const q9aList: InvestorProfileListGraphqItem[] = [
  { value: "4", icon: "/images/imgQ9A1.jpg", description: true },
  { value: "2", icon: "/images/imgQ9A2.jpg", description: true },
];

// Question 9 B
export const q9bList: InvestorProfileListGraphqItem[] = [
  { value: "4", icon: "/images/imgQ9B1.jpg", description: true },
  { value: "2", icon: "/images/imgQ9B2.jpg", description: true },
];

// Question 10
export const q10List: InvestorProfileListItem[] = [
  { value: "1" },
  { value: "3" },
];

// Question 11
export const q11List: InvestorProfileListItem[] = [
  { value: "4" },
  { value: "2" },
  { value: "1" },
];

// Question 12
export const q12List: InvestorProfileListItem[] = [
  { value: "4" },
  { value: "3" },
  { value: "1" },
];

// Question 13
export const q13List: InvestorProfileListItem[] = [
  { value: "4" },
  { value: "3" },
  { value: "1" },
];

// Question 14
export const q14Object = {
  "q14._1": "5",
  "q14._2": "2",
  "q14._3": "3",
  "q14._4": "4",
  "q14._5": "1",
  "q14._6": "0",
};

// Question 15
export const q15List: InvestorProfileListItem[] = [
  { value: "1" },
  { value: "2" },
  { value: "4" },
  { value: "5" },
];

// Question 16
export const q16List: InvestorProfileListItem[] = [
  { value: "5" },
  { value: "4" },
  { value: "3" },
  { value: "2" },
  { value: "1" },
];

// Question 17
export const q17Object = {
  "q17._1": "3",
  "q17._2": "1",
  "q17._3": "1",
  "q17._4": "3",
};

// Question 18
export const q18Object = {
  "q18._1": "2",
  "q18._2": "3",
  "q18._3": "1",
};

// Question 19
export const q19List = [{ value: "1" }, { value: "2" }];
