import { RouteListType, routes } from "routing";

export function isAssetRoute(route: RouteListType): boolean {
  return [
    routes.home.key,
    routes.other.key,
    routes.addWallet.key,
    routes.profile.key,
    routes.heritageObjectives.key,
  ].includes(route.key);
}
