import type { FC } from "react";
import { useForm } from "react-hook-form";
import { Button } from "components";
import { setUserInformationsGeneralMutation } from "core";
import { useMutation, useQueryClient } from "react-query";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "components/RadixAccordion";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { CustomInput } from "components/CustomInput";

type InputProps = {
  type: "text" | "date" | "number" | "file";
  step?: number;
};

const maritalStatusFormData = {
  name: {
    type: "text",
  },
  birthName: {
    type: "text",
  },
  surname: {
    type: "text",
  },
  birthDate: {
    type: "date",
  },
  birthCity: {
    type: "text",
  },
  birthCountry: {
    type: "text",
  },
  familySituation: {
    type: "text",
  },
  nationality: {
    type: "text",
  },
} satisfies Record<string, InputProps>;

const professionnalSituationFormData = {
  studiesLevel: {
    type: "text",
  },
  occupation: {
    type: "text",
  },
  occupationWording: {
    type: "text",
  },
  retirementAge: {
    type: "number",
  },
  annualIncome: {
    type: "number",
    step: 0.01,
  },
  globalHeritage: {
    type: "number",
  },
  heritageOrigin: {
    type: "text",
  },
} satisfies Record<string, InputProps>;

const identityFormData = {
  seniorAdvisor: {
    type: "text",
  },
  dateEntryRelationship: {
    type: "date",
  },
  // idCard: {
  //   type: "file",
  // },
  idNumber: {
    type: "text",
  },
  issueDate: {
    type: "date",
  },
  authority: {
    type: "text",
  },
  expirationDate: {
    type: "date",
  },
} satisfies Record<string, InputProps>;

const otherInformationsFormData = {
  idMIF: {
    type: "text",
  },
  taxResidenceCountry: {
    type: "text",
  },
  customerClassificationMIF: {
    type: "text",
  },
  classificationDate: {
    type: "date",
  },
  pep: {
    type: "text",
  },
  usPerson: {
    type: "text",
  },
  legalCapacity: {
    type: "number",
  },
} satisfies Record<string, InputProps>;

export type GeneralInformationsFormDataType = Record<
  keyof typeof maritalStatusFormData,
  string | number
> &
  Record<keyof typeof professionnalSituationFormData, string | number> &
  Record<keyof typeof identityFormData, string | number> &
  Record<keyof typeof otherInformationsFormData, string | number>;

export const ProfileInformationsGeneral: FC<{ defaultValues?: object }> = ({
  defaultValues,
}) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (input: Record<string, any>) =>
      setUserInformationsGeneralMutation({ input }),
    {
      onSuccess: () => {
        toast.success(t("notification.save"));
        queryClient.invalidateQueries("user");
      },
    }
  );

  const { t } = useTranslation();

  const form = useForm<GeneralInformationsFormDataType>({
    defaultValues,
  });

  return (
    <form onSubmit={form.handleSubmit((data) => mutation.mutate(data))}>
      <Accordion type="multiple" className="w-full" defaultValue={["item-1"]}>
        <AccordionItem value="item-1">
          <AccordionTrigger>
            {t("forms.customers.details.general.accordion.maritalStatus")}
          </AccordionTrigger>
          <AccordionContent className="grid grid-cols-2 gap-4">
            {Object.entries(maritalStatusFormData).map(([key, value]) => (
              <CustomInput
                type={value.type}
                key={key}
                {...form.register(key)}
                label={t(`forms.customers.details.general.${key}`)}
              />
            ))}
          </AccordionContent>
        </AccordionItem>

        <AccordionItem value="item-2">
          <AccordionTrigger>
            {t(
              "forms.customers.details.general.accordion.professionalSituation"
            )}
          </AccordionTrigger>
          <AccordionContent className="grid grid-cols-2 gap-4">
            {Object.entries(professionnalSituationFormData).map(
              ([key, value]) => (
                <CustomInput
                  type={value.type}
                  key={key}
                  {...form.register(key)}
                  label={t(`forms.customers.details.general.${key}`)}
                />
              )
            )}
          </AccordionContent>
        </AccordionItem>

        <AccordionItem value="item-3">
          <AccordionTrigger>
            {t("forms.customers.details.general.accordion.identity")}
          </AccordionTrigger>
          <AccordionContent className="grid grid-cols-2 gap-4">
            {Object.entries(identityFormData).map(([key, value]) => (
              <CustomInput
                type={value.type}
                key={key}
                {...form.register(key)}
                label={t(`forms.customers.details.general.${key}`)}
              />
            ))}
          </AccordionContent>
        </AccordionItem>

        <AccordionItem value="item-4">
          <AccordionTrigger>
            {t("forms.customers.details.general.accordion.others")}
          </AccordionTrigger>
          <AccordionContent className="grid grid-cols-2 gap-4">
            {Object.entries(otherInformationsFormData).map(([key, value]) => (
              <CustomInput
                type={value.type}
                key={key}
                {...form.register(key)}
                label={t(`forms.customers.details.general.${key}`)}
              />
            ))}
          </AccordionContent>
        </AccordionItem>
      </Accordion>

      <Button
        label="actions.save"
        className="mt-4 items-center truncate text-clip rounded-md border border-transparent bg-primary-400 px-4 py-2 font-medium text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        type="submit"
      />
    </form>
  );
};
