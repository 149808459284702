import type { FC } from "react";
import { useEffect } from "react";
import { useQuery, useMutation } from "react-query";
import { useTranslation } from "react-i18next";

import { ScrollableContainer } from "components";
import { getBankAccountById } from "./Assets.logic";
import TransactionRow from "screens/private/assetById/components/assets/transactionRow";
import { setHeader, useAppDispatch } from "_redux";
import {
  budgetInsightConnections,
  manageConnection,
} from "../../../profile/components/BudgetInsightConnections/BudgetInsightConnections.logic";

type BankAccountViewProps = {
  id: string;
  shownTransactionIndex: number;
  initialBalance: number;
  setDynamicBalance: (props: { date: string; balance: number } | null) => void;
};

const BankAccountView: FC<BankAccountViewProps> = ({
  id,
  shownTransactionIndex,
  initialBalance,
  setDynamicBalance,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const bankAccountQuery = useQuery(["bankAccount", id], () =>
    getBankAccountById(id)
  );

  const budgetInsightConnectionsQuery = useQuery(["budgetInsightConnections"], {
    queryFn: budgetInsightConnections,
    staleTime: 0,
  });

  const manageConnectionMutation = useMutation(() => manageConnection(), {
    onSuccess: (url) => {
      window.open(url, "_blank");
    },
  });

  useEffect(() => {
    if (bankAccountQuery.data?.bankUUID) {
      dispatch(
        setHeader({
          icon: `https://monapi.biapi.pro/2.0/logos/${bankAccountQuery.data.bankUUID}-thumbnail.webp`,
        })
      );
    }

    return () => {
      dispatch(
        setHeader({
          icon: null,
        })
      );
    };
  }, [bankAccountQuery.data?.bankUUID]);

  useEffect(() => {
    if (shownTransactionIndex < 0) {
      setDynamicBalance(null);
      return;
    }

    // get the balance at the time of the transaction
    const transaction =
      bankAccountQuery.data?.asset.transactions[shownTransactionIndex];
    if (!transaction) {
      return;
    }

    const balanceAtTransaction = bankAccountQuery.data?.asset.transactions
      .slice(0, shownTransactionIndex)
      .reduce((acc, transaction) => acc - transaction.amount, initialBalance);

    setDynamicBalance({
      date: transaction.date,
      balance: balanceAtTransaction || 0,
    });
  }, [
    shownTransactionIndex,
    initialBalance,
    bankAccountQuery.data?.asset.transactions,
    setDynamicBalance,
  ]);

  let connectorFound = null;
  if (bankAccountQuery.data?.bankUUID && budgetInsightConnectionsQuery.data) {
    connectorFound = budgetInsightConnectionsQuery.data.find(
      (element) =>
        element.connector.uuid === bankAccountQuery.data.bankUUID &&
        element.state
    );
  }
  return (
    <ScrollableContainer
      title="title.transactions"
      height={100}
      Right={
        connectorFound && (
          <button
            type="button"
            className="me-2 inline-flex items-center rounded-lg rounded-lg border border-red-500 px-3 py-2 text-center text-center text-xs font-medium text-red-500 hover:bg-red-600 hover:text-white focus:outline-none focus:ring-4 focus:ring-red-300 "
            disabled={manageConnectionMutation.isLoading}
            onClick={() => manageConnectionMutation.mutate()}
          >
            {t("authenticationRequired")}
          </button>
        )
      }
    >
      <div className="px-4">
        {bankAccountQuery.isSuccess &&
          bankAccountQuery.data.asset.transactions.length === 0 && (
            <div className="text-content-secondary text-center font-sans text-xs font-medium">
              {t("empty_data")}
            </div>
          )}
        {bankAccountQuery.isLoading && <>Loading</>}
        {bankAccountQuery.data?.asset.transactions.map((transaction) => (
          <TransactionRow key={transaction.id} transaction={transaction} />
        ))}
      </div>
    </ScrollableContainer>
  );
};

export default BankAccountView;
