import ContentLoader from "react-content-loader";

import useSize from "core/helpers/hooks/useSize";
import { clsx } from "core";

interface LoaderProps {
  className?: string;
  children: React.ReactNode;
}
export function Loader({ className, children }: LoaderProps) {
  const { ref, width, height } = useSize();

  return (
    <div ref={ref} className={clsx("h-full w-full", className)}>
      <ContentLoader
        viewBox={`0 0 ${width} ${height}`}
        width="100%"
        height="100%"
        speed={2}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        {children}
      </ContentLoader>
    </div>
  );
}
