import { TFunction } from "i18next";
import { Switch } from "@headlessui/react";

import { clsx } from "core";

interface ManageCgpProps {
  enabled: boolean;
  setEnabled: (value: boolean) => void;
  code?: string;
  t: TFunction;
  onProCodeSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
}
export function ManageCgp({
  code,
  enabled,
  setEnabled,
  t,
  onProCodeSubmit,
}: ManageCgpProps) {
  return (
    <div className="mt-4 mb-4 flex h-fit w-full snap-x  scroll-pl-6 flex-col justify-end gap-x-5 overflow-x-hidden rounded-md bg-white p-0">
      <div className="mb-0 flex h-fit shrink-0 cursor-pointer items-center justify-between rounded-none bg-primary-500 p-2 py-4 _700:p-4 lg:w-full lg:flex-row">
        <p className="text-_14 font-normal text-white _700:text-_16">
          {t("page.active_cgp")}
        </p>
        <span className="text-white">
          {t(enabled ? "page.enabled" : "page.disabled")}
        </span>
      </div>
      <div className="mb-0 h-fit shrink-0 justify-center rounded-none bg-white p-2 py-4 _700:justify-start _700:p-4 lg:w-full lg:flex-row">
        <form
          className="text-_14 font-normal text-gray-800 _700:text-_16"
          onSubmit={onProCodeSubmit}
        >
          <div className="flex items-center justify-between">
            <input
              defaultValue={code ?? ""}
              name="active_cgp"
              type="text"
              className="mr-4 flex-1 rounded"
            />
            <Switch
              checked={enabled}
              onChange={setEnabled}
              className={clsx(
                "relative inline-flex h-6 w-11 items-center rounded-full",
                enabled ? "bg-secondary-500" : "bg-gray-200"
              )}
            >
              <span className="sr-only">Enable notifications</span>
              <span
                className={clsx(
                  "inline-block h-4 w-4 transform rounded-full bg-white transition",
                  enabled ? "translate-x-6" : "translate-x-1"
                )}
              />
            </Switch>
          </div>
          <button
            type="submit"
            className="text-secondary m-auto mt-4 block rounded-md border border-transparent bg-primary-100 px-4 py-2 font-medium focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            {t("formLabel.submit")}
          </button>
        </form>
      </div>
    </div>
  );
}
