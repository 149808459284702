import "../wdyr";
import "primeicons/primeicons.css";
import { Suspense } from "react";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";

import { Navigation } from "core/helpers/navigation";
import * as routes from "routing/routeList";
import { ErrorBoundary } from "components";
import { AppProvider } from "context/AppContext";
import { SvgAnimation } from "static/animation";
import { store } from "_redux/store";

const SuspenseLoading = () => (
  <div className="flex h-screen w-screen items-center">
    <SvgAnimation type="threeDotLoading" isStopped={false} />
  </div>
);

export function App() {
  return (
    <div className="App">
      <Toaster position="top-right" />
      <ErrorBoundary>
        <Suspense fallback={<SuspenseLoading />}>
          <AppProvider>
            <Provider store={store}>
              <Navigation {...routes} />
            </Provider>
          </AppProvider>
        </Suspense>
      </ErrorBoundary>
    </div>
  );
}
