import type { FC } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import { routes } from "routing";
import { dateFormat } from "core";
import { AssetType } from "@types";
import { InfoLine } from "../InfoLine";
import { getCrowdfundingById } from "./Assets.logic";
import { useAssetActions } from "./AssetActions/AssetActions";
import { useDeleteAsset } from "core/helpers/hooks/useDeleteAsset";
import { Icon, ManualUpdateModal, ScrollableContainer } from "components";

type CrowdfundingViewProps = {
  id: string;
};

const CrowdfundingView: FC<CrowdfundingViewProps> = ({ id }) => {
  const crowdfundingQuery = useQuery(["crowdfunding", id], () =>
    getCrowdfundingById(id)
  );
  const navigation = useNavigate();

  const { mutate: deleteAsset } = useDeleteAsset(() => {
    navigation(routes.crowdfunding.path);
  });

  const { render, state, dispatch } = useAssetActions(["edit", "delete"], {
    onDelete: async () => deleteAsset(id),
  });

  if (!crowdfundingQuery.data) {
    return null;
  }

  return (
    <ScrollableContainer title="title.informations" Right={render}>
      <div className="p-4 pt-0">
        <InfoLine label="formLabel.name" value={crowdfundingQuery.data.name} />
        <InfoLine
          label="formLabel.providerName"
          value={crowdfundingQuery.data.providerName}
        />
        <InfoLine
          label="formLabel.investmentField"
          value={crowdfundingQuery.data.investmentField}
        />

        <InfoLine
          label="formLabel.subscribeDate"
          value={dateFormat(crowdfundingQuery.data?.subscribeDate)}
          icon={<Icon type="calendar" />}
        />
        <InfoLine
          label="formLabel.endDate"
          value={dateFormat(crowdfundingQuery.data?.endDate)}
          icon={<Icon type="calendar" />}
        />
      </div>
      {!!state.edit && (
        <ManualUpdateModal
          selected={crowdfundingQuery.data}
          editType={"update"}
          queryType={AssetType.crowdfunding}
          onClose={() => dispatch({ type: "edit" })}
          handleUpdate={crowdfundingQuery.refetch}
          handleDelete={console.log}
          handleActivityDelete={console.log}
        />
      )}
    </ScrollableContainer>
  );
};

export default CrowdfundingView;
