import mixpanel from "mixpanel-browser";
import * as amplitude from "@amplitude/analytics-browser";
import { getAnalytics, logEvent } from "firebase/analytics";

import { config } from "config";
import { firebaseApp } from "lib";
import { AssetType } from "@types";

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Tracking {
  export enum Event {
    LOGIN = "login",
    LOGOUT = "logout",
    SIGN = "sign_up",
    CTA_ADD_WALLET = "Ajouter un actif",
    PURCHASE = "purchase",
  }
  export enum AssetTypeEvent {
    CTA_ADD_WALLET = "Click ajout actif",
    CTA_ADD_MANUAL = "Ajout manuel",
    CTA_ADD_AUTOMATIC = "Ajout automatique",
    PAGE_VIEW = "Page vue",
  }

  export class Tracker {
    constructor() {
      amplitude.init(config.amplitude.apiKey);
      mixpanel.init(config.mixpanel.token, {
        debug: config.NODE_ENV === "development",
      });
    }

    log(event: Event | AssetTypeEvent, assetType?: AssetType) {
      let eventName = event.toString();

      try {
        if (Object.values(AssetTypeEvent).includes(event as AssetTypeEvent)) {
          if (!assetType) throw new Error("assetType is required");
          eventName = `${event} ${assetType}`;
        }

        const analytics = getAnalytics(firebaseApp);
        logEvent(analytics, eventName);
        amplitude.track(eventName).promise.catch((error) => {
          console.error(`fail to log event ${eventName} to amplitude:`, error);
        });
        mixpanel.track(eventName, {
          source: "app-web",
        });
      } catch (error) {
        console.error(`failed to track event ${eventName}:`, error);
      }
    }

    registerUser(userId: string) {
      amplitude.setUserId(userId);
      mixpanel.identify(userId);
    }
  }
}

export const tracker = new Tracking.Tracker();
