import { NavigateFunction, Params } from "react-router-dom";

import { RoutesType } from "routing";
import { FormBuilderProps } from "./form/";
import { Asset, PlatformAccount } from "./query";
import { NormalizeTranslationKeys } from "i18n";
import { AssetType, UserInterface } from "@types";

export type Sort = "up" | "down";
export type OnSort = (sort: Sort) => void;
export interface Page {
  currentRouteKey?: RoutesType;
  querySearch?: URLSearchParams;
  params?: Params;
  navigate?: NavigateFunction;
}
type DeleteType = (id: string) => void;

export interface ManualUpdateModalProps {
  editType?: "update" | "activity";
  user?: UserInterface | null;
  selected: FormBuilderProps["defaultValues"];
  queryType: RoutesType;
  onClose: () => void;
  handleUpdate?: () => void;
  handleDelete: DeleteType;
  handleActivityDelete?: DeleteType;
  handlePlatformDelete?: DeleteType;
}
type ITableExcludes =
  | "onClose"
  | "editType"
  | "selected"
  | "queryType"
  | "handleDelete";
export type AuthStrategy = "google";

export interface Data {
  id?: string;
  title: string | React.ReactNode;
  icon?: string;
  gain?: number;
  type?: AssetType;
  price: string | number;
  percent?: number;
  to?: string;
  asset?: Asset;
}
export interface ITable extends Omit<ManualUpdateModalProps, ITableExcludes> {
  label?: NormalizeTranslationKeys;
  data: (Data & { id: string })[];
  queryType?: AssetType;
  showPercentage?: boolean;
  showModal?: boolean;
  className?: string;
  containerClass?: string;
  isPremium?: boolean;
  handleSelection?: (editType?: ManualUpdateModalProps["editType"]) => void;
  handleDelete?: (id: string) => void;
  handleUpdate?: () => void;
}

export interface ATable extends Omit<ITable, "data"> {
  type?: "evolution";
  data: PlatformAccount[];
  mapDataKey: "stocks" | "cryptos";
  evolutionData?: Asset[];
}
export type RealEstateGeoloc = {
  lat: number;
  lon: number;
  source: string;
};
export enum AppartmentType {
  SIMPLEX = "SIMPLEX",
  DUPLEX = "DUPLEX",
  TRIPLEX = "TRIPLEX",
  HOUSE = "HOUSE",
}

export enum AppartmentCondition {
  REDONE = "REDONE",
  REFRESHED = "REFRESHED",
  STANDARD = "STANDARD",
  TO_REFRESH = "TO_REFRESH",
  IMPORTANT_WORK = "IMPORTANT_WORK",
}
export enum BuildingStanding {
  PREMIUM = "PREMIUM",
  GOOD = "GOOD",
  NORMAL = "NORMAL",
  LOW = "LOW",
}
export enum CondominiumCondition {
  WELL_MAINTAINED = "WELL_MAINTAINED",
  STANDARD = "STANDARD",
  NEGLECTED = "NEGLECTED",
}
export enum Direction {
  EAST = "EAST",
  NORTH = "NORTH",
  SOUTH = "SOUTH",
  WEST = "WEST",
}
export enum RealEstateView {
  VIS_A_VIS = "VIS_A_VIS",
  OPEN_VIEW = "OPEN_VIEW",
  OUTSTANDING = "OUTSTANDING",
}
export enum RealEstateAmenitiesProximity {
  VERY_DISTANT = "VERY_DISTANT",
  DISTANT = "DISTANT",
  STANDARD = "STANDARD",
  CLOSE = "CLOSE",
  VERY_CLOSE = "VERY_CLOSE",
}
export enum PoolType {
  NONE = "NONE",
  STANDARD = "STANDARD",
  STANDING = "STANDING",
}
export enum GardenType {
  LANDSCAPE = "LANDSCAPE",
  LANDSCAPED = "LANDSCAPED",
  STANDARD_NONE = "STANDARD_NONE",
  TO_BE_LANDSCAPED = "TO_BE_LANDSCAPED",
}
export enum ProjectDetails {
  FROM_0_T0_6_MONTHS = "FROM_0_T0_6_MONTHS",
  FROM_0_12_MONTHS = "FROM_0_12_MONTHS",
  NO_PROJECT = "NO_PROJECT",
}
export enum AcquirerType {
  BUYER = "BUYER",
  SELLER = "SELLER",
  BOTH = "BOTH",
}
// Court terme | Long terme
export enum InvestmentHorizon {
  short = "short", // 5
  medium = "medium", // 50
  long = "long", // 95
}

// Agressif; Prudent;
export enum InvestmentType {
  dynamic = "dynamic", // 5 | (4)
  neutral = "neutral", // 50 | (5)
  judicious = "judicious", // 95 | ( 6)
}

export enum IYield {
  high = "high", // 5
  medium = "medium", // 50
  low = "low", // 95
}
