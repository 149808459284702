import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { routes } from "routing";
import { gql } from "graphql-request";
import { useQuery } from "react-query";
import { Icon } from "components/Icon";
import { NormalizeTranslationKeys } from "i18n";
import { Container } from "components/Container";
import { client } from "core/queries/makeGQLQuery";
import { useUser } from "core/helpers/hooks/useUser";
import { useWealth } from "core/helpers/hooks/useWealth";
import FavoriteAssetsModule from "screens/private/home/FavoriteAssetsModule";
import FavoriteAccountsModule from "screens/private/home/FavoriteAccountsModule";

export function NoDataSync() {
  return (
    <div className="h-full bg-grey">
      <div className="px-5 py-5 sm:px-6 md_lg:px-8">
        <div className="px-4 pt-10">
          <div className="m-auto max-w-7xl">
            <div className="relative z-10 flex gap-24">
              <div className="flex flex-col gap-4">
                <NoDataPremiumActions />
                <SecondActions />
              </div>
              <Info />
            </div>

            <Assets />
          </div>
        </div>
      </div>
      <div>
        <img src="area-chart.png" alt="" className="w-full" />
      </div>
    </div>
  );
}

export function NoDataPremiumActions() {
  const { data: user, isLoading } = useUser();
  const { t } = useTranslation();

  const investorProfileForm = useQuery("investorProfileForm", () =>
    client
      .request(
        gql`
          query GetInvestorProfileForm {
            getUserInvestorProfileForm
          }
        `
      )
      .then((res) => res.getUserInvestorProfileForm as any)
  );
  const assets = useWealth(null);

  const addAssetsDone = assets.data && assets.data.length > 0;
  const investorProfileDone = !!investorProfileForm.data;
  const patrimonialGoalsDone = !!user?.patrimonialsGoals;

  if (
    isLoading ||
    assets.isLoading ||
    (addAssetsDone && investorProfileDone && patrimonialGoalsDone)
  )
    return null;

  const firstActionList = [
    ...(addAssetsDone
      ? []
      : [
          {
            to: `${routes.addWallet.path}?actif=home`,
            icon: "add-asset",
            title: "title.addAsset",
          },
        ]),

    ...(investorProfileDone
      ? []
      : [
          {
            to: routes.other.path,
            icon: "user-circle",
            title: "title.investorProfile",
          },
        ]),

    ...(patrimonialGoalsDone
      ? []
      : [
          {
            to: routes.heritageObjectives.path,
            icon: "target",
            title: "title.heritageObjectives",
          },
        ]),
  ];

  return (
    <Container className="w-[343px]">
      {firstActionList.map(({ to, icon, title }) => (
        <Link
          to={to}
          key={title}
          className="my-2 flex h-6 shrink grow basis-0 items-center justify-between gap-4 hover:opacity-50"
        >
          <div className="flex items-center">
            <Icon type={icon} className="mr-4" />
            <div className="text-base font-bold leading-tight text-slate-900">
              {t(title as NormalizeTranslationKeys)}
            </div>
          </div>
          <Icon type="wc_arrowUp" className="fill-arrow-menu" rotate={90} />
        </Link>
      ))}
    </Container>
  );
}

function SecondActions() {
  return (
    <div className="flex flex-col gap-4">
      <FavoriteAssetsModule hideContent />
      <FavoriteAccountsModule hideContent />
    </div>
  );
}

function Info() {
  return (
    <div>
      <img src="/no-data-arrow.png" alt="" className="-translate-x-1/2" />
      <div className="AucuneDeVosDonnEsNeSontSynchronisEsPourLInstant h-[134px] w-[514px]">
        <span className="text-3xl font-medium leading-[45.61px]">
          Aucune de vos données ne sont{" "}
        </span>
        <span className="text-3xl font-medium leading-[45.61px] text-primary-400">
          synchronisées
        </span>
        <span className="text-3xl font-medium leading-[45.61px]">
          {" "}
          pour l’instant...
        </span>
      </div>
      <div className="NeTardezPlusPourAjouterVosActifs h-14 w-[368px]">
        <span className="text-lg font-medium leading-7 text-slate-900">
          Ne tardez plus pour{" "}
        </span>
        <span className="text-lg font-medium leading-7 text-indigo-600">
          ajouter vos actifs
        </span>
        <span className="text-lg font-medium leading-7 text-slate-900"> !</span>
      </div>
    </div>
  );
}

function Assets() {
  return (
    <div>
      <div className="flex -translate-y-8 justify-between">
        <img src="/no-data-bankaccount.png" alt="bankaccount wallet" />
        <img
          src="/no-data-crypto.png"
          alt="crypto wallet"
          className="-translate-y-8"
        />
        <img
          src="/no-data-realestate.png"
          alt="realestate wallet"
          className="-translate-y-12"
        />
      </div>
    </div>
  );
}
