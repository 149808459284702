import { useTranslation } from "react-i18next";

import { AssetType, FormBuilderProps, Option } from "@types";
import { getAssetTypeColor } from "core/constants/assetColor";
import { NormalizeTranslationKeys } from "i18n";

export const action: FormBuilderProps["action"] = ({ submit }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const actifValue = urlParams.get("actif");
  const { t } = useTranslation();

  return (
    <button
      type="submit"
      onClick={submit}
      className="focus:ring-none mx-4 w-[32%] items-center truncate text-clip rounded-xl px-4 py-3 font-bold text-white focus:outline-none"
      style={{
        backgroundColor: getAssetTypeColor(actifValue as AssetType).color,
      }}
    >
      {t("actions.add")}
    </button>
  );
};
export const updateAction: FormBuilderProps["action"] = ({ submit }) => {
  const { t } = useTranslation();

  return (
    <button
      type="submit"
      onClick={submit}
      className="mx-4 items-center truncate text-clip rounded-md border border-transparent bg-primary-100 px-4 py-2 font-medium text-asset focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
    >
      {t("actions.update")}
    </button>
  );
};
export const saveAction: FormBuilderProps["action"] = ({ submit }) => {
  const { t } = useTranslation();

  return (
    <button
      type="submit"
      onClick={submit}
      className="text-secondary mx-4 items-center truncate text-clip rounded-md border border-transparent bg-asset px-4 py-2 font-medium focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
    >
      {t("actions.save")}
    </button>
  );
};

export const options: Option<NormalizeTranslationKeys>[] = [
  { label: "forms.activityItems.instrument.options.euro.label", value: "euro" },
  {
    label: "forms.activityItems.instrument.options.dollar.label",
    value: "Dollar",
  },
];
export const activityItems: FormBuilderProps<NormalizeTranslationKeys>["items"] =
  [
    {
      className: "grid grid-cols-1 md_lg:grid-cols-3 gap-4",
      children: [
        {
          name: "value",
          type: "number",
          label: "forms.activityItems.value.label",
          placeholder: "forms.activityItems.value.placeholder",
          step: "any",
          required: true,
        },
        {
          name: "instrument",
          type: "select",
          label: "forms.activityItems.instrument.label",
          options,
        },
        {
          name: "date",
          type: "date",
          label: "forms.activityItems.date.label",
          required: true,
        },
      ],
    },
  ];
export const genItem: FormBuilderProps<NormalizeTranslationKeys> = {
  items: [
    {
      className: "flex md:flex-row flex-col",
      children: [
        {
          label: "forms.genItem.name.label",
          name: "name",
          placeholder: "forms.genItem.name.placeholder",
        },
        {
          label: "forms.genItem.bankName.label",
          name: "bankName",
          placeholder: "forms.genItem.bankName.placeholder",
        },
        {
          label: "forms.genItem.type.label",
          name: "type",
          placeholder: "forms.genItem.type.placeholder",
        },
      ],
    },
    ...activityItems,
  ],
  action,
};
