import { FC, Fragment, useState } from "react";

import { Modal } from "components";
import FavoriteAccount from "./FavoriteAccount";
import { Loader } from "../../../components/Loader";
import { useFavoriteAccounts } from "core/helpers/hooks";
import { generateNumberArray } from "core/helpers/array";
import { ScrollableContainer } from "../../../components/Container";
import { FavoriteAccountsCreaction } from "./FavoriteAccountsCreaction";

interface FavoriteAccountsModule {
  hideContent?: boolean;
}
const FavoriteAccountsModule: FC<FavoriteAccountsModule> = ({
  hideContent = false,
}) => {
  const [open, setOpen] = useState(false);
  const { favoriteAccounts, isLoading } = useFavoriteAccounts();

  if (!favoriteAccounts) {
    return null;
  }

  return (
    <>
      <ScrollableContainer
        height={"auto"}
        title="title.favorite_accounts"
        onTitleClick={() => setOpen(true)}
        hideContent={hideContent}
      >
        <div className="flex flex-col gap-4 px-4" id="favorite-account">
          {isLoading ? (
            <div className="mt-3 min-h-[200px]">
              <FavoriteAccountSkeleton />
            </div>
          ) : (
            favoriteAccounts.map((item) => (
              <FavoriteAccount key={item.id} account={item} />
            ))
          )}
        </div>
      </ScrollableContainer>
      <Modal open={open} setOpen={setOpen} title="title.favorite_assets">
        <FavoriteAccountsCreaction />
      </Modal>
    </>
  );
};

function FavoriteAccountSkeleton() {
  return (
    <Loader className="min-h-[200px]">
      {generateNumberArray(4).map((i) => {
        const yOffset = i * 60; // 16px space between each set

        return (
          <Fragment key={i}>
            <circle cx="31" cy={22 + yOffset} r="22" />
            <rect x="64" y={yOffset} rx="2" ry="2" width="99" height="15" />
            <rect
              x="64"
              y={22 + yOffset}
              rx="2"
              ry="2"
              width="99"
              height="15"
            />
            <rect x="195" y={yOffset} rx="2" ry="2" width="99" height="15" />
            <rect
              x="195"
              y={22 + yOffset}
              rx="2"
              ry="2"
              width="99"
              height="15"
            />
          </Fragment>
        );
      })}
    </Loader>
  );
}

export default FavoriteAccountsModule;
