import { AssetType } from "@types";
import { useQuery } from "react-query";

import { useAppSelector } from "_redux";
import { rangeForPeriod } from "../convinients";
import { assets } from "../../../screens/private/assetById/components/assets/Assets.logic";

export function useAssets(type: AssetType) {
  const period = useAppSelector((state) => state.period.value);

  const range = period ? rangeForPeriod(period) : { start: null, end: null };

  return useQuery(["assets", type, period], () =>
    assets(type, range.start, range.end)
  );
}
