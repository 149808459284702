import { gql } from "graphql-request";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { clsx } from "core";
import { routes } from "routing";
import { Typo, Widget } from "components";
import { NormalizeTranslationKeys } from "i18n";
import { client } from "core/queries/makeGQLQuery";
import { calcInvestorProfileStats } from "screens/private/other/components/investorProfileForm/utils";

const InvestorProfileMapping = {
  experience: {
    0: "nonDefined",
    1: "beginner",
    2: "informed",
    3: "experienced",
  },
  risk: {
    0: "nonDefined",
    1: "offensive",
    2: "experienced",
    3: "balanced",
    4: "defensive",
    5: "secure",
  },
  sensitivity: {
    0: "nonDefined",
    1: "neutral",
    2: "moderate",
    3: "significant",
    4: "strong",
  },
  environmentalActivities: {
    0: "nonDefined",
    1: "sensible",
    2: "neutral",
    3: "unSensile",
  },
  environmentalSocialGoal: {
    0: "nonDefined",
    1: "sensible",
    2: "neutral",
    3: "unSensile",
  },
  negativeImpacts: {
    1: "yes",
    2: "no",
  },
};

export function InvestorProfile() {
  const { t } = useTranslation();

  const investorProfileForm = useQuery("investorProfileForm", () =>
    client
      .request(
        gql`
          query GetInvestorProfileForm {
            getUserInvestorProfileForm
          }
        `
      )
      .then((res) => res.getUserInvestorProfileForm as any)
  );

  const {
    risk,
    experience,
    sensitivity,
    negativeImpacts,
    environmentActivities,
    environmentSocialGoal,
  } = calcInvestorProfileStats(investorProfileForm.data);

  return (
    <div className="mt-8">
      <div className="mb-3 flex items-center justify-between">
        <Typo
          type="wlc_inter_xxl"
          className="text-base tracking-tighter text-primary"
        >
          title.investorProfile
        </Typo>
        <Link
          to={routes.other.path}
          className="ml-auto flex h-9 w-[83px] cursor-pointer items-end justify-end gap-2 rounded-[64px] border border-stone-200 bg-white py-1.5 pl-4 pr-3 hover:opacity-50"
        >
          <span className="text-center text-sm font-medium leading-normal text-slate-900">
            {t("actions.update")}
          </span>
        </Link>
      </div>
      <div className="flex flex-col gap-4">
        <InvestorProfileLine
          title="page.profil.investor.knowlegdeExperience"
          data={InvestorProfileMapping.experience}
          value={experience}
        />

        <InvestorProfileLine
          title="page.profil.investor.riskProfile"
          data={InvestorProfileMapping.risk}
          value={risk}
        />

        <InvestorProfileLine
          title="page.profil.investor.financialSensitivity"
          data={InvestorProfileMapping.sensitivity}
          value={sensitivity}
        />

        <InvestorProfileLine
          title="page.profil.investor.environmentalActivities"
          data={InvestorProfileMapping.environmentalActivities}
          value={environmentActivities}
        />

        <InvestorProfileLine
          title="page.profil.investor.environmentalSocialGoal"
          data={InvestorProfileMapping.environmentalSocialGoal}
          value={environmentSocialGoal}
        />

        <InvestorProfileLineBool
          title="page.profil.investor.negativeImpacts"
          data={InvestorProfileMapping.negativeImpacts}
          value={negativeImpacts}
          reason={investorProfileForm.data?.q19Answer}
        />
      </div>
    </div>
  );
}

interface InvestorProfileLine {
  data: { [key: number]: string };
  title: NormalizeTranslationKeys;
  value: number;
}
function InvestorProfileLine({ title, data, value }: InvestorProfileLine) {
  const { t } = useTranslation();

  const dataLength = Object.keys(data).length;
  const percentage = data[value] ? (value * 100) / (dataLength - 1) : 0;

  return (
    <Widget label={title}>
      <div className="mx-4">
        <div className="flex-row justify-between">
          <p className="text-content-tertiary font-sans text-sm font-medium text-zinc-400">
            {data[value]
              ? t(
                  `page.profil.investor.${data[value]}` as NormalizeTranslationKeys
                )
              : t("page.profil.investor.nonDefined")}
          </p>
        </div>
        <div className="bg-background-tertiary relative my-1 h-4 rounded-full">
          <progress
            max="100"
            value={percentage}
            className="h-6 w-full overflow-hidden rounded-xl"
            id="investorProfileProgress"
          />
        </div>
      </div>
    </Widget>
  );
}

function InvestorProfileLineBool({
  title,
  reason,
  data,
  value,
}: InvestorProfileLine & { reason: string }) {
  const { t } = useTranslation();

  return (
    <Widget label={title}>
      <div className="px-4">
        <div className="flex w-full flex-row rounded-full bg-grey p-0.5">
          {Object.keys(data).map((cur, index) => (
            <div
              key={index}
              className={clsx(
                "flex grow basis-2 items-center justify-center rounded-full px-3 py-1.5",
                value === Number(cur) && "bg-white"
              )}
            >
              <p
                className={clsx(
                  "font-sans text-sm font-bold",
                  Number(cur) !== value && "text-content-secondary"
                )}
              >
                {t(
                  `page.profil.investor.${
                    data[Number(cur)]
                  }` as NormalizeTranslationKeys
                )}
              </p>
            </div>
          ))}
        </div>
        <div className="mt-4">
          <p className="text-content-tertiary font-sans text-sm font-medium">
            {t("page.profil.investor.which")}
          </p>
          <div className="mt-1 rounded-xl border border-black/25 bg-[#F6F4F3] p-2">
            <p className="text-content-secondary font-sans">{reason}</p>
          </div>
        </div>
      </div>
    </Widget>
  );
}
