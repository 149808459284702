import { useEffect, useState } from "react";

import { clsx } from "core";
import { Icon } from "components";
import { Row } from "components/AssetRow";
import { Activity, Asset, AssetType } from "@types";
import { useAssets, useFavoriteAccounts } from "core/helpers/hooks";

export function FavoriteAccountsCreaction() {
  const { updateFavorites, favoriteAccounts } = useFavoriteAccounts();

  const [favorites, setFavorites] = useState<string[]>([]);

  const assetQuery = useAssets(AssetType.bankAccount);

  const filteredAssetData = assetQuery.data?.filter(
    (el) => el.currentActivity && el.currentActivity?.value !== 0
  ) as
    | (Asset & {
        currentActivity: Activity;
      })[]
    | undefined;

  useEffect(() => {
    setFavorites(favoriteAccounts?.map((account) => account.id) || []);
  }, [favoriteAccounts]);

  const onUpdate = (favorites: string[]) => {
    const hasPendingChanges =
      favorites.length !== favoriteAccounts?.length ||
      favorites.some(
        (id) => !favoriteAccounts?.find((asset) => asset.id === id)
      );
    if (hasPendingChanges) {
      updateFavorites(favorites);
    }
  };

  return (
    <div>
      {filteredAssetData?.map((asset) => {
        const isFavorite = favorites.includes(asset.id);

        return (
          <div key={asset.id}>
            <Row
              className={clsx(isFavorite ? "bg-gray-50" : "bg-transparent")}
              type="action"
              title={asset.name}
              assetType={AssetType.bankAccount}
              value={asset.currentActivity.value || 0}
              onClick={() => {
                if (isFavorite) {
                  onUpdate(favorites.filter((acc) => acc !== asset.id));
                } else {
                  onUpdate([...favorites, asset.id]);
                }
              }}
              Right={
                isFavorite ? (
                  <Icon
                    type="check"
                    className={clsx(
                      "mr-4 h-7 w-7 cursor-pointer",
                      isFavorite ? "fill-asset" : "fill-gray-400"
                    )}
                  />
                ) : undefined
              }
            />
          </div>
        );
      })}
    </div>
  );
}
