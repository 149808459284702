/* eslint-disable @typescript-eslint/no-unused-vars */
import { VFC } from "react";
import { useTranslation } from "react-i18next";

import { clsx } from "core";
import { SelectTypes } from "@types";
import { useFormBuilder } from "context";
import { FormLabel } from "../FormLabel";
import { AsyncReactSelect, CreatableReactSelect } from "./reactSelect";

export const DefaultSelect: VFC<SelectTypes> = ({
  name,
  label,
  options = [],
  defaultValue,
  errorType = "message",
  className,
  onChange,
  register,
  setValue,
  ...props
}) => {
  const _formBuilderCtx = useFormBuilder();
  const { t } = useTranslation();

  return (
    <div
      className={clsx(
        "overflow-hidden rounded-xl border border-primary-100 bg-white shadow-md invalid:border-pink-500 disabled:border-slate-200",
        className
      )}
    >
      {!!label && (
        <FormLabel
          name={name}
          label={label}
          className={_formBuilderCtx?.classNames?.label?.className as string}
        />
      )}
      <div className="flex w-full items-center overflow-hidden rounded-md focus:border-none sm:text-sm">
        <select
          id={name}
          {...(register
            ? register(name, {
                required: props.required,
                valueAsNumber: props.convertType == "number",
              })
            : {
                name,
                onChange: (e) =>
                  onChange?.(e.target.value, { form: { setValue } }),
              })}
          className="block w-full rounded-md border-none py-2 pl-3 pr-10 focus:border-none"
          defaultValue={defaultValue}
          {...props}
        >
          {options.map(({ value, label }) => (
            <option key={value} value={value}>
              {t(label)}
            </option>
          ))}
        </select>
      </div>
      {/* <GeoSearchSelect /> */}
    </div>
  );
};
export const Standard: VFC<SelectTypes> = ({ type, loadOptions, ...props }) => {
  if (type === "react-select-async" && loadOptions) {
    if (props.onCreate)
      return <CreatableReactSelect loadOptions={loadOptions} {...props} />;
    return <AsyncReactSelect loadOptions={loadOptions} {...props} />;
  }
  return <DefaultSelect {...props} />;
};
