import { FC } from "react";
import { QueryFunction, QueryKey } from "react-query";

import { Error, Loading } from "components";
import {
  SkeletonList,
  SkeletonListProps,
} from "components/skeleton/skeletonList";
import { useGQLQuery } from "core/queries/useQuery";

interface withGqlQueryProps {
  queryKey: QueryKey;
  queryFn: QueryFunction;
  loading?: SkeletonListProps;
  children: FC<{ data?: any }>;
}
export const WithGqlQuery: FC<withGqlQueryProps> = ({
  children,
  queryKey,
  loading,
  queryFn,
}: withGqlQueryProps) => {
  const { isLoading, data = {}, error } = useGQLQuery(queryKey, queryFn);

  if (isLoading) return loading ? <SkeletonList {...loading} /> : <Loading />;
  if (error) return <Error err={error} />;

  return children({ data });
};
