import { gql, GraphQLClient, Variables } from "graphql-request";

import { config } from "config";
import { AuthService } from "core/services";

function makeClient() {
  return new GraphQLClient(`${config.apiUrl}/graphql`, {
    headers: {
      authorization: `Bearer ${AuthService.getToken()}`,
    },
  });
}

export const client = makeClient();

export const makeGQLQuery = <T>({
  chunks,
  variables,
}: {
  chunks: string;
  variables?: Variables;
}): (() => Promise<T>) => {
  return async (): Promise<T> => {
    let data;

    try {
      if (config.apiUrl) {
        const graphqlClient = new GraphQLClient(`${config.apiUrl}/graphql`, {
          headers: {
            authorization: `Bearer ${AuthService.getToken()}`,
          },
          cache: "reload",
        });
        data = await graphqlClient.request(
          gql`
            ${chunks}
          `,
          variables
        );
      }
    } catch (error: any) {
      const has401Error =
        error?.response?.status === 401 ||
        error?.response?.errors?.find((e: any) => e.message == "Unauthorized");

      if (has401Error) {
        AuthService.localLogout();
      }
    }
    return data;
  };
};
