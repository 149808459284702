import Lottie from "lottie-react";

import animationData from "../lotties/wc_logo.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const Loading = () => (
  <div className="flex h-full w-full items-center">
    <Lottie {...defaultOptions} />
  </div>
);
