import { separete } from "core/helpers/object";
import { ManualFormFields } from "./manual";
import { action, options } from "./commons";
import { NormalizeTranslationKeys } from "i18n";

export const crowdfunding: ManualFormFields<NormalizeTranslationKeys> = {
  defaultRequired: true,
  items: [
    {
      className: "grid md:grid-cols-3 grid-cols-1",
      children: [
        {
          name: "name",
          label: "forms.crowdfunding.name.label",
          placeholder: "forms.crowdfunding.name.placeholder",
        },
        {
          name: "providerName",
          label: "forms.crowdfunding.providerName.label",
          placeholder: "forms.crowdfunding.providerName.placeholder",
        },
        {
          name: "investmentField",
          label: "forms.crowdfunding.investmentField.label",
          placeholder: "forms.crowdfunding.investmentField.placeholder",
        },
        {
          name: "subscribeDate",
          type: "date",
          label: "forms.crowdfunding.subscribeDate.label",
          placeholder: "forms.crowdfunding.subscribeDate.placeholder",
        },
        {
          name: "endDate",
          type: "date",
          label: "forms.crowdfunding.endDate.label",
          placeholder: "forms.crowdfunding.endDate.placeholder",
        },
        {
          name: "efficiency",
          type: "number",
          label: "forms.crowdfunding.efficiency.label",
          placeholder: "forms.crowdfunding.efficiency.placeholder",
        },
        {
          name: "value",
          type: "number",
          label: "forms.crowdfunding.value.label",
          placeholder: "forms.crowdfunding.value.placeholder",
          required: true,
        },
        {
          name: "instrument",
          type: "select",
          label: "forms.crowdfunding.instrument.label",
          options,
        },
      ],
    },
  ],
  action,
  formFormatter: (formValue) => {
    const [activity, crowdfunding] = separete(formValue, [
      "value",
      "instrument",
    ]);

    return {
      crowdfunding,
      activity: {
        ...activity,
        date: new Date(),
      },
    };
  },
};
