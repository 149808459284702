import React from "react";
import type { FC } from "react";

import PremiumBlur from "./PremiumBlur";
import { NormalizeTranslationKeys } from "i18n";
import { ScrollableContainer } from "components/Container";

type PremiumCardProps = {
  active?: boolean;
  className?: string;
  Right?: JSX.Element;
  children?: React.ReactNode;
  title: NormalizeTranslationKeys;
  type?: "gradient" | "container";
  onClick?: () => void;
};

const PremiumCard: FC<PremiumCardProps> = ({
  title,
  Right,
  children,
  active = true,
}) => {
  return (
    <PremiumBlur active={active} title={title}>
      <ScrollableContainer title={title} Right={Right}>
        <div className="px-4">{children}</div>
      </ScrollableContainer>
    </PremiumBlur>
  );
};

export default PremiumCard;
