import { clsx } from "core";
import { Text, TextPropsWithLabel } from "./Text";

export interface LabelProps {
  id?: string;
  className?: string;
  label: TextPropsWithLabel["label"];
  subTitle?: TextPropsWithLabel["label"];
  disableTranslate?: TextPropsWithLabel["disableTranslate"];
  disableSubtitleTranslate?: TextPropsWithLabel["disableTranslate"];
}

export function Label({
  id,
  label,
  subTitle,
  className,
  disableTranslate,
  disableSubtitleTranslate,
}: LabelProps): JSX.Element {
  return (
    <>
      <Text
        as="label"
        label={label}
        htmlFor={id || label?.toString()}
        disableTranslate={disableTranslate}
        className={clsx(
          "font-DMSansRegular text-blue-1100 w-fit text-sm leading-6",
          className
        )}
      />
      {subTitle && (
        <Text
          as="span"
          label={subTitle}
          disableTranslate={disableSubtitleTranslate}
          className={clsx(
            "font-DMSansRegular block text-xs leading-6 text-gray-400",
            className
          )}
        />
      )}
    </>
  );
}
