import { VFC } from "react";
import AsyncSelect from "react-select/async";

import { RoutesType } from "routing";
import { instrumentUrl } from "core/queries";
import { selectComponents } from "core/helpers/form";

interface CustomInstrumentSelectProps {
  value: any;
  onChange: (value: any) => void;
  placeholder?: string;
  category: RoutesType;
}

export interface Instrument {
  code: string;
  category: RoutesType;
  name: string;
  internationalCode?: string;
  logo?: string;
}
const loadOptions =
  (category: RoutesType) =>
  async (
    contains: string,
    callback: (
      options: { value: string; label: string; code: string }[]
    ) => void
  ) => {
    const data = await instrumentUrl({
      category,
      contains,
      limit: 200,
    })();

    callback(
      data.instruments?.map(
        ({ name: value, name: label, code }: Instrument) => ({
          value,
          label,
          code,
        })
      )
    );
  };

export const CustomInstrumentSelect: VFC<CustomInstrumentSelectProps> = ({
  value,
  onChange,
  category,
  placeholder = "Sélectionner",
  ...props
}) => (
  <AsyncSelect
    defaultOptions
    value={value}
    onChange={onChange}
    placeholder={placeholder}
    components={selectComponents}
    loadOptions={loadOptions(category) as any}
    {...props}
    styles={{
      control: (baseStyles) => ({
        ...baseStyles,
        borderColor: "transparent",
        backgroundColor: "white",
        borderRadius: 12,
        border: "0 !important",
        // This line disable the blue border
        boxShadow: "0 !important",
        "&:hover": {
          border: "0 !important",
        },
        "*": {
          boxShadow: "none !important",
        },
      }),
    }}
  />
);
