import { gql } from "graphql-request";
import { useMutation, useQueryClient } from "react-query";

import { AssetType } from "@types";
import { client } from "core/queries/makeGQLQuery";

async function deleteAssetMutation(id: string) {
  const mutation = gql`
    mutation deleteAssetType($id: String!) {
      deleteAssetType(id: $id) {
        id
        type
      }
    }
  `;

  const response = await client.request(mutation, { id });
  return response.deleteAssetType as {
    id: string;
    type: AssetType;
  };
}

export function useDeleteAsset(onSettled?: () => void) {
  const queryClient = useQueryClient();

  return useMutation(deleteAssetMutation, {
    onSuccess: ({ type }) => {
      queryClient.invalidateQueries(["periodActivities", type]);
      queryClient.invalidateQueries("wealth");
      queryClient.invalidateQueries(["wealthPerformances", type]);
      queryClient.invalidateQueries(["assets", type]);
    },
    onSettled,
  });
}
