import i18next from "i18next";
import { ChangeEvent, useEffect, useState } from "react";
import { FieldValues, UseFormRegister } from "react-hook-form";

import { clsx, isFn } from "core";
import { Label, LabelProps } from "../Label";
import { Text } from "../Text";

export interface SelectOption {
  value: string;
  disabled?: boolean;
  disableTranslate?: boolean;
  label: string | JSX.Element;
  logo?: string;
  optgroup?: string;
}
export type SelectProps<T> = {
  ns?: string;
  name: string;
  error?: string;
  label?: string;
  className?: string;
  labelClassName?: string;
  containerClassName?: string;
  disabled?: boolean;
  defaultValue?: string;
  options: SelectOption[];
  subTitle?: LabelProps["subTitle"];
  disableLabelTranslate?: LabelProps["disableTranslate"];
  disableSubtitleTranslate?: LabelProps["disableSubtitleTranslate"];
  onChange?: (value: string) => void;
  register?: T;
  required?: boolean;
  type?: string;
  placeholder?: string;
};

export function Select<T = UseFormRegister<FieldValues>>({
  name,
  error,
  label,
  options,
  register,
  subTitle,
  className,
  defaultValue,
  labelClassName,
  containerClassName,
  disableSubtitleTranslate,
  disableLabelTranslate = false,
  placeholder,
  ...props
}: SelectProps<T>) {
  const [selected, setSelected] = useState(defaultValue);

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelected(event.target.value);
    props.onChange?.(event.target.value);
  };

  useEffect(() => {
    setSelected(defaultValue);
  }, [defaultValue]);

  return (
    <div className={containerClassName}>
      {/* Label */}
      {label && (
        <Label
          label={label}
          className={labelClassName}
          subTitle={subTitle}
          disableTranslate={disableLabelTranslate}
          disableSubtitleTranslate={disableSubtitleTranslate}
        />
      )}

      {/* Select */}
      <select
        id={label?.toString()}
        name={name}
        {...(name ? { ["data-testid"]: name } : {})}
        value={selected}
        disabled={props.disabled}
        className={clsx(
          "block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6",
          className
        )}
        {...(isFn(register)
          ? register(name, {
              required: props.type === "hidden" ? false : props.required,
              valueAsNumber: props.type === "number",
            })
          : {
              name,
              required: props.type === "hidden" ? false : !!props.required,
              onChange: handleChange,
            })}
      >
        {placeholder && (
          <option value="" disabled>
            {placeholder}
          </option>
        )}
        {options
          .reduce((acc, option) => {
            if (option.optgroup) {
              const optgroup = acc.find(
                (item) => item.label === option.optgroup
              );

              if (optgroup && "options" in optgroup) {
                optgroup.options.push(option);
              } else {
                acc.push({
                  label: option.optgroup,
                  options: [option],
                });
              }
            } else {
              acc.push(option);
            }
            return acc;
          }, [] as (SelectOption | { label: string; options: SelectOption[] })[])
          .map((option) => {
            if ("options" in option) {
              return (
                <optgroup key={option.label} label={option.label}>
                  {options
                    .filter((item) => item.optgroup === option.label)
                    .map((item) => (
                      <Option
                        key={item.value}
                        label={item.label}
                        value={item.value}
                      />
                    ))}
                </optgroup>
              );
            }

            return (
              <Option
                key={option.value}
                label={option.label}
                value={option.value}
              />
            );
          })}
      </select>

      {/* Error */}
      {error && (
        <Text as="span" label={error} className="text-xs text-red-400" />
      )}
    </div>
  );
}

type OptionProps = {
  value: string;
  label: string | JSX.Element;
  disableTranslate?: boolean;
};

const Option: React.FC<OptionProps> = ({
  value,
  label,
  disableTranslate,
  ...props
}) => {
  let _label = label;

  if (
    typeof label === "string" &&
    disableTranslate !== true &&
    !disableTranslate
  ) {
    _label = i18next.t(label) as string;
  }

  return (
    <option key={value} value={value} {...props}>
      {_label}
    </option>
  );
};
