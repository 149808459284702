import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { clsx } from "core";
import { AssetType } from "@types";
import { Typo } from "components/Typo";
import { numberFormat } from "core/helpers/number";
import { Icon, Period, PeriodMap } from "components";
import { RadioSelect } from "components/RadioSelect";
import DoubleLabel from "components/DoubleLabel/DoubleLabel";

type Type = "brut" | "net";
interface PriceProps {
  net: number;
  brut: number;
  price: number;
  title: string;
  icon?: string;
  showType: Type;
  type: AssetType;
  period?: Period;
  percent?: number;
  evolution: number;
  showBrut?: boolean;
  showGain?: boolean;
  className?: string;
  showPercent: boolean;
  showToggler?: boolean;
  gain: {
    value: number;
    instrument: string;
  };
  gainValue: number;
  onClick?: (type: Type) => void;
}

export function PriceBox(props: PriceProps) {
  const [price, setPrice] = useState(props.net);
  const [gain, setGain] = useState(props.gain?.value);
  const [showType, setShowType] = useState<Type>("brut");
  const [percent, setPercent] = useState(props.evolution);

  useEffect(() => {
    if (props.showGain) {
      setGain(props.gain?.value);
      setPercent(props.evolution);
    }
    setPrice(props.showBrut ? props.brut : props.net);
  }, [props]);

  const onTypeChange = (type: Type) => {
    setShowType(type);

    setPrice(type === "brut" && props.showBrut ? props.brut : props.net);
  };

  return (
    <div
      className={clsx(
        "flex h-fit w-fit gap-x-2",
        props.type === "crypto" || props.type === "stocks" ? "h-full" : ""
      )}
    >
      {props.icon && <Icon type={props.icon} className="invisible h-6 w-6" />}
      <div>
        <Header
          {...props}
          price={price}
          showType={showType}
          onClick={onTypeChange}
        />
        <Content
          {...props}
          onClick={onTypeChange}
          showType={showType}
          percent={percent}
          price={price}
          gainValue={gain}
        />
      </div>
    </div>
  );
}

function Header(props: PriceProps) {
  const { t } = useTranslation();

  return (
    <div className="mb-1 flex">
      <div className="hidden items-center sm:flex">
        <div className="group relative flex cursor-pointer items-center">
          <Typo
            children={props.title}
            className={`mb-1 text-_26 font-bold ${
              props.showBrut ? "text-primary" : "text-asset"
            }`}
          />
          {props.showToggler && <Icon type="arrow" />}
          {props.showBrut && (
            <RadioSelect
              defaultValue={props.showType}
              list={[
                {
                  element: t("header.amounts.gross"),
                  id: "brut",
                },
                {
                  element: t("header.amounts.net"),
                  id: "net",
                },
              ]}
              onChange={(value) => props.onClick?.(value as Type)}
            />
          )}
        </div>
        {props.period !== undefined &&
          props.type !== "crypto" &&
          props.type !== "stocks" && (
            <Typo
              className="mb-2 mt-1 text-lg font-bold text-primary"
              children={`${PeriodMap[props.period as Period]}`}
            />
          )}
      </div>
      <div className="flex items-center gap-x-1 sm:hidden">
        <Typo
          type="wlc_inter_m"
          children={props.title}
          className="font-bold text-white"
        />
        {props.period !== undefined && (
          <Typo
            type="wlc_inter_s"
            className="mb-2 text-_16 font-bold text-white"
            children={`${PeriodMap[props.period as Period]}`}
          />
        )}
      </div>
    </div>
  );
}

function Content(props: PriceProps) {
  return (
    <div
      className={clsx(
        "flex gap-2",
        props.showBrut ? "items-center" : "_700_items-end items-start",
        props.type === "crypto" || props.type === "stocks" ? "h-full" : ""
      )}
    >
      <div
        className={clsx(
          props.type === "crypto" || props.type === "stocks"
            ? "flex _700:h-full"
            : ""
        )}
      >
        {!props.showGain && (
          <div>
            <p
              className={clsx(
                "text-base font-bold text-primary sm:text-_32",
                props.type === "crypto" || props.type === "stocks"
                  ? "header-price mt-1"
                  : ""
              )}
            >
              {numberFormat(props.price, { maximumFractionDigits: 0 })}
            </p>
          </div>
        )}

        {props.showGain && props.percent !== undefined && (
          <div className="flex gap-x-2">
            <DoubleLabel
              data={{
                title: "Gain",
                gain: props.gainValue,
                percent: props.percent,
                price: props.price,
              }}
              displayValue="gain"
              className="text-lg"
            />
            <DoubleLabel
              data={{
                title: "Gain",
                gain: props.gainValue,
                percent: props.percent,
                price: props.price,
              }}
              displayValue="percent"
              className="text-lg"
            />
          </div>
        )}
      </div>
    </div>
  );
}
