export enum AssetType {
  loan = "loan",
  crypto = "crypto",
  stocks = "stocks",
  exotic = "exotic",
  savings = "savings",
  economies = "economies",
  realEstate = "realEstate",
  retirements = "retirement",
  bankAccount = "bankAccount",
  crowdfunding = "crowdfunding",
  lifeInsurance = "lifeInsurance",
  privateCivilRealEstate = "privateCivilRealEstate",
}

export enum InverstorProfileExperience {
  expert = "expert",
  beginner = "beginner",
  intermediate = "intermediate",
}

export interface Activity {
  end: Date;
  id: string;
  start: Date;
  instrument: string;
  value: number;
}
export enum AssetStatus {
  active = "active",
  manual = "manual",
  pending = "pending",
  expired = "expired",
}
export enum InstrumentCategory {
  crypto = "crypto",
  currency = "currency",
  stock = "stock",
  etf = "etf",
  obligations = "obligations",
  rawMaterials = "rawMaterials",
}

export type Instrument = {
  code: string;
  category: InstrumentCategory;
  name: string;
  internationalCode?: string;
  logo?: string;
};

export type Investment = {
  id: string;
  code: string;
  label: string;
  unitValue: string;
  quantity: number;
  valuation: number;
};

export interface IPerformance {
  evolution?: number;
  gain: PerformanceAmount;
}
export interface Asset {
  id: string;
  update: Date;
  name: string;
  creation: Date;
  type: AssetType;
  activity: Activity;
  status: AssetStatus;
  activities: Activity[];
  currentActivity: Activity;
  performance?: IPerformance;
  investments?: Investment[];
  isUnderManagement?: boolean;
  // performance(start: Date, end: Date): IPerformance;
}
export interface Retirement {
  id: string;
  name: string;
  type: string;
  asset: Asset;
  bankName: string;
}
export interface Stock {
  id: string;
  name: string;
  code: string;
  creation: Date;
  quantity: number;
  unitPrice: number;
  unitValue: number;
  valuation: number;
  instrument?: Instrument;
  budgetInsightId: string;
}

export type Cryptos = Stock;
export interface RealEstate extends Stock {
  buying_price: number;
  asset?: Asset;
}
export interface Exotic {
  id: string;
  name: string;
  asset: Asset;
  creation: Date;
  quantity: number;
  category: string;
  ownership: number;
  unitPrice: number;
  purchaseDate: Date;
}
export interface Crowdfunding {
  id: string;
  name: string;
  asset: Asset;
  endDate: Date;
  efficiency: number;
  subscribeDate: Date;
  providerName: string;
  investmentField: string;
}
export interface LifeInsurance {
  id: string;
  name: string;
  creation: Date;
  efficiency: number;
  transfersAmount: number;
  insuranceCompany: string;
  asset: Asset;
}

export interface PlatformAccount {
  id: string;
  name: string;
  price: number;
  asset: Asset;
  stocks: [Stock];
  type: AssetType;
  exchange: string;
  cryptos: [Cryptos];
  [x: string]: any;
}

export interface Question {
  id: string;
  answers: string[];
}
export interface SCPI {
  type: string;
  name?: string;
  state: string;
  dividend?: string;
  gestion_fees: string;
  capitalization: string;
  withdrawal_price: string;
  subscription_fees: string;
  subscription_price?: string;
}

export interface PrivateCivilRealEstate {
  id: string;
  scpi: SCPI;
  asset: Asset;
  type: string;
  name: string;
  code?: string;
  quantity: number;
  creation: string;
  unitPrice: number;
  unitValue: number;
  valuation: number;
  purschaseDate: Date;
}
export interface Loan {
  id: string;
  start: Date;
  type: string;
  asset: Asset;
  name: string;
  duration: number;
  interests: number;
  assuranceFee: number;
  loanedAmount: number;
  amountLocked: number;
  applicationFee: number;
  monthlyPayment: number;
}

export interface Wealth {
  type: AssetType;
  activity: Activity;
  performance?: IPerformance;
}
export interface FeeAggregation {
  key: string;
  name: string;
  value: number;
  instrument: string;
  reprensentation: number;
}

export interface RetirementSimulationObject {
  id: string;
  userId: string;
  dateOfBirth: Date;
  startOfActivity: Date;
  endOfActivity: Date;
  yearsOfInterruption: number;
  profession: string;
  best25Year: number;
}

export interface AssetById {
  id: string;
  name: string;
  type: AssetType;
  status: AssetStatus;
  activities: Activity[];
  currentActivity: Activity;
  performance?: Performance;
  creation: Date;
  update: Date;
  activity: Activity;
  valuationInTime: Activity[];
  // performance(start: Date, end: Date): Performance;
}

export interface Amount {
  value: number;
  instrument: string;
}
export interface PerformanceAmount {
  value: number;
  instrument: string;
}
export interface Performance {
  evolution?: number;
  gain: PerformanceAmount;
}

export interface FavoriteAssetType {
  type: AssetType;
  amount: PerformanceAmount;
  performance: Performance;
}
