import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { clsx } from "core";
import { Icon } from "components";
import { NormalizeTranslationKeys } from "i18n";

export function Button({
  label,
  type = "button",
  onClick,
  to,
  icon,
  rounded,
  className,
  disabled,
}: {
  disabled?: boolean;
  label?: NormalizeTranslationKeys | null;
  type?: "button" | "submit" | "reset";
  onClick?: any;
  to?: any;
  icon?: string;
  rounded?: boolean;
  className?: string;
}) {
  const iconComponent = icon ? (
    <Icon type={icon} className="h-4 w-4" aria-hidden="true" />
  ) : null;

  const { t } = useTranslation();

  const text = t((label ?? "") as NormalizeTranslationKeys);

  return (
    <>
      {to ? (
        <NavLink
          type="button"
          className={clsx(
            "text-secondary mx-4 flex items-center gap-x-2 truncate text-clip rounded-md border border-transparent px-2 py-1 text-xs font-medium md:text-sm",
            rounded ? "rounded-full" : "",
            className
          )}
          to={to}
        >
          {text}

          {iconComponent}
        </NavLink>
      ) : (
        <button
          disabled={disabled}
          type={type}
          className={clsx(
            "text-secondary mx-4 flex items-center gap-x-2 truncate text-clip rounded-md border border-transparent bg-primary-400 px-2 py-2 text-xs font-medium text-white hover:bg-primary-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 md:text-sm",
            rounded ? "rounded-full" : "",
            className
          )}
          onClick={onClick}
        >
          <p>{text}</p>

          {iconComponent}
        </button>
      )}
    </>
  );
}
