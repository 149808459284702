import type { FC } from "react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { routes } from "routing";
import { AssetType } from "@types";
import { InfoLine } from "../InfoLine";
import { NormalizeTranslationKeys } from "i18n";
import { dateFormat, numberFormat } from "core";
import { useAssetActions } from "./AssetActions/AssetActions";
import { getPrivateCivilRealEstateById } from "./Assets.logic";
import { useDeleteAsset } from "core/helpers/hooks/useDeleteAsset";
import { ManualUpdateModal, ScrollableContainer } from "components";

type PrivateCivilRealEstateViewProps = {
  id: string;
};

const PrivateCivilRealEstateView: FC<PrivateCivilRealEstateViewProps> = ({
  id,
}) => {
  const privateCivilRealEstateQuery = useQuery(["privateRealEstate", id], () =>
    getPrivateCivilRealEstateById(id)
  );
  const navigation = useNavigate();
  const { mutate: deleteAsset } = useDeleteAsset(() => {
    navigation(routes.privateCivilRealEstate.path);
  });

  const { render, state, dispatch } = useAssetActions(["edit", "delete"], {
    onDelete: async () => deleteAsset(id),
  });

  const { t } = useTranslation();

  if (!privateCivilRealEstateQuery.data) {
    return null;
  }

  return (
    <>
      <ScrollableContainer title="title.informations" Right={render}>
        <div className="p-4 pt-0">
          <InfoLine
            label="formLabel.price"
            value={numberFormat(privateCivilRealEstateQuery.data.unitPrice)}
          />
          <InfoLine
            label="formLabel.buyingDate"
            value={
              privateCivilRealEstateQuery.data.purschaseDate
                ? dateFormat(privateCivilRealEstateQuery.data.purschaseDate)
                : undefined
            }
          />

          <InfoLine
            label="formLabel.quantity"
            value={privateCivilRealEstateQuery.data.quantity}
          />

          <InfoLine
            label="formLabel.subscription_price"
            value={
              privateCivilRealEstateQuery.data.scpi.subscription_price || "N/A"
            }
          />
        </div>
      </ScrollableContainer>

      <ScrollableContainer title="title.realEstateDetails">
        <div className="p-4 pt-0">
          <InfoLine
            label="formLabel.houseType"
            value={
              t(
                `page.scpi.${privateCivilRealEstateQuery.data.type}` as NormalizeTranslationKeys
              ) as string
            }
          />

          <InfoLine
            label="formLabel.categorie"
            value={privateCivilRealEstateQuery.data.scpi.type}
          />

          <InfoLine
            label="formLabel.yieldDVM"
            value={
              privateCivilRealEstateQuery.data.scpi.dividend
                ? privateCivilRealEstateQuery.data.scpi.dividend + " %"
                : "N/A"
            }
          />

          <InfoLine
            label="formLabel.subscriptionFees"
            value={
              privateCivilRealEstateQuery.data.scpi.subscription_fees || "N/A"
            }
          />

          <InfoLine
            label="formLabel.gestionFees"
            value={privateCivilRealEstateQuery.data.scpi.gestion_fees || "N/A"}
          />
        </div>
        {!!state.edit && (
          <ManualUpdateModal
            selected={privateCivilRealEstateQuery.data}
            editType={"update"}
            queryType={AssetType.privateCivilRealEstate}
            onClose={() => dispatch({ type: "edit" })}
            handleUpdate={privateCivilRealEstateQuery.refetch}
            handleDelete={console.log}
            handleActivityDelete={console.log}
          />
        )}
      </ScrollableContainer>
    </>
  );
};

export default PrivateCivilRealEstateView;
