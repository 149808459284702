import { useEffect, useState, VFC } from "react";
import { ChevronRightIcon } from "@heroicons/react/solid";

import { clsx, isArray } from "core";
import { Container } from "components";
import { UserInterface } from "@types";

interface Tab {
  title: string;
  id: string;
  component?: JSX.Element;
  onClick?: (id: string) => void;
  icon?: string;
}

export interface TabGroup {
  tabs: Tab[];
}
interface Props {
  tabsGroup: TabGroup[];
  bottomLeftContent?: JSX.Element | JSX.Element[];
  defaultValue?: string;
  user?: UserInterface | null;
  onClick?: () => void;
}

export const Tabs: VFC<Props> = ({
  tabsGroup,
  bottomLeftContent,
  defaultValue,
  user,
}) => {
  const [currentTab, setCurrentTab] = useState(defaultValue);
  const [view, setView] = useState<Tab["component"] | undefined>(
    tabsGroup[0].tabs[0].component
  );
  const setActiveTab = (
    groupIndex: number,
    tabIndex: number,
    tabId: string
  ) => {
    setCurrentTab(tabId);
    const tab = tabsGroup[groupIndex].tabs[tabIndex];

    if (tab.component) {
      setView(tab.component);
    }
  };

  useEffect(() => {
    const tabId = defaultValue || tabsGroup[0].tabs[0].id;

    let tabIndex = 0;
    const groupIndex = tabsGroup.findIndex((group) => {
      tabIndex = group.tabs.findIndex((tab) => tab.id === tabId);

      return tabIndex !== -1;
    });

    setActiveTab(groupIndex, tabIndex, tabId);
  }, [defaultValue]);

  return (
    <div className="flex w-full flex-col gap-x-4 lg:flex-row">
      <div className="flex w-full flex-col gap-x-4 px-1 lg:w-4/12">
        {user && (
          <div className="mb-7 flex gap-x-4">
            <img
              src={`svg/user_${user.isPremium ? "premium" : "basic"}.svg`}
              className="h-14 w-14"
            />
            <div className="flex flex-col items-center justify-center">
              <p className="text-2xl font-bold tracking-tight text-primary">
                {user.name}
              </p>
              <p className="text-sm text-primary-600">{user.email}</p>
            </div>
          </div>
        )}
        <div className="flex w-full snap-x scroll-pl-6 flex-row justify-end gap-y-4 gap-x-5 overflow-x-hidden rounded-xl bg-none p-0 px-1 pb-4 lg:flex-col">
          {tabsGroup.map((tabGroup, groupIndex) => (
            <Container
              key={`tab-group-${groupIndex}`}
              className="rounded-xl p-0 drop-shadow-md"
            >
              <div className="overflow-hidden rounded-xl">
                {tabGroup.tabs.map((tab, tabId) => {
                  const id = tab.id;
                  const isCurrentTab = currentTab === id;

                  return (
                    <Container
                      key={`tab-${groupIndex}-${tabId}`}
                      className={clsx(
                        "mb-0 flex w-fit shrink-0 cursor-pointer flex-row items-center rounded-none bg-white p-3 _700:justify-start lg:w-full lg:flex-row",
                        isCurrentTab
                          ? "w-[49%] justify-center"
                          : "w-[49%] justify-center hover:bg-gray-200"
                      )}
                      onClick={() => {
                        tab.onClick
                          ? tab.onClick(id)
                          : setActiveTab(groupIndex, tabId, id);
                      }}
                    >
                      <div className="flex items-center gap-x-3">
                        {tab.icon && (
                          <img
                            src={`svg/profile_${tab.icon}.svg`}
                            className="h-10 w-10"
                          />
                        )}
                        <p
                          className={clsx(
                            "font-bold tracking-tight",
                            isCurrentTab
                              ? "text-neutral-800"
                              : "text-primary-600"
                          )}
                        >
                          {tab.title}
                        </p>
                      </div>
                      <ChevronRightIcon
                        className={clsx("mr-2 h-5 w-5 text-gray-400")}
                        aria-hidden="true"
                      />
                    </Container>
                  );
                })}
              </div>
            </Container>
          ))}
        </div>
        {bottomLeftContent && (
          <div
            className={`flex ${
              isArray(bottomLeftContent) ? "flex-col" : "flex-row"
            } mb-4 w-full snap-x scroll-pl-6 justify-between gap-x-5 overflow-x-auto px-1`}
          >
            {bottomLeftContent}
          </div>
        )}
      </div>
      <div className="mx-auto w-full overflow-visible rounded-xl bg-transparent py-5 px-6 lg:w-8/12">
        {view}
      </div>
    </div>
  );
};
