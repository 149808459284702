import { gql } from "graphql-request";

import { client } from "core/queries/makeGQLQuery";

export async function budgetInsightConnections() {
  const query = gql`
    query BudgetInsightConnections {
      budgetInsightConnections {
        id
        active
        state
        last_update
        created
        connector {
          id
          name
          color
          uuid
        }
      }
    }
  `;

  const response = await client.request(query).catch((error) => {
    if (error.response.data) {
      return error.response.data;
    }

    throw error;
  });

  return response.budgetInsightConnections as {
    id: number;
    active: boolean;
    state?: string;
    last_update: string;
    created: string;
    connector: {
      id: string;
      name: string;
      color?: string;
      uuid: string;
    };
  }[];
}

export async function manageConnection() {
  const query = gql`
    query BudgetInsightManage {
      budgetInsightManage
    }
  `;

  const response = await client.request(query).catch((error: any) => {
    if (error.response.data) {
      return error.response.data;
    }

    throw error;
  });

  return response.budgetInsightManage as string;
}
