import { z } from "zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

import * as formData from "./investorProfileFormData";
import { Button, Input } from "../../../../../components";
import {
  RadioList,
  CheckBoxList,
  RadioWithGraph,
  RadioWithCheckboxList,
  makeZodBoolFromObject,
} from "./utils";

const radiosSchema = z.object({
  q1: makeZodBoolFromObject(formData.q1Object),
  q2: makeZodBoolFromObject(formData.q2Object),
  ...formData.q3a.list.reduce(
    (prev, currentList, index) => ({
      ...prev,
      [`q3a.${index + 1}`]: z.enum(
        currentList.list.map((item) => item.value) as unknown as readonly [
          string,
          ...string[]
        ]
      ),
    }),
    {}
  ),
  ...formData.q3b.list.reduce(
    (prev, currentList, index) => ({
      ...prev,
      [`q3b.${index + 1}`]: z.enum(
        currentList.list.map((item) => item.value) as unknown as readonly [
          string,
          ...string[]
        ]
      ),
    }),
    {}
  ),
  q4: z.enum(
    formData.q4List.map((item) => item.value) as unknown as readonly [
      string,
      ...string[]
    ]
  ),
  q5: z.enum(
    formData.q5List.map((item) => item.value) as unknown as readonly [
      string,
      ...string[]
    ]
  ),
  q6: z.enum(
    formData.q6List.map((item) => item.value) as unknown as readonly [
      string,
      ...string[]
    ]
  ),
  q7: z.enum(
    formData.q7List.map((item) => item.value) as unknown as readonly [
      string,
      ...string[]
    ]
  ),
  q8: z.enum(
    formData.q8List.map((item) => item.value) as unknown as readonly [
      string,
      ...string[]
    ]
  ),
  q9a: z.enum(
    formData.q9aList.map((item) => item.value) as unknown as readonly [
      string,
      ...string[]
    ]
  ),
  q9b: z.enum(
    formData.q9aList.map((item) => item.value) as unknown as readonly [
      string,
      ...string[]
    ]
  ),
  q10: z.enum(
    formData.q10List.map((item) => item.value) as unknown as readonly [
      string,
      ...string[]
    ]
  ),
  q11: z.enum(
    formData.q11List.map((item) => item.value) as unknown as readonly [
      string,
      ...string[]
    ]
  ),
  q12: z.enum(
    formData.q12List.map((item) => item.value) as unknown as readonly [
      string,
      ...string[]
    ]
  ),
  q13: z.enum(
    formData.q13List.map((item) => item.value) as unknown as readonly [
      string,
      ...string[]
    ]
  ),
  q14: makeZodBoolFromObject(formData.q14Object),
  q15: z.enum(
    formData.q15List.map((item) => item.value) as unknown as readonly [
      string,
      ...string[]
    ]
  ),
  q16: z.enum(
    formData.q16List.map((item) => item.value) as unknown as readonly [
      string,
      ...string[]
    ]
  ),
  q17: makeZodBoolFromObject(formData.q17Object),
  q18: makeZodBoolFromObject(formData.q18Object),
  q19: z.enum(
    formData.q19List.map((item) => item.value) as unknown as readonly [
      string,
      ...string[]
    ]
  ),
  q19Answer: z.string().optional(),
});

// Combine the schemas

export type InvestorProfileFormInputs = z.infer<typeof radiosSchema>;

interface InvestorProfileForm {
  defaultValue: any;
  onSubmit: (data: InvestorProfileFormInputs) => void;
}
export function InvestorProfileForm({
  onSubmit,
  defaultValue: defaultValues = {},
}: InvestorProfileForm) {
  const { reset, register, handleSubmit, watch } =
    useForm<InvestorProfileFormInputs>({
      defaultValues,
    });

  useEffect(() => reset(defaultValues), [defaultValues]);

  const values = watch("q19");
  const q19IsPositive = values === "1";

  return (
    <form onSubmit={handleSubmit((data) => onSubmit(data))}>
      {/* Question 1 */}
      <CheckBoxList data={formData.q1Object} i18nKey="q1" register={register} />

      {/* Question 2 */}
      <CheckBoxList data={formData.q2Object} i18nKey="q2" register={register} />

      {/* Question 3 (A / B) */}
      <div>
        <RadioWithCheckboxList
          data={formData.q3a}
          i18nKey="q3a.1"
          register={register}
        />
        <RadioWithCheckboxList
          data={formData.q3b}
          i18nKey="q3b.1"
          register={register}
          checkbable={false}
          align="horizontal"
        />
      </div>

      {/* Question 4 */}
      <RadioList
        list={formData.q4List}
        i18nKey="q4"
        name="q4"
        register={register}
      />

      {/* Question 5 */}
      <RadioList
        list={formData.q5List}
        i18nKey="q5"
        name="q5"
        register={register}
      />

      {/* Question 6 */}
      <RadioList
        list={formData.q6List}
        i18nKey="q6"
        name="q6"
        register={register}
      />

      {/* Question 7 */}
      <RadioWithGraph
        list={formData.q7List}
        i18nKey="q7"
        name="q7"
        register={register}
      />

      {/* Question 8 */}
      <RadioWithGraph
        list={formData.q8List}
        i18nKey="q8"
        name="q8"
        register={register}
      />

      {/* Question 9 (A / B) */}
      <div>
        <RadioWithGraph
          list={formData.q9aList}
          i18nKey="q9a"
          name="q9a"
          register={register}
        />

        <RadioWithGraph
          list={formData.q9bList}
          i18nKey="q9b"
          name="q9b"
          register={register}
        />
      </div>

      {/* Question 10 */}
      <RadioList
        list={formData.q10List}
        i18nKey="q10"
        name="q10"
        register={register}
      />

      {/* Question 11 */}
      <RadioList
        list={formData.q11List}
        i18nKey="q11"
        name="q11"
        register={register}
      />

      {/* Question 12 */}
      <RadioList
        list={formData.q12List}
        i18nKey="q12"
        name="q12"
        register={register}
      />

      {/* Question 13 */}
      <RadioList
        name="q13"
        list={formData.q13List}
        i18nKey="q13"
        register={register}
      />

      {/* Question 14 */}
      <CheckBoxList
        data={formData.q14Object}
        i18nKey="q14"
        register={register}
      />

      {/* Question 15 */}
      <RadioList
        list={formData.q15List}
        i18nKey="q15"
        name="q15"
        register={register}
      />

      {/* Question 16 */}
      <RadioList
        list={formData.q16List}
        i18nKey="q16"
        name="q16"
        register={register}
      />

      {/* Question 17 */}
      <CheckBoxList
        data={formData.q17Object}
        i18nKey="q17"
        register={register}
      />

      {/* Question 19 */}
      <CheckBoxList
        data={formData.q18Object}
        i18nKey="q18"
        register={register}
      />

      <div>
        {/* Question 19 */}
        <RadioList
          list={formData.q19List}
          i18nKey="q19"
          name="q19"
          register={register}
        />
        {q19IsPositive && (
          <Input
            type="text"
            name="q19Answer"
            register={register}
            label="Si oui, lesquelles (exemple : gaz à effet de serre, droits du travail, égalité) :"
          />
        )}
      </div>

      <Button
        label="button.save"
        type="submit"
        className="mt-6 bg-primary-400 text-white"
      />
    </form>
  );
}
