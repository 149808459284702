import { format } from "date-fns";
import { fr } from "date-fns/locale";

export const dateIsBefore = (first, last) => {
  var d1 = new Date(first);
  var d2 = new Date(last);

  return d1.getTime() === d2.getTime();
};

export const addOrSubtractDay = (day = 0, startingDay = new Date()) => {
  const newDate = new Date(startingDay);

  newDate.setDate(newDate.getDate() + day);

  return newDate;
};

export const nbDayBetween = (first, last = new Date()) => {
  const d1 = new Date(first);
  const d2 = new Date(last);

  return Math.round((d2 - d1) / (1000 * 60 * 60 * 24));
};

export const formatDate = (date, dateFormat = "MM-dd-yyyy") =>
  format(new Date(date), dateFormat, {
    locale: fr,
  });

export const monthDiff = (d1, d2) => {
  let months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();

  return months <= 0 ? 0 : months;
};
