import { FC } from "react";

import { useFormBuilder } from "context/formBuilderCtx";
import { FormErrorType, InputTypes } from "@types";
import { clsx } from "core";
import { FormLabel } from "../FormLabel";
import { Error, Preffix } from "../utils";
import { useTranslation } from "react-i18next";
import { NormalizeTranslationKeys } from "i18n";

export const Standard: FC<InputTypes> = ({
  name,
  label,
  error,
  sufix,
  prefix,
  className = "",
  errorType = "message",
  required,
  defaultValue,
  register,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setValue,
  ...props
}) => {
  const { t } = useTranslation();
  const _formBuilderCtx = useFormBuilder();
  const errorMessageType: FormErrorType[] = ["message", "message-bordered"];
  const errorBorderType: FormErrorType[] = ["bordered", "message-bordered"];
  const Element = props.type === "textarea" ? "textarea" : "input";

  const isDate = ["date", "datetime-local"].includes(props.type);

  if (isDate && defaultValue) {
    defaultValue = formatDate(new Date(defaultValue));
  }

  return (
    <div
      className={clsx(
        "rounded-xl border border-primary-100 bg-white shadow-md invalid:border-pink-500 disabled:border-slate-200 disabled:shadow-none",
        _formBuilderCtx?.classNames?.input?.wrapper,
        className
      )}
    >
      {!!label && (
        <FormLabel
          name={name}
          label={label}
          className={_formBuilderCtx?.classNames?.label?.className}
        />
      )}
      <div
        className={clsx(
          "input-add flex w-full items-center overflow-hidden rounded-xl focus-within:border focus-within:border-secondary-500 focus-within:ring-indigo-500",
          "sm:text-sm",
          " invalid:text-pink-600 disabled:bg-slate-50 disabled:text-slate-500",
          prefix && "input-prefix pl-3",
          sufix && "input-suffix pr-3",
          error && errorBorderType.includes(errorType) && "border-red-500",
          _formBuilderCtx?.classNames?.input?.container
        )}
      >
        {prefix && <Preffix message={prefix} />}
        <Element
          id={name}
          className={clsx(
            "w-full flex-1 border-none focus:border-none focus:border-secondary-500 focus:ring-secondary-500",
            _formBuilderCtx?.classNames?.input?.className
          )}
          defaultValue={defaultValue}
          {...props}
          placeholder={
            props.placeholder &&
            (t(props.placeholder as NormalizeTranslationKeys) as string)
          }
          {...(register
            ? register(name, {
                required: props.type === "hidden" ? false : required,
                valueAsNumber:
                  props.type == "number" || props.convertType === "number",
              })
            : { name, required: props.type === "hidden" ? false : !!required })}
        />
        {sufix && <Preffix message={sufix} />}
      </div>
      {error && errorMessageType.includes(errorType) && (
        <Error message={error} />
      )}
    </div>
  );
};

function formatDate(date: Date) {
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2); // Months are zero-based. Add leading 0.
  const day = ("0" + date.getDate()).slice(-2); // Add leading 0.

  return `${year}-${month}-${day}`;
}
