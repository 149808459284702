import { Period } from "components";

export type DateRange = {
  start: Date | null;
  end: Date | null;
};

/**
 * Date range for a given period.
 * Return a range with an end date at the current time, and the starting date at x days in the past.
 * @param period The period to compute the range with. If the period is not handled a global range will be return
 * @description If a period of 0 days is provided an global range will be returned
 */
export function rangeForPeriod(period: Period): DateRange {
  const oneDay = 24 /* hours */ * 60 /* minutes */ * 60 /* seconds */ * 1000;
  const now = new Date();
  const todayTime = new Date(
    now.getUTCFullYear(),
    now.getMonth(),
    now.getDate()
  ).getTime();
  const towmorrow = new Date(todayTime + oneDay);

  switch (period) {
    case 0:
      return { start: null, end: null };
    case 7:
      return { start: new Date(todayTime - 7 * oneDay), end: towmorrow };
    case 30:
      return {
        start: new Date(todayTime - 30 * oneDay),
        end: towmorrow,
      };
    case 90:
      return { start: new Date(todayTime - 90 * oneDay), end: towmorrow };
    case 365:
      return { start: new Date(todayTime - 365.25 * oneDay), end: towmorrow };
  }
  return { start: null, end: null };
}
