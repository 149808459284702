import { clsx } from "core";
import { Icon } from "./Icon";

interface ArrowProps {
  class?: string;
  currentSlide?: number;
  slideCount?: number;
  onClick?: () => void;
}

export function SamplePrevArrow(props: ArrowProps) {
  return (
    <div
      className={clsx(
        "z-_2 mr-4 h-32 w-8 cursor-pointer rounded-lg",
        props.currentSlide === 0 && "cursor-default",
        props.class
      )}
      onClick={props.onClick}
    >
      <Icon
        type="wc_arrowSlider"
        className="firstarrowslider mt-_52 h-6 w-6 rounded-full bg-white fill-white"
      />
    </div>
  );
}

export function SampleNextArrow(props: ArrowProps) {
  return (
    <div
      className={clsx(
        "z-_2 ml-4 h-32 w-8 cursor-pointer rounded-lg",
        props.currentSlide === (props.slideCount as number) - 1 && "hidden",
        props.class
      )}
      onClick={props.onClick}
    >
      <Icon
        type="wc_arrowSlider"
        className="mt-_52 h-6 w-6 rotate-180 rounded-full bg-white fill-white"
      />
    </div>
  );
}
