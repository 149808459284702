import { useMemo, useReducer } from "react";
import { useTranslation } from "react-i18next";

import { clsx } from "core";
import { Icon } from "components";

type Type = "sort" | "add" | "info" | "edit" | "delete" | "repartition";

type AssetState = {
  sort?: "asc" | "desc";
  repartition?: boolean;
  add?: boolean;
  info?: boolean;
  edit?: boolean;
  delete?: boolean;
};

interface Actions {
  type: Type;
  onClick: (e: any) => void;
}
export function useAssetActions(
  types: Type[],
  {
    onAdd,
    onEdit,
    onDelete,
    defaultState = {
      sort: "asc",
      repartition: false,
      add: false,
      info: false,
      edit: false,
      delete: false,
    },
  }: {
    onDelete?: () => void;
    onEdit?: () => void;
    onAdd?: () => void;
    defaultState?: AssetState;
  } = {}
) {
  const [state, dispatch] = useReducer(reducer, defaultState);

  const assetActions = useMemo(
    () =>
      types.map((type): Actions => {
        switch (type) {
          case "sort":
            return {
              type: "sort",
              onClick: () => dispatch({ type: "sort" }),
            };
          case "repartition":
            return {
              type: "repartition",
              onClick: () => dispatch({ type: "repartition" }),
            };

          case "info":
            return {
              type: "info",
              onClick: () => dispatch({ type: "info" }),
            };
          case "add":
            return {
              type: "add",
              onClick: () => {
                onAdd && onAdd();
                dispatch({ type: "add" });
              },
            };
          case "edit":
            return {
              type: "edit",
              onClick: () => {
                onEdit && onEdit();
                dispatch({ type: "edit" });
              },
            };
          case "delete":
            return {
              type: "delete",
              onClick: () => onDelete && confirm("Supprimer ?") && onDelete(),
            };
          default:
            throw new Error(`Unsupported action type: ${type}`);
        }
      }),
    [types]
  );

  const render = useMemo(
    () => <AssetActions list={assetActions} state={state} />,
    [assetActions, state]
  );

  return {
    state,
    render,
    dispatch,
  };
}

interface AssetActionsProps extends AssetState {
  list: Actions[];
  state: AssetState;
}

function AssetActions({ list, state }: AssetActionsProps) {
  return (
    <div className="flex gap-2">
      {list.map(({ type, onClick }) => (
        <div key={type}>
          {type === "edit" ? (
            <EditAction onClick={onClick} />
          ) : (
            <IconAction type={type} onClick={onClick} state={state} />
          )}
        </div>
      ))}
    </div>
  );
}

function EditAction({ onClick }: Pick<Actions, "onClick">) {
  const { t } = useTranslation();

  return (
    <div
      className="inline-flex h-9 w-[83px] cursor-pointer items-center justify-center gap-2 rounded-[64px] border border-stone-200 py-1.5 pl-4 pr-3 hover:opacity-50"
      onClick={onClick}
    >
      <div className="text-center text-sm font-medium leading-normal text-slate-900">
        {t("actions.update")}
      </div>
    </div>
  );
}

export function IconAction({
  type,
  onClick,
  state,
}: Actions & {
  state: AssetState;
}) {
  const canFocus = ["repartition"].includes(type);
  const isFocused = !!state[type];

  return (
    <div
      className={clsx(
        "inline-flex items-center justify-center gap-2 hover:opacity-50",
        "h-10 w-10 cursor-pointer rounded-[64px] border border-stone-200 p-1.5",
        ["add", "options"].includes(type) &&
          "border-asset bg-asset stroke-white",
        isFocused ? (canFocus ? "bg-primary-400" : "shadow") : undefined
      )}
      onClick={onClick}
    >
      <Icon
        type={canFocus && isFocused ? `${type}-focus` : type}
        className="relative h-5 w-5"
      />
    </div>
  );
}

type AssetAction = {
  type: Type;
  payload?: Partial<AssetState>;
};

function reducer(state: AssetState, action: AssetAction): AssetState {
  switch (action.type) {
    case "sort":
      return {
        ...state,
        sort: state.sort == "desc" ? "asc" : "desc",
      };
    case "repartition":
      return {
        ...state,
        repartition: !state.repartition,
      };
    case "add":
      return {
        ...state,
        add: !state.add,
      };
    case "info":
      return {
        ...state,
        info: !state.info,
      };
    case "edit":
      return {
        ...state,
        edit: !state.edit,
      };
    case "delete":
      return {
        ...state,
        delete: !state.delete,
      };
    default:
      throw new Error(`Unsupported action type: ${action.type}`);
  }
}
