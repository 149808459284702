import React from "react";
import type { FC } from "react";
import { useNavigate } from "react-router-dom";

import { clsx } from "core";
import { routes } from "routing";
import { Icon } from "components/Icon";
import { useUser } from "core/helpers/hooks/useUser";

type PremiumBlurProps = {
  children: React.ReactNode;
  title: string;
  active?: boolean;
};

const PremiumBlur: FC<PremiumBlurProps> = ({ children, active = true }) => {
  const { data: user } = useUser();
  const navigate = useNavigate();

  if (!user) {
    return null;
  }

  return (
    <div className="relative h-full">
      <div className={clsx("h-full", active && "opacity-0")}>{children}</div>
      {active && (
        <div>
          <>
            <div
              className="blur-sm transition-all duration-300 ease-in-out hover:opacity-70"
              style={{
                top: 0,
                position: "absolute",
                left: 0,
                right: 0,
                bottom: 0,
                borderRadius: 8,
              }}
            />
            <div className="absolute bottom-0 left-0 right-0 top-0 flex w-full items-start justify-center">
              <div className="w-full rounded-xl bg-white px-3 py-2 shadow-xl">
                <Icon
                  onClick={() =>
                    !user.isPremium &&
                    active &&
                    navigate(routes.profile.path + "#abonner-premium")
                  }
                  type={
                    user.isPremium
                      ? "premium-subscribe"
                      : "non-premium-subscribe"
                  }
                  className="block w-full cursor-pointer rounded-lg "
                />
              </div>
            </div>
          </>
        </div>
      )}
    </div>
  );
};

export default PremiumBlur;
