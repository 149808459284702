import {
  FC,
  useMemo,
  useState,
  Dispatch,
  ReactNode,
  useEffect,
  SetStateAction,
} from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import { routes } from "routing";
import { AssetType } from "@types";
import { Row } from "components/AssetRow";
import { AssetPalmares } from "./AssetPalmares";
import { getPlatformAccountById } from "./Assets.logic";
import { AssetEvolution } from "components/AssetEvolution";
import { useAssetActions } from "./AssetActions/AssetActions";
import { AssetRepartition } from "components/AssetRepartition";
import { useDeleteAsset } from "core/helpers/hooks/useDeleteAsset";
import { ManualUpdateModal, ScrollableContainer } from "components";

type PlatformAccountViewProps = {
  id: string;
  type: AssetType.crypto | AssetType.stocks;
  setFeatureComponent: Dispatch<SetStateAction<ReactNode[]>>;
};

type Row = {
  id: string;
  name: string;
  code: string;
  value: number;
  quantity: number;
  unitPrice: number;
  gain: number;
  evolution: number;
  logo: string;
};

const PlatformAccountView: FC<PlatformAccountViewProps> = ({
  id,
  type,
  setFeatureComponent,
}) => {
  const [selected, setSelected] = useState<Row>();
  const navigation = useNavigate();

  const platformAccountQuery = useQuery(
    ["platformAccount", id],
    () => getPlatformAccountById(id),
    {
      select: (data) => {
        const rows = type === AssetType.crypto ? data.cryptos : data.stocks;

        return {
          ...data,
          rows: rows.map((value) => {
            const buyingPrice = value.unitPrice * value.quantity;
            const totalValue = value.unitValue * value.quantity;
            const gain = totalValue - buyingPrice;

            return {
              id: value.id,
              name: value.name,
              code: value.code,
              value: totalValue,
              quantity: value.quantity,
              unitPrice: value.unitPrice,
              gain,
              evolution: gain / buyingPrice,
              logo: value.instrument?.logo,
            };
          }),
        };
      },
    }
  );

  const rows = platformAccountQuery.data?.rows;

  const { mutate: deleteAsset } = useDeleteAsset(() => {
    navigation(routes[type].path);
  });
  const { render, state } = useAssetActions(["repartition", "sort", "delete"], {
    onDelete: async () => deleteAsset(id),
  });
  const orderedRows = useMemo(() => {
    if (state.sort === "asc") {
      return rows?.sort((a, b) => a.value - b.value);
    } else {
      return rows?.sort((a, b) => b.value - a.value);
    }
  }, [rows, state.sort]);

  const repartition = useMemo(
    () =>
      orderedRows?.map((row) => {
        return {
          title: row.name,
          value: row.value,
        };
      }),
    [orderedRows]
  );

  useEffect(() => {
    if (rows) {
      setFeatureComponent([
        <AssetPalmares type="top" assetType={type} data={rows} />,
        <AssetPalmares type="flop" assetType={type} data={rows} />,
      ]);
    }
  }, [rows]);

  return (
    <ScrollableContainer title="title.assets" Right={render} height={100}>
      {state.repartition ? (
        <AssetRepartition type={type} dataset={repartition ?? []} />
      ) : (
        <AssetEvolution
          dataset={
            orderedRows?.map((row) => {
              let src = undefined;

              if (type === AssetType.crypto && row.code) {
                src = `https://platforms-assets.s3.fr-par.scw.cloud/instruments/${row.code}.png`;
              } else if (type === AssetType.stocks && row.logo) {
                src = `https://platforms-assets.s3.fr-par.scw.cloud/instruments/${row.logo}`;
              }

              return {
                id: row.id,
                assetType: type,
                instrument: "EUR",
                gain: row.gain,
                title: row.name,
                value: row.value,
                evolution: row.evolution,
                onEdit: () => setSelected(row),
                Icon: (
                  <div className="flex h-11 w-11 flex-shrink-0 items-center justify-center rounded-full bg-[#F6F4F3]">
                    {src ? <img src={src} className="h-5 w-5" /> : null}
                  </div>
                ),
              };
            }) ?? []
          }
        />
      )}

      {!!selected && (
        <ManualUpdateModal
          selected={selected}
          editType={"update"}
          queryType={type}
          onClose={() => setSelected(undefined)}
          handleUpdate={platformAccountQuery.refetch}
          handleDelete={console.log}
          handleActivityDelete={console.log}
        />
      )}
    </ScrollableContainer>
  );
};

export default PlatformAccountView;
