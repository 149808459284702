/// <reference types="@welldone-software/why-did-you-render" />
import React from "react";

import { config } from "config";

if (config.NODE_ENV === "development") {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const whyDidYouRender = require("@welldone-software/why-did-you-render");
  whyDidYouRender(React, {
    trackAllPureComponents: false,
    onlyLogs: true,
    titleColor: "green",
    diffNameColor: "darkturquoise",
  });
}
