import { Button, Container, Typo } from "components";
import {
  budgetInsightConnections,
  manageConnection,
} from "./BudgetInsightConnections.logic";
import type { FC } from "react";
import { useMutation, useQuery } from "react-query";
import { dateFormat } from "core";
import { ArrowUpIcon, ExclamationCircleIcon } from "@heroicons/react/outline";

export const BudgetInsightConnections: FC = () => {
  const { data } = useQuery(["budgetInsightConnections"], {
    queryFn: budgetInsightConnections,
    staleTime: 0,
  });

  const manageConnectionMutation = useMutation(() => manageConnection(), {
    onSuccess: (url) => {
      window.open(url, "_blank");
    },
  });

  return (
    <Container className="rounded-xl p-4">
      <Typo
        type="wlc_inter_l"
        children="title.connections"
        className="mb-8 text-lg tracking-tight text-primary"
      />
      <div className="flex flex-col gap-6">
        {data?.map((connection) => (
          <div className="flex flex-row items-center gap-4" key={connection.id}>
            <div className="h-16 w-16 rounded-full border">
              <img
                src={`https://monapi.biapi.pro/2.0/logos/${connection.connector.uuid}.png`}
                alt={connection.connector.name}
                className="h-full w-full object-contain p-2"
              />
            </div>
            <div className="flex grow flex-col">
              <h1 className="text-xl font-bold">{connection.connector.name}</h1>
              <h2 className="flex flex-row gap-1 text-sm font-thin">
                <ArrowUpIcon
                  width={16}
                  height={16}
                  color="#4761C8"
                  className="mx-0 text-left"
                />{" "}
                {dateFormat(connection.last_update)}
              </h2>
            </div>
            <div className="flex flex-row items-center gap-2">
              {connection.state && (
                <>
                  <ExclamationCircleIcon
                    width={32}
                    height={32}
                    color="#FF0000"
                  />
                </>
              )}
              <Button
                label="actions.update"
                disabled={manageConnectionMutation.isLoading}
                onClick={() => manageConnectionMutation.mutate()}
              />
            </div>
          </div>
        ))}
      </div>
    </Container>
  );
};
