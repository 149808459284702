import { ReactSVG } from "react-svg";

interface Props {
  type: string;
  rotate?: number;
  className?: string;
  width?: number;
  height?: number;
  onClick?: (args?: any) => void;
}
export const Icon = ({ type, rotate, className, ...props }: Props) => (
  <ReactSVG
    {...props}
    src={`/svg/${type}.svg`}
    beforeInjection={(svg) => {
      if (!svg) return;

      svg.dataset.name = type;
      if (className)
        svg.classList.add(
          ...className
            .trim()
            .split(" ")
            .filter((c) => c)
        );
    }}
    wrapper="span"
    style={{
      ...(rotate !== undefined ? { transform: `rotate(${rotate}deg)` } : {}),
      ...(CSSTransition !== undefined ? { transition: `0.5s` } : {}),
    }}
  />
);
