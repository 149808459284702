import { FieldValues } from "react-hook-form";

import { FormBuilderProps, reactString } from "@types";
import { pick } from "core/helpers/object";
import {
  createLoan,
  createStocks,
  createCrypto,
  createExotic,
  createRealEstate,
  createRetirement,
  economiesCreation,
  createCrowdfunding,
  createLifeInsurance,
  bankAccountCreationMutation,
  privateCivilRealEstateCreationMutation,
} from "core/queries";
import { exotic } from "./exotic";
import { othersForms } from "./others";
import { RoutesType } from "routing";
import { realEstate } from "./realEstate";
import { crowdfunding } from "./crowdfunding";
import { lifeInsurance } from "./lifeInsurance";
import { stockOrCrypto } from "./stockOrCrypto";
import { action, activityItems } from "./commons";

export type ManualFormFields<T = reactString> = FormBuilderProps<T> & {
  formFormatter?: (value: FieldValues, ...args: any[]) => FieldValues;
};
export type ManualForm<T = reactString> = {
  [key in RoutesType]: ManualFormFields<T>;
};
type ManualFormCRUDHandler = {
  [key in RoutesType]: {
    create: (formValues: FieldValues) => Promise<any>;
  };
};
export const manualFormCRUDHandler: Partial<ManualFormCRUDHandler> = {
  privateCivilRealEstate: {
    create: privateCivilRealEstateCreationMutation,
  },
  bankAccount: {
    create: bankAccountCreationMutation,
  },
  crypto: {
    create: createCrypto,
  },
  stocks: {
    create: createStocks,
  },
  lifeInsurance: {
    create: createLifeInsurance,
  },
  realEstate: {
    create: createRealEstate,
  },
  retirement: {
    create: createRetirement,
  },
  crowdfunding: {
    create: createCrowdfunding,
  },
  loan: {
    create: createLoan,
  },
  exotic: {
    create: createExotic,
  },
  economies: {
    create: async ({ activity, ...economies }) =>
      await economiesCreation({
        economies: pick(economies, [
          "accountName",
          "bankName",
          "type",
          "amount",
          "name",
        ]),
        activity,
      }),
  },
};

export const activityNameForm: FormBuilderProps = {
  items: [
    {
      className: "flex md:flex-row flex-col",
      children: [
        {
          label: "formLabel.name",
          name: "name",
          className: "mx-auto w-1/2",
        },
      ],
    },
  ],
};

export const activityForm: FormBuilderProps = {
  items: activityItems,
  action,
};

export const manualForm: any = {
  ...othersForms,
  exotic,
  realEstate,
  crowdfunding,
  lifeInsurance,
  crypto: stockOrCrypto,
  stocks: stockOrCrypto,
};
