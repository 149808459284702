import { VFC } from "react";
import { useTranslation } from "react-i18next";

import { RoutesType } from "routing";
import { clsx, isDate, isEmpty } from "core";
import { NormalizeTranslationKeys } from "../../i18n";
import { calcLoan, numberFormat } from "core/helpers/number";
import { AccordionActions, FooterLine, FooterLineWrapper } from "./commons";
import { imgPath, imgPathDefault, valuationStyle } from "core/helpers/style";
import {
  Loan,
  Stock,
  ITable,
  Exotic,
  Cryptos,
  AssetType,
  RealEstate,
  Crowdfunding,
  LifeInsurance,
  PrivateCivilRealEstate,
  ManualUpdateModalProps,
} from "@types";

interface TheadType {
  list: {
    name: string;
    label: string;
    className?: string;
  }[];
  action?: boolean;
}

const tableHeaderList: TheadType["list"] = [
  { name: "name", label: "Nom", className: "w-4/12" },
  { name: "cap", label: "Cours", className: "w-2/12 hidden lg:table-cell" },
  {
    name: "quantity",
    label: "Quantité",
    className: "w-2/12 hidden xs_sm:table-cell",
  },
  {
    name: "value",
    label: "Valeur",
    className: "w-2/12 hidden sm:table-cell",
  },
  { name: "percent", label: "+/- Value", className: "w-3/12" },
];

const tableHeaderLifeInsurance: TheadType["list"] = [
  { name: "name", label: "Nom", className: "w-6/12" },
  { name: "cap", label: "Cours", className: "w-2/12 hidden lg:table-cell" },
  {
    name: "quantity",
    label: "Quantité",
    className: "w-2/12 hidden xs_sm:table-cell",
  },
  {
    name: "value",
    label: "Valeur",
    className: "w-2/12 hidden sm:table-cell",
  },
];

const Thead: VFC<TheadType> = ({ list, action }) => (
  <thead className="bg-white-500">
    <tr>
      {list.map((e) => (
        <th
          key={e.name}
          scope="col"
          className={clsx(
            "p-2 text-base font-normal text-primary-300",
            e.className,
            e.name === "name" ? "text-left" : "text-right"
          )}
        >
          {e.label}
        </th>
      ))}
      {action && <th></th>}
    </tr>
  </thead>
);

interface AccordionTableProps {
  queryType: RoutesType;
  data: (Stock | Cryptos | RealEstate)[];
  handleDelete?: ITable["handleDelete"];
  handleSelection?: (editType?: ManualUpdateModalProps["editType"]) => void;
}

export const AccordionTable: VFC<AccordionTableProps> = ({
  data,
  queryType,
  handleDelete,
  handleSelection,
}) => (
  <table className="min-w-full divide-y divide-gray-300">
    <Thead list={tableHeaderList} action />
    <tbody className="divide-y divide-gray-200 bg-white">
      {data?.map(
        (
          {
            id,
            name,
            unitPrice,
            unitValue,
            quantity,
            code,
            valuation,
            instrument,
          },
          i
        ) => {
          const _value = unitValue - unitPrice;
          const value = (_value / unitPrice) * 100;
          const isLiquidity = code === "XX-liquidity";

          const v = numberFormat(
            unitPrice === 0
              ? unitValue * quantity
              : isLiquidity
              ? valuation
              : unitValue * quantity,
            { maximumFractionDigits: 0 }
          );

          return (
            <tr
              key={i}
              className={i % 2 === 0 ? "bg-white-500" : "bg-tertiary-100"}
            >
              <td className="w-3/5 overflow-hidden pl-3 sm:w-auto">
                <div className="flex flex-col items-start whitespace-nowrap py-3 text-sm font-medium text-gray-800">
                  <div className="mb-0 flex items-center gap-x-2 truncate whitespace-nowrap text-left text-sm font-semibold text-gray-800">
                    {[AssetType.crypto, AssetType.stocks].includes(
                      queryType as AssetType
                    ) && (
                      <img
                        className="crypto-icon h-_30 w-_30"
                        src={imgPath(
                          instrument?.logo || name.toLocaleLowerCase(),
                          queryType as AssetType
                        )}
                        onError={(e) => {
                          if (queryType === AssetType.crypto) {
                            (e.target as HTMLImageElement).src = imgPathDefault(
                              "default/crypto.svg",
                              queryType as AssetType
                            );
                          } else {
                            (e.target as HTMLImageElement).src = imgPathDefault(
                              "default/bourse.svg",
                              queryType as AssetType
                            );
                          }
                        }}
                      />
                    )}
                    {name}
                  </div>
                  <dl className="font-normal lg:hidden">
                    <dt className="sr-only">Cours</dt>
                    <dd className="mt-1 truncate text-gray-700">
                      {isLiquidity ? "-" : numberFormat(unitValue)}
                    </dd>
                    <dt className="sr-only xs_sm:hidden">Quantité</dt>
                    <dd className="mt-1 truncate text-gray-500 xs_sm:hidden">
                      {isLiquidity
                        ? "-"
                        : numberFormat(quantity, { showCurrency: false })}
                    </dd>
                    <dt className="sr-only sm:hidden">Valeur</dt>
                    <dd className="mt-1 truncate text-gray-500 sm:hidden">
                      {v}
                    </dd>
                  </dl>
                </div>
              </td>
              <td className="hidden overflow-hidden whitespace-nowrap px-2 text-right text-sm font-semibold text-gray-800 lg:table-cell">
                {isLiquidity
                  ? "-"
                  : numberFormat(unitValue, { maximumFractionDigits: 2 })}
              </td>
              <td className="hidden overflow-hidden whitespace-nowrap px-2 text-right text-sm font-semibold text-gray-800 xs_sm:table-cell">
                {isLiquidity
                  ? "-"
                  : numberFormat(quantity, { showCurrency: false })}
              </td>
              <td className="hidden overflow-hidden whitespace-nowrap px-2 text-right text-sm font-semibold text-gray-800 sm:table-cell">
                {v}
              </td>
              <td
                className={clsx(
                  "right-0 overflow-hidden whitespace-nowrap py-2 text-right",
                  valuationStyle(value)
                )}
              >
                {isLiquidity ? (
                  "-"
                ) : (
                  <>
                    <p className="text-sm font-semibold">
                      {numberFormat(_value * quantity)}
                    </p>
                    <p className="text-xs font-normal">{`${
                      isNaN(value) || !isFinite(value)
                        ? "N/A"
                        : `${value.toFixed(2)} %`
                    }`}</p>
                  </>
                )}
              </td>
              <td className="w-4 px-1">
                <AccordionActions
                  id={id}
                  theme="light"
                  editable={
                    ![AssetType.crypto, AssetType.stocks].includes(
                      queryType as AssetType
                    )
                  }
                  handleDelete={handleDelete}
                  handleSelection={handleSelection}
                />
              </td>
            </tr>
          );
        }
      )}
    </tbody>
  </table>
);

interface LifeInsuranceProps {
  data: LifeInsurance;
}

export const LifeInsuranceTable: VFC<LifeInsuranceProps> = ({ data }) => (
  <table className="mb-4 min-w-full divide-y divide-gray-300 border-b border-gray-300">
    <Thead list={tableHeaderLifeInsurance} action />
    <tbody className="divide-y divide-gray-200 bg-white">
      {data.asset.investments?.map(
        ({ id, label, unitValue, quantity, valuation }) => {
          return (
            <tr key={id} className="bg-white-200">
              <td className="w-3/5 overflow-hidden pl-3 sm:w-auto">
                <div className="flex flex-col items-start whitespace-nowrap py-3 text-sm font-medium text-gray-800">
                  <div className="mb-0 flex items-center gap-x-2 truncate whitespace-nowrap text-left text-sm font-semibold text-gray-800">
                    {label}
                  </div>
                  <dl className="font-normal lg:hidden">
                    <dt className="sr-only">Cours</dt>
                    <dd className="mt-1 truncate text-gray-700">
                      {numberFormat(unitValue)}
                    </dd>
                    <dt className="sr-only xs_sm:hidden">Quantité</dt>
                    <dd className="mt-1 truncate text-gray-500 xs_sm:hidden">
                      {numberFormat(quantity, { showCurrency: false })}
                    </dd>
                  </dl>
                </div>
              </td>
              <td className="hidden overflow-hidden whitespace-nowrap px-2 text-right text-sm font-semibold text-gray-800 lg:table-cell">
                {numberFormat(unitValue, { maximumFractionDigits: 0 })}
              </td>
              <td className="hidden overflow-hidden whitespace-nowrap px-2 text-right text-sm font-semibold text-gray-800 xs_sm:table-cell">
                {numberFormat(quantity, { showCurrency: false })}
              </td>
              <td
                className={clsx(
                  "right-0 overflow-hidden whitespace-nowrap py-2 text-right text-sm font-semibold text-gray-800"
                )}
              >
                {numberFormat(valuation)}
              </td>
            </tr>
          );
        }
      )}
    </tbody>
  </table>
);

interface ScpiProp extends Omit<Props, "data"> {
  data: PrivateCivilRealEstate;
}
export const ScpiFooter: VFC<ScpiProp> = ({ data }) => {
  const { t } = useTranslation();
  const showFrais = false;

  return (
    <FooterLineWrapper>
      <FooterLine
        title="type"
        value={
          t(`page.scpi.${data.type}` as NormalizeTranslationKeys) as string
        }
      />
      <FooterLine title="Nombre de parts" value={data.quantity} />
      <FooterLine title="Catégorie" value={data.scpi.type} />
      <FooterLine
        title="Prix d’achat par part"
        value={numberFormat(data.unitPrice)}
      />
      <FooterLine
        title="Taux de rendement (DVM)"
        value={`${numberFormat(data.scpi.dividend || 0, {
          showCurrency: false,
        })} %`}
      />
      <FooterLine
        title="Frais de souscription"
        value={
          !isEmpty(data.scpi.subscription_fees)
            ? `${numberFormat(data.scpi.subscription_fees, {
                showCurrency: false,
              })} %`
            : "N/A"
        }
      />
      <FooterLine
        title="Frais de gestion"
        value={
          !isEmpty(data.scpi.gestion_fees)
            ? `${numberFormat(data.scpi.gestion_fees, {
                showCurrency: false,
              })} %`
            : "N/A"
        }
      />
      {showFrais && (
        <>
          <FooterLine
            title="Frais de sortie"
            value={`${numberFormat(data.scpi.dividend || 0, {
              showCurrency: false,
            })} %`}
          />
          <FooterLine
            title="Frais de gestion"
            value={`${numberFormat(data.scpi.dividend || 0, {
              showCurrency: false,
            })} %`}
          />
        </>
      )}
    </FooterLineWrapper>
  );
};
type GenFormFormTypes = RealEstate | Exotic | Crowdfunding | LifeInsurance;
interface GenFromFormCreationProp extends Omit<Props, "data"> {
  data: GenFormFormTypes;
  queryType: AssetType;
}

type ToDisplayTypes =
  | AssetType.exotic
  | AssetType.realEstate
  | AssetType.crowdfunding
  | AssetType.lifeInsurance;
const toDisplay: Record<ToDisplayTypes, string[]> = {
  [AssetType.realEstate]: [
    "source",
    "price",
    "buyingDate",
    "typeId",
    "area",
    "rooms",
    "bedrooms",
    "condition",
    "orientation",
  ],
  [AssetType.exotic]: [
    "category",
    "unitPrice",
    "purchaseDate",
    "quantity",
    "ownership",
  ],
  [AssetType.crowdfunding]: [
    "providerName",
    "investmentField",
    "subscribeDate",
    "endDate",
    "efficiency",
  ],
  [AssetType.lifeInsurance]: [
    "insuranceCompany",
    "transfersAmount",
    "efficiency",
    "date",
  ],
};

const tranlatableValues = ["typeId", "condition", "orientation"];
const formatablePrice = ["price", "unitPrice", "transfersAmount"];
export const GenFromFormCreationFooter: VFC<GenFromFormCreationProp> = ({
  data,
  queryType,
}) => (
  <FooterLineWrapper>
    {toDisplay[queryType as ToDisplayTypes].map((key) => {
      let value = data[key as keyof GenFormFormTypes];

      if (key === "price" && queryType === AssetType.realEstate) {
        value = numberFormat(
          "" + data.asset?.activities[data.asset?.activities.length - 1].value,
          { minimumFractionDigits: 0 }
        );
      } else if (key.toLocaleLowerCase().includes("date")) {
        const date = new Date(value as string);
        value = isDate(date) ? date.toLocaleDateString() : "N/A";
      } else if (formatablePrice.includes(key))
        value = numberFormat(value as string);
      else if (key === "ownership")
        value = `${numberFormat(parseFloat(value as string) * 100, {
          showCurrency: false,
        })}  %`;

      return (
        <FooterLine
          key={key}
          title={`formLabel.${key}`}
          value={`${
            tranlatableValues.includes(key) ? `formLabel.${value}` : value
          }`}
        />
      );
    })}
  </FooterLineWrapper>
);

interface LoanProp extends Omit<Props, "data"> {
  data: Loan;
}
export const LoanFooter: VFC<LoanProp> = ({ data }) => {
  const {
    total,
    capital,
    duration,
    remaining,
    totalInterst,
    totalReFundeded,
    remainingPercent,
  } = calcLoan(data);

  return (
    <FooterLineWrapper>
      <FooterLine title="Coût total de l'emprunt" value={numberFormat(total)} />
      <FooterLine title="Capital" value={numberFormat(capital)} />
      <FooterLine title="Durée (en mois)" value={duration} />
      <FooterLine
        title="Intérêts et Assurance"
        value={numberFormat(totalInterst)}
      />
      <FooterLine
        title="Frais de dossier"
        value={numberFormat(data.applicationFee)}
      />
      <FooterLine
        title="Total remboursé"
        value={numberFormat(totalReFundeded)}
      />
      <FooterLine title="Reste à rembourser" value={numberFormat(remaining)} />
      <FooterLine title="Reste à rembourser (%)" value={remainingPercent} />
    </FooterLineWrapper>
  );
};

interface Props {
  data: (Stock | Cryptos)[] | GenFormFormTypes | PrivateCivilRealEstate | Loan;
  handleDelete?: ITable["handleDelete"];
  handleSelection?: (editType?: ManualUpdateModalProps["editType"]) => void;
}
export const AccordionFooter: VFC<Props & { queryType: AssetType }> = ({
  queryType,
  data,
  ...props
}) =>
  queryType === AssetType.loan ? (
    <LoanFooter data={data as unknown as Loan} {...props} />
  ) : queryType === AssetType.privateCivilRealEstate ? (
    <ScpiFooter data={data as unknown as PrivateCivilRealEstate} {...props} />
  ) : queryType === AssetType.lifeInsurance ? (
    <>
      <LifeInsuranceTable {...props} data={data as unknown as LifeInsurance} />
      <GenFromFormCreationFooter
        data={data as GenFormFormTypes}
        queryType={queryType}
        {...props}
      />
    </>
  ) : [AssetType.exotic, AssetType.realEstate, AssetType.crowdfunding].includes(
      queryType as AssetType
    ) ? (
    <GenFromFormCreationFooter
      data={data as GenFormFormTypes}
      {...props}
      queryType={queryType}
    />
  ) : (
    <AccordionTable
      {...props}
      data={data as unknown as (Stock | Cryptos | RealEstate)[]}
      queryType={queryType}
    />
  );
