import { VFC } from "react";
import { useTranslation } from "react-i18next";

import { clsx, isStr } from "core";
import { CheckboxTypes } from "@types";
import { useFormBuilder } from "context";
import { NormalizeTranslationKeys } from "i18n";
import { CheckBoxRadioListWrapper } from "./FormLabel";

const CheckboxRender = ({
  id,
  label,
  subLine,
  name,
  register,
  required,
  className,
  value,
  onChange,
  ...props
}: CheckboxTypes) => {
  const { t } = useTranslation();
  const _formBuilderCtx = useFormBuilder();

  return (
    <div
      className={clsx(
        "relative flex items-start",
        _formBuilderCtx.classNames?.checkbox?.wrapper,
        className
      )}
    >
      <div
        className={clsx(
          "flex h-5 items-center",
          _formBuilderCtx.classNames?.checkbox?.checkboxContainer
        )}
      >
        <input
          type="checkbox"
          id={id || name}
          value={value}
          {...props}
          {...(register
            ? register(name)
            : { name, required: !!required, onChange })}
          className={clsx(
            "h-4 w-4 cursor-pointer rounded border-primary-200 text-primary focus:ring-primary",
            _formBuilderCtx.classNames?.checkbox?.checkbox
          )}
        />
      </div>
      <div
        className={clsx(
          "ml-3 text-sm",
          _formBuilderCtx.classNames?.checkbox?.labelContainer
        )}
      >
        {label ? (
          <label
            htmlFor={id || name}
            className={clsx(
              "cursor-pointer text-base font-medium text-gray-900",
              _formBuilderCtx.classNames?.checkbox?.label
            )}
          >
            {isStr(label) ? t(label as NormalizeTranslationKeys) : label}
          </label>
        ) : (
          ""
        )}
        {subLine ? (
          <label
            htmlFor={name}
            className={clsx(
              "cursor-pointer text-sm font-normal text-gray-600",
              _formBuilderCtx.classNames?.checkbox?.label
            )}
          >
            {t(subLine as NormalizeTranslationKeys)}
          </label>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export const Checkbox: VFC<CheckboxTypes> = (props) => {
  if (props.options)
    return (
      <CheckBoxRadioListWrapper {...props}>
        {props.options.map((option, index) => (
          <CheckboxRender
            key={index}
            {...props}
            {...option}
            name={props.name ? `${props.name}` : `${option.name}`}
            id={`${props.name}-${option.label}`}
          />
        ))}
      </CheckBoxRadioListWrapper>
    );

  return <CheckboxRender {...props} />;
};
