import Slider from "react-slick";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "_redux";
import { Link } from "react-router-dom";
import { AssetType, Wealth } from "@types";
import { Loader } from "components/Loader";
import { NormalizeTranslationKeys } from "i18n";
import { PerioActivityChart } from "components/charts";
import { generateNumberArray } from "core/helpers/array";
import EvolutionPercent from "components/EvolutionPercent";
import FavoriteAssetsModule from "./FavoriteAssetsModule";
import { NoDataPremiumActions } from "components/NoDataSync";
import FavoriteAccountsModule from "./FavoriteAccountsModule";
import { SampleNextArrow, SamplePrevArrow } from "components/slider";
import { PremiumPlaceHolderList } from "components/premiumPlaceholder";
import { home, numberFormat, addOrSubtractDay, getAssetTypeColor } from "core";
import {
  Icon,
  Error,
  Period,
  AssetIcon,
  Container,
  PeriodControl,
} from "components";

export function HomeScreen() {
  const period = useAppSelector((state) => state.period.value);

  const queryPeriodArgs =
    period == Period.all
      ? { start: null, end: null }
      : { start: addOrSubtractDay(period ? -period : 0), end: new Date() };

  const { data, isLoading, error } = useQuery(["home", period], () =>
    home({ ...queryPeriodArgs })
  );

  if (isLoading) return <HomeSkeleton />;

  if (error) return <Error err={error} />;

  // Remove duplicate assets
  const uniqueWealthQuery =
    data?.wealth.reduce((acc, asset) => {
      const existingAsset = acc.find((a) => a.type === asset.type);
      if (existingAsset) {
        return acc;
      }
      acc.push(asset);
      return acc;
    }, [] as Wealth[]) || [];

  return (
    <div className="h-full w-full">
      {/* Slider */}
      <Slider
        infinite={false}
        nextArrow={<SampleNextArrow />}
        prevArrow={<SamplePrevArrow />}
        dots={false}
        slidesToShow={1}
        slidesToScroll={1}
        variableWidth={true}
        className="flex flex-row"
      >
        {uniqueWealthQuery.map((asset) => (
          <Link key={asset.type} to={`/${asset.type}`}>
            <AssetCard asset={asset} />
          </Link>
        ))}
      </Slider>

      <div className="mt-4 flex w-full flex-col gap-x-8 sm_md:flex-row">
        <div className="flex w-full flex-col justify-between gap-[26px] sm_md:w-2/5">
          <FavoriteAssetsModule />
          <FavoriteAccountsModule />
          <NoDataPremiumActions />
        </div>

        {/* Chart */}
        <div className="flex w-full flex-col justify-between gap-[26px] sm_md:w-3/5">
          <div>
            <PerioActivityChart
              dataset={data?.periodActivities?.map((e) => ({
                date: e.start,
                amount: {
                  value: e.value,
                  instrument: "EUR",
                },
              }))}
              hasDecimal={false}
              assetType={AssetType.bankAccount}
            />
            {period != undefined && <PeriodControl />}
          </div>
          <PremiumPlaceHolderList list={[]} />
        </div>
      </div>
    </div>
  );
}

interface AssetCardProps {
  asset: Wealth;
}
function AssetCard({ asset }: AssetCardProps) {
  const { t } = useTranslation();

  return (
    <Container className="flex w-[243px] max-w-lg flex-col justify-between rounded-[10px] bg-white px-3 py-[10px] drop-shadow-md _700:justify-start">
      {/* Header */}
      <div className="relative flex items-center gap-[10px]">
        <div className="absolute -right-1 top-1 flex h-4 w-4 transform items-center justify-center">
          <Icon type="arrow" />
        </div>
        <AssetIcon asset={asset.type} size="medium" background="asset" isHome />
        <div
          className="w-[84px] text-base font-bold leading-tight"
          style={{
            color: getAssetTypeColor(asset.type).color,
          }}
        >
          {t(`title.${asset.type}` as NormalizeTranslationKeys)}
        </div>
      </div>

      {/* Content */}
      <div className="pt-3 text-[22px] font-bold leading-10 text-slate-600">
        {numberFormat(asset.activity.value, { maximumFractionDigits: 0 })}
      </div>

      {/* Footer */}
      <div className="flex h-10 items-center gap-1">
        <span className="text-xs font-bold leading-none text-slate-600">
          {numberFormat(asset.performance?.gain?.value ?? 0, {
            maximumFractionDigits: 2,
          })}
        </span>
        <EvolutionPercent
          evolution={asset.performance?.evolution ?? 0}
          size="large"
        />
      </div>
    </Container>
  );
}

function HomeSkeleton() {
  return (
    <Loader>
      {generateNumberArray(8).map((i) => (
        <rect
          key={i}
          x={i * (240 + 20)}
          y="20"
          rx="10"
          ry="10"
          width="240"
          height="150"
        />
      ))}
      <rect
        x="0"
        y="200"
        rx="10"
        ry="10"
        width="calc(45% - 15px)"
        height="100%"
      />
      <rect
        x="calc(45% + 15px)"
        y="200"
        rx="10"
        ry="10"
        width="calc(55% - 15px)"
        height="100%"
      />
    </Loader>
  );
}
