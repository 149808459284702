import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export const inputUploadMax = 100;
export const inputUploadAccecpt = {
  label: `JPG, PNG, WEBP, GIF, TIF, MP4 max ${inputUploadMax}mb`,
  accept: [
    "image/jpeg",
    "image/png",
    "image/webp",
    "image/gif",
    "image/tif",
    "video/mp4",
  ],
};

// Drop Intro
const assetsManualAddSchema = yup.object({
  bank: yup.string().required(),
  name: yup.string().required(),
  accountType: yup.string().required(),
  balance: yup.number().default(0),
});

export const dropIntroFormValidator = {
  resolver: yupResolver(assetsManualAddSchema),
};
