import { WithGqlQuery } from "wrapper";
import { budgetInsightUrl } from "core/queries";

export function BudgetInsight() {
  return (
    <WithGqlQuery queryKey="BiUrl" queryFn={budgetInsightUrl}>
      {({ data }) => (
        <iframe
          id="Budget Insight"
          title="Budget Insight"
          width="600"
          height="800"
          src={data?.budgetInsightWebview}
        />
      )}
    </WithGqlQuery>
  );
}
