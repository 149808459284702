import { useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";

import { clsx, isFn } from "core";
import { Checkbox } from "components/form";
import { Input } from "components/form/input";
import { Radio } from "components/form/Radio";
import { Select } from "components/form/select";
import { NormalizeTranslationKeys } from "i18n";
import { useFormBuilder } from "context/formBuilderCtx";
import {
  RadioTypes,
  CheckboxTypes,
  InputPropsTypes,
  FormSelectProps,
  FormBuilderProps,
  GeneriqueTypeProps,
} from "@types";

const GeneriqueType = ({
  defaultRequired,
  formValues,
  control,
  ...args
}: GeneriqueTypeProps) => {
  args.required = defaultRequired || args.required;
  const { type, ...props } = args;

  switch (type) {
    case "radio":
      return <Radio {...(props as RadioTypes)} />; // T-485 add select types
    case "checkbox":
      return <Checkbox {...(props as CheckboxTypes)} />; // T-487 add select types
    case "select":
    case "react-select":
    case "react-select-async":
      return (
        <Select
          {...(args as FormSelectProps<NormalizeTranslationKeys>)}
          control={control}
        />
      ); // T-917 add select types
    case "custom":
      const Element = args.element;

      return (
        <Element
          formValues={formValues}
          control={control}
          setValue={args.setValue}
          register={args.register}
        />
      );
    default:
      return <Input {...(args as InputPropsTypes)} />;
  }
};

export const FormBuilder = ({
  items,
  props,
  children,
  mode,
  variant = "standard",
  errorType = "message",
  clasName = "",
  setValues,
  defaultRequired = false,
  action: Action,
  defaultValues = {},
  onSubmit,
  onChange,
}: FormBuilderProps) => {
  const [formValues, setFormValues] = useState<FieldValues>({});
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    reset,
    formState: { errors },
  } = useForm({ defaultValues, mode });
  const _formBuilderCtx = useFormBuilder();

  useEffect(() => {
    const subscription = watch((values) => {
      onChange && onChange(values);
      setValues && setValues({ values, setValue });
      setFormValues(values);
    });

    return () => subscription.unsubscribe();
  }, [watch, onChange]);

  const submitFn = onSubmit ? handleSubmit(onSubmit) : undefined;
  const submitProps = { submit: submitFn, reset, formValues };

  return (
    <form
      onSubmit={submitFn}
      className={clsx(
        "flex flex-col gap-y-4",
        _formBuilderCtx?.classNames?.form?.className,
        clasName
      )}
    >
      {items?.map(({ children, className, conditionalRender }, index) => {
        if (conditionalRender?.(formValues) === false) return null;

        const filteredChildren = children.filter((arg) => {
          const { conditionalRender } = isFn(arg) ? arg({ props }) : arg;
          return !conditionalRender || conditionalRender(formValues);
        });

        if (filteredChildren.length === 0) return null;

        return (
          <div
            key={index}
            className={clsx("grid grid-cols-1 gap-4 py-2", className)}
          >
            {filteredChildren?.map((args, i) => {
              const {
                className = clsx("flex flex-col w-full"),
                calcValue,
                ...rest
              } = isFn(args) ? args({ props }) : args;
              if (calcValue) {
                const value = calcValue(formValues);
                if (value !== formValues[rest.name]) setValue(rest.name, value);
              }

              return (
                <div
                  key={i}
                  className={clsx(
                    "content-myinput-grid",
                    rest.type === "radio" && "radio-input",
                    className,
                    rest.type === "hidden" && "hidden"
                  )}
                >
                  <GeneriqueType
                    variant={variant}
                    setValue={setValue}
                    register={register}
                    control={control}
                    errorType={errorType}
                    defaultRequired={defaultRequired}
                    error={errors[rest.name]?.message}
                    defaultValue={defaultValues[rest.name] as string}
                    formValues={formValues}
                    {...rest}
                    className="md_lg:w-full"
                  />
                </div>
              );
            })}
          </div>
        );
      })}
      {Action ? (
        <div
          className={clsx(
            "section-action-button",
            _formBuilderCtx?.classNames?.action?.className
          )}
        >
          <Action {...submitProps} />
        </div>
      ) : (
        children?.(submitProps)
      )}
    </form>
  );
};

/* 
  TODO: add error display if errorMessage:true


  const items = [
    {
      clasName: "flex gap-2",
      children: [
        { type: name: 'test', "input", defaultValue: "one" labelClassName:'', conditionalRender: ({formValue})=> formValue.gender === 'male'},
        { type: name:'gender' "select", options: [{ label: "Male", value: "male" }] },
      ],
    },
  ];
  
  <FormBuilder
    variant='feildset'
    defaultValues={defaultValues}
    register={register}
    items={items}
    conditionalRender={{
      test: {
        keep: true,
        condition: ({formValues}) => formValues.test === 'one',
      }
    }}
    className="btn-white"
    Submit={({onClick, reset})=> <div><button onClick={onClick}>Submit</button></div>}
  />
*/
