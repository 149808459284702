import { UserInterface } from "@types";
import { isObj } from "core/helpers/check";
import { tracker, Tracking } from "core/helpers/tracking";
import SecureStorageService from "./SecureStorage";

export class AuthService {
  static rememberMe = false;
  /**
   * Get token
   *
   * @returns {string}
   */
  static getToken() {
    return SecureStorageService.getItem("token");
  }

  /**
   * get current user
   *
   * @returns {{id: number, uuid: string, email: string, name: string}} | null
   */
  static getUser(): UserInterface | null {
    const _user = SecureStorageService.getItem("user");
    if (!isObj(_user)) return null;

    return _user as UserInterface;
  }

  static setToken(token: string, isSesssionStorage = false) {
    SecureStorageService.setItem("token", token, isSesssionStorage);
  }

  static setUser(auth: object, isSesssionStorage = false) {
    SecureStorageService.setItem(
      "user",
      JSON.stringify(auth),
      isSesssionStorage
    );
  }

  static updateUser(
    newUserData: Partial<UserInterface>,
    isSesssionStorage = true
  ): UserInterface | null {
    const user = AuthService.getUser();

    if (!user) return null;

    const newUser = { ...user, ...newUserData };
    AuthService.setUser(newUser, isSesssionStorage);
    return newUser;
  }

  /**
   * Delete token
   *
   * @returns {void}
   */
  static removeToken() {
    localStorage.removeItem("token");
    sessionStorage.removeItem("token");
  }

  /**
   * Supprime l'utilisateur connecté
   *
   * @returns {void}
   */
  static removeUser() {
    localStorage.removeItem("user");
    sessionStorage.removeItem("user");
  }

  /**
   * Check if user is authenticated
   *
   * @returns {boolean}
   */
  static isAuth() {
    return null != AuthService.getToken();
  }

  /**
   * Clean all auth data
   */
  static localLogout() {
    AuthService.removeToken();
    AuthService.removeUser();
    tracker.log(Tracking.Event.LOGOUT);

    window.location.reload();
  }
}
