import { AssetType } from "@types";
import { Typo, Container, Icon } from "components";
import { getAssetTypeColor } from "core";

interface ActionPanelProps {
  to?: string;
  icon: string;
  heading: string;
  assetType: AssetType;
  description?: string;
  onClick?: () => void;
}
export const ActionPanel = ({
  heading,
  description,
  onClick,
  icon,
  assetType,
}: ActionPanelProps) => (
  <Container
    className="relative mb-0 flex h-full cursor-pointer flex-col justify-between p-5 hover:bg-gray-100"
    onClick={onClick}
  >
    <div>
      <Icon type={icon} className="h-10 w-10 xs_sm:h-14 xs_sm:w-14" />
      <div>
        <Typo
          type="wlc_inter_l"
          className="mt-5 mb-[6px] text-center text-base font-bold leading-tight text-violet-600 "
          style={{ color: getAssetTypeColor(assetType).color }}
        >
          {heading}
        </Typo>
        {!!description && (
          <Typo
            type="wlc_inter_s"
            className="m-auto w-2/3 text-center text-_10 font-bold leading-none text-slate-900"
          >
            {description}
          </Typo>
        )}
      </div>
    </div>
  </Container>
);
