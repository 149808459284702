import { useTranslation } from "react-i18next";

import { clsx } from "core";
import { AssetType, Wealth } from "@types";
import { Row } from "components/AssetRow";
import { Icon } from "components";
import { NormalizeTranslationKeys } from "i18n";
import { useFavoriteAssets, useWealth } from "core/helpers/hooks";
import { useAppSelector } from "_redux";

export function FavoriteAssets() {
  const { t } = useTranslation();
  const period = useAppSelector((state) => state.period.value);
  const wealthQuery = useWealth(period);
  const { favoriteAssets, updateFavorites } = useFavoriteAssets();

  const onUpdate = (favorites: AssetType[]) => {
    const hasPendingChanges =
      favorites.length !== favoriteAssets?.length ||
      favoriteAssets.find((asset) => !favorites.includes(asset.type));
    if (hasPendingChanges) {
      updateFavorites(favorites);
    }
  };

  // Remove duplicate assets
  const uniqueWealthQuery =
    wealthQuery?.data?.reduce((acc, asset) => {
      const existingAsset = acc.find((a) => a.type === asset.type);
      if (existingAsset) {
        return acc;
      }
      acc.push(asset);
      return acc;
    }, [] as Wealth[]) || [];

  return (
    <div>
      {uniqueWealthQuery.map((asset) => {
        const favoritesAssets =
          favoriteAssets?.map((asset) => asset.type) || [];
        const isFavorite = favoritesAssets.includes(asset.type);

        return (
          <Row
            key={asset.type}
            type="action"
            title={
              t(`title.${asset.type}` as NormalizeTranslationKeys) as string
            }
            assetType={asset.type}
            value={asset.activity.value || 0}
            Right={
              <div
                onClick={() => {
                  if (isFavorite) {
                    onUpdate(
                      favoritesAssets.filter(
                        (favorite) => favorite !== asset.type
                      )
                    );
                  } else {
                    onUpdate([...favoritesAssets, asset.type]);
                  }
                }}
              >
                <Icon
                  type="pin"
                  className={clsx(
                    "mr-4 h-7 w-7 cursor-pointer",
                    isFavorite ? "fill-asset stroke-asset" : "stroke-[#161B33]"
                  )}
                />
              </div>
            }
          />
        );
      })}
    </div>
  );
}
