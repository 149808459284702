import i18n from "i18next";
import Backend from "i18next-http-backend";
import detector from "i18next-browser-languagedetector";
import { Normalize, initReactI18next } from "react-i18next";

import fr from "./locales/fr/translation.json";
import en from "./locales/en/translation.json";
import de from "./locales/de/translation.json";
import es from "./locales/es/translation.json";

export type TranslationKeys = typeof fr;
export type NormalizeTranslationKeys = Normalize<TranslationKeys>;

const languages = ["fr", "en", "de", "es"];
const initialLanguage = localStorage.getItem("i18nextLng") || "fr";
i18n
  .use(Backend)
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: initialLanguage,
    // fallbackLng: "fr",
    defaultNS: "translation",

    supportedLngs: languages,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    resources: {
      fr: {
        translation: fr,
      },
      en: {
        translation: en,
      },
      de: {
        translation: de,
      },
      es: {
        translation: es,
      },
    },
  });

export default i18n;

declare module "react-i18next" {
  // and extend them!
  interface Resources {
    translation: TranslationKeys;
  }
}
