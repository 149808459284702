import { useState, VFC } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "_redux";
import { Loader } from "components/Loader";
import { useAssets } from "core/helpers/hooks";
import { NormalizeTranslationKeys } from "i18n";
import { DataType } from "components/charts/brush";
import { Activity, Asset, AssetType } from "@types";
import { useGQLQuery } from "core/queries/useQuery";
import { PerioActivityChart } from "components/charts";
import { privateRoute, routes, RoutesType } from "routing";
import { periodActivitiesUrl } from "core";
import { AssetRepartition } from "components/AssetRepartition";
import CostAnalysis from "components/PremiumModules/CostAnalysis";
import LoanCapacity from "components/PremiumModules/LoanCapacity";
import { useDeleteAsset } from "core/helpers/hooks/useDeleteAsset";
import { PremiumPlaceHolderList } from "components/premiumPlaceholder";
import { AssetEvolution } from "components/AssetEvolution/AssetEvolution";
import RetirementSimulation from "components/PremiumModules/RetirementSimulation";
import { useAssetActions } from "screens/private/assetById/components/assets/AssetActions/AssetActions";
import {
  Error,
  ManualUpdateModal,
  Period,
  PeriodControl,
  ScrollableContainer,
} from "components";
import { getBankAccounts } from "screens/private/assetById/components/assets/Assets.logic";
import { budgetInsightConnections } from "screens/private//profile/components/BudgetInsightConnections/BudgetInsightConnections.logic";
import { rangeForPeriod } from "core/helpers/convinients";

const premiumModules = {
  [AssetType.bankAccount]: [CostAnalysis],
  [AssetType.loan]: [LoanCapacity],
  [AssetType.retirements]: [RetirementSimulation],
};
interface Props {
  type: RoutesType;
  hideCurrenciesTracker?: boolean;
}

type AssetData = (Asset & {
  currentActivity: Activity;
})[];

export const WithWallets: VFC<Props> = ({ type }) => {
  // States
  const [selected, setSelected] = useState<AssetData | null>(null);

  // Hooks
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { render, state } = useAssetActions(["sort"]);
  const period = useAppSelector((state) => state.period.value);

  const _type = routes[type];

  const assetQuery = useAssets(type as AssetType);

  const bankAccountsQuery = useQuery(
    ["bankAccounts"],
    () => getBankAccounts(),
    {
      enabled: type === AssetType.bankAccount,
    }
  );

  const budgetInsightConnectionsQuery = useQuery(["budgetInsightConnections"], {
    queryFn: budgetInsightConnections,
    staleTime: 0,
  });

  const isAll = period == Period.all;

  const rangePeriod = rangeForPeriod(period);

  const { data: periodData = {} } = useGQLQuery(
    ["sumary", type, period],
    periodActivitiesUrl({
      type: _type.type,
      ...(isAll
        ? { start: null, end: null }
        : { start: rangePeriod.start, end: rangePeriod.end }),
    })
  );
  const { mutate: deleteAsset } = useDeleteAsset(() => {
    queryClient.invalidateQueries([type, period]);
    queryClient.invalidateQueries(["sumary", type, period]);
  });

  // Data format
  const orderedRows = (assetQuery.data ?? [])?.sort((a, b) => {
    if (state.sort === "asc") {
      return b.currentActivity?.value - a.currentActivity?.value;
    } else {
      return a.currentActivity?.value - b.currentActivity?.value;
    }
  });

  const onDelete = (id: string, title: string) => {
    if (
      !window.confirm(
        t("wordings.delete_element" as NormalizeTranslationKeys, {
          title,
        }) as string
      )
    ) {
      return;
    }

    deleteAsset(id);
  };

  const activePremiumModules: React.FC[] | null =
    (premiumModules as any)[type] || null;

  if (assetQuery.isLoading) return <WalletSkeleton />;
  if (assetQuery.error) return <Error err={assetQuery.error} />;

  return (
    <>
      <div className="h-full w-full">
        <div className="flex h-full w-full flex-col gap-x-9 sm_md:flex-row">
          <div className="flex h-full w-full flex-col gap-y-6 sm_md:w-1/3">
            <ScrollableContainer title="title.wallet" Right={render}>
              <AssetEvolution
                dataset={orderedRows.map((e) => ({
                  id: e.id,
                  assetType: _type.type,
                  title: e.name,
                  gain: e.performance?.gain?.value || 0,
                  evolution: e.performance?.evolution || 0,
                  value: e.currentActivity?.value || 0,
                  icon: bankAccountsQuery.data?.find(
                    (el) => el.asset.id === e.id
                  )?.bankUUID
                    ? `https://monapi.biapi.pro/2.0/logos/${
                        bankAccountsQuery.data?.find(
                          (el) => el.asset.id === e.id
                        )?.bankUUID
                      }-thumbnail.webp`
                    : undefined,
                  isConnected: !budgetInsightConnectionsQuery.data?.find(
                    (element) =>
                      element.connector.uuid ===
                        bankAccountsQuery.data?.find(
                          (el) => el.asset.id === e.id
                        )?.bankUUID && element.state
                  ),
                  isUnderManagement: e.isUnderManagement,
                  onDelete: () => onDelete(e.id, e.name),
                  onClick: () =>
                    navigate(privateRoute.assetDetails(_type.type, e.id)),
                  onEdit: () => setSelected(e as unknown as AssetData),
                }))}
              />
            </ScrollableContainer>
            <ScrollableContainer title="title.repartition">
              <AssetRepartition
                type={_type.type}
                dataset={
                  orderedRows.map((e) => ({
                    title: e.name,
                    value: e.currentActivity?.value || 0,
                    isUnderManagement: e.isUnderManagement,
                    icon: bankAccountsQuery.data?.find(
                      (el) => el.asset.id === e.id
                    )?.bankUUID
                      ? `https://monapi.biapi.pro/2.0/logos/${
                          bankAccountsQuery.data?.find(
                            (el) => el.asset.id === e.id
                          )?.bankUUID
                        }-thumbnail.webp`
                      : undefined,
                    onClick: () =>
                      navigate(privateRoute.assetDetails(_type.type, e.id)),
                  })) || []
                }
              />
            </ScrollableContainer>
          </div>
          <div className="flex w-full flex-col justify-between gap-y-6 sm_md:w-2/3">
            <div className="mt-6 flex w-full flex-col gap-x-8 sm_md:flex-row">
              <div className="flex w-full flex-col justify-between gap-y-14">
                <PerioActivityChart
                  dataset={periodData?.periodActivities?.map((e: DataType) => ({
                    date: e.start,
                    amount: {
                      value: e.value,
                    },
                  }))}
                  hasDecimal={false}
                  assetType={type as AssetType}
                />
                {period != undefined && <PeriodControl />}
              </div>
            </div>
            {activePremiumModules ? (
              <div className="mt-4 flex-1">
                {activePremiumModules.map((Module, index) => (
                  <Module key={index} />
                ))}
              </div>
            ) : (
              <PremiumPlaceHolderList list={[]} />
            )}
          </div>
        </div>
      </div>
      {!!selected && (
        <ManualUpdateModal
          selected={selected}
          editType={"update"}
          queryType={_type.type}
          onClose={() => setSelected(null)}
          handleUpdate={assetQuery.refetch}
          handleDelete={console.log}
          handleActivityDelete={console.log}
        />
      )}
    </>
  );
};

function WalletSkeleton() {
  return (
    <Loader>
      <rect x="0" y="20" rx="10" ry="10" width="354px" height="100%" />
      <rect
        x="400"
        y="20"
        rx="10"
        ry="10"
        width="calc(100% - 370px)"
        height="100%"
      />
    </Loader>
  );
}
