export const privateRoute = {
  home: "/",
  crypto: "/crypto",
  crowdfunding: "/crowdfunding",
  incomeTaxes: "/incomeTaxes",
  lifeInsurance: "/lifeInsurance",
  other: "/other",
  pension: "/pension",
  realEstate: "/realEstate",
  stocks: "/stocks",
  bankAccount: "/bankAccount",
  addWallet: "/addWallet",
  heritageObjectives: "/heritage-objectives",
  singleRealEstate: (estate = ":estate") => `/realEstateDetails/${estate}`,
  singleSCPI: (estate = ":estate") => `/scpi/${estate}`,
  assetDetails: (type = ":type", id = ":id") => `/${type}/${id}`,
};
