import { separete } from "core/helpers/object";
import { ManualFormFields } from "./manual";
import { action, options } from "./commons";
import { NormalizeTranslationKeys } from "i18n";

export const exotic: ManualFormFields<NormalizeTranslationKeys> = {
  defaultRequired: true,
  items: [
    {
      className: "grid md:grid-cols-3 grid-cols-1",
      children: [
        {
          name: "name",
          label: "forms.exotic.name.label",
          placeholder: "forms.exotic.name.placeholder",
        },
        {
          name: "category",
          label: "forms.exotic.category.label",
          placeholder: "forms.exotic.category.placeholder",
        },
        {
          name: "unitPrice",
          label: "forms.exotic.unitPrice.label",
          placeholder: "forms.exotic.unitPrice.placeholder",
          type: "number",
        },
        {
          name: "value",
          label: "forms.exotic.value.label",
          placeholder: "forms.exotic.value.placeholder",
          type: "number",
        },
        {
          name: "purchaseDate",
          type: "date",
          label: "forms.exotic.purchaseDate.label",
          placeholder: "forms.exotic.purchaseDate.placeholder",
        },
        {
          name: "quantity",
          label: "forms.exotic.quantity.label",
          placeholder: "forms.exotic.quantity.placeholder",
          type: "number",
        },
        {
          name: "ownership",
          label: "forms.exotic.ownership.label",
          placeholder: "forms.exotic.ownership.placeholder",
          type: "number",
        },
        {
          name: "instrument",
          type: "select",
          label: "forms.exotic.instrument.label",
          options,
        },
      ],
    },
  ],
  action,
  formFormatter: (formValue) => {
    const [activity, input] = separete(formValue, [
      "value",
      "instrument",
      "date",
    ]);

    return {
      input: { ...input, ownership: input.ownership / 100 },
      activity: { ...activity, date: new Date() },
    };
  },
};
