import { gql } from "graphql-request";
import { client } from "core/queries/makeGQLQuery";
import { useMutation, useQuery, useQueryClient } from "react-query";

async function getFavoriteAccounts() {
  const query = gql`
    query FavoriteAssetsBankAccount {
      favoriteAssets {
        id
        name
        currentActivity {
          value
          instrument
        }
        transactions {
          id
          name
          date
          amount
        }
      }
    }
  `;

  const response = await client.request(query);
  return response.favoriteAssets as {
    id: string;
    name: string;
    currentActivity: {
      value: number;
      instrument: string;
    };
    transactions: {
      id: string;
      name: string;
      date: string;
      amount: number;
    }[];
  }[];
}

async function updateFavoriteAssets(assetIds: string[]) {
  const mutation = gql`
    mutation UpdateFavoriteAssets($assetIds: [String!]!) {
      updateFavoriteAssets(input: { assetIds: $assetIds }) {
        id
      }
    }
  `;

  const response = await client.request(mutation, { assetIds });
  return response.updateFavoriteAssets as {
    id: string;
  };
}

export function useFavoriteAccounts() {
  const queryClient = useQueryClient();

  const { data } = useQuery("favoriteAccounts", getFavoriteAccounts);

  const { mutate: addFavorite, isLoading } = useMutation<any, unknown, string>(
    (assetId) => {
      const currentFavorites = data?.map((a) => a.id) ?? [];
      return updateFavoriteAssets([...currentFavorites, assetId]);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("favoriteAccounts");
        queryClient.invalidateQueries("wealth");
      },
    }
  );

  const removeFavoriteMutation = useMutation<any, unknown, string>(
    (assetId) => {
      const currentFavorites = data?.map((a) => a.id) ?? [];
      return updateFavoriteAssets(
        currentFavorites.filter((a) => a !== assetId)
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("favoriteAccounts");
        queryClient.invalidateQueries("wealth");
      },
    }
  );

  const updateFavoritesMutation = useMutation(updateFavoriteAssets, {
    onSuccess: () => {
      queryClient.invalidateQueries("favoriteAccounts");
      queryClient.invalidateQueries("wealth");
    },
  });

  return {
    addFavorite,
    removeFavorite: removeFavoriteMutation.mutate,
    updateFavorites: updateFavoritesMutation.mutate,
    favoriteAccounts: data,
    isLoading:
      isLoading ||
      removeFavoriteMutation.isLoading ||
      updateFavoritesMutation.isLoading,
    isFavorite: (assetId: string) => {
      return !!data?.some((a) => a.id === assetId);
    },
  };
}
