import { useEffect, useState } from "react";
import { useController } from "react-hook-form";
import CreatableSelect from "react-select/creatable";

import { FormSelectProps, Option } from "@types";
import { FormLabel } from "components/form/FormLabel";
import { useTranslation } from "react-i18next";
import { NormalizeTranslationKeys } from "i18n";

export const CreatableReactSelect = ({
  name,
  label,
  control,
  required,
  defaultValue,
  className = "w-full",
  onCreate,
  loadOptions,
  placeholder,
}: FormSelectProps<string>) => {
  const [options, setOptions] = useState<Option[]>([]);
  const { t } = useTranslation();

  const { field } = useController({
    name,
    control,
    defaultValue,
    rules: { required },
  });

  const handleCreate = async (value: string) => {
    if (!onCreate) return;
    const newOption = await onCreate(value);
    setOptions([...options, newOption]);
  };

  useEffect(() => {
    if (!loadOptions) return;
    const effect = async () => {
      try {
        const options = await loadOptions();
        setOptions(options);
      } catch (error) {}
    };
    effect();
  }, [loadOptions]);

  return (
    <>
      {!!label && <FormLabel name={name} label={label} />}
      <CreatableSelect
        isClearable
        isSearchable
        options={options}
        className={className}
        placeholder={
          placeholder ? t(placeholder as NormalizeTranslationKeys) : undefined
        }
        onCreateOption={handleCreate}
        {...field}
      />
    </>
  );
};
