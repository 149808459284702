import type { FC } from "react";

import { Icon } from "./Icon";
import { clsx, numberFormat } from "core";

type EvolutionPercentProps = {
  evolution: number;
  positive?: boolean;
  size?: "small" | "large";
};

const EvolutionPercent: FC<EvolutionPercentProps> = ({
  evolution,
  positive,
  size = "small",
}) => {
  const isPositive = evolution >= 0 || positive;

  return (
    <div
      className={clsx(
        "flex flex-row items-center",
        size === "small" ? "text-xs" : "text-sm",
        isPositive ? "text-primary-400" : "text-red-500"
      )}
    >
      <Icon
        type="gain_arrow"
        className="stroke-current"
        rotate={isPositive ? 0 : 180}
      />
      <p className="ml-1 text-xs font-bold leading-none">
        {numberFormat(evolution, {
          style: "percent",
          signDisplay: "exceptZero",
        })}
      </p>
    </div>
  );
};

export default EvolutionPercent;
