import { WithWallets } from "HOC";
import { Page } from "@types";

export const GenericPage = ({ currentRouteKey }: Page) => (
  <WithWallets
    hideCurrenciesTracker
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    type={currentRouteKey!}
  ></WithWallets>
);
