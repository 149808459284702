import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import React, { useMemo, Dispatch, SetStateAction } from "react";

import { AssetType } from "@types";
import PremiumBlur from "./PremiumBlur";
import { feeAggregation, clsx } from "core";
import { Container } from "components/Container";
import { AssetRepartition } from "components/AssetRepartition";

interface FeeAggregationType {
  key: string;
  name: string;
  value: number;
  instrument: string;
  reprensentation: number;
}
const topSorting: any = (a: { value: number }, b: { value: number }) =>
  b.value - a.value;
const flopSorting: any = (a: { value: number }, b: { value: number }) =>
  a.value - b.value;

const CostAnalysis = ({ className }: { className?: string }) => {
  const feeAggregationQuery = useQuery("feeAggregation", feeAggregation);
  const [selectedTab, setSelectedTab] = React.useState<"top" | "flop">("top");
  const { t } = useTranslation();

  const feeAggregationList =
    (
      feeAggregationQuery.data as {
        feeAggregation: FeeAggregationType[];
      }
    )?.feeAggregation ?? [];

  const filteredData = useMemo(() => {
    if (!feeAggregationList) {
      return [];
    }

    return feeAggregationList
      .sort(selectedTab === "top" ? topSorting : flopSorting)
      .filter((item) => {
        if (selectedTab === "top") {
          return item.value > 0;
        }
        return item.value < 0;
      })
      .slice(0, 5);
  }, [selectedTab, feeAggregationList]);

  return (
    <PremiumBlur title={t("premium.costAnalysis")}>
      <Container className={clsx("h-full p-4", className)}>
        <p className="text-content-primary mr-4 shrink font-sans text-lg font-bold leading-6">
          {t("premium.costAnalysis")}
        </p>
        <Tab
          className="mt-4"
          selectedValue={selectedTab}
          setValue={setSelectedTab}
          values={[
            {
              title: t("palmares.best"),
              value: "top",
            },
            {
              title: t("palmares.worst"),
              value: "flop",
            },
          ]}
        />
        <div className="mt-4">
          {feeAggregationQuery.isLoading ? (
            <div className="flex-row py-2">
              {/* <Skeleton width={100} height={12} circle /> */}

              <div className="items-end justify-between">
                {/* <Skeleton width={60} height={8} circle /> */}
              </div>
            </div>
          ) : filteredData.length > 0 ? (
            <AssetRepartition
              type={AssetType.bankAccount}
              dataset={
                filteredData.map((item) => ({
                  title: item.name,
                  value: item.value || 0,
                })) || []
              }
            />
          ) : (
            <div>
              <p className="text-content-tertiary text-center font-sans text-sm">
                {t("noData")}
              </p>
            </div>
          )}
        </div>
      </Container>
    </PremiumBlur>
  );
};

type Value = {
  title: string;
  value: string;
};

type TabProps = {
  values: Value[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: Dispatch<SetStateAction<any>>;
  selectedValue: string;
  className?: string;
};

function Tab({ values, setValue, selectedValue, className }: TabProps) {
  return (
    <div
      className={clsx(
        "flex w-full flex-row rounded-full bg-tertiary p-0.5",
        className
      )}
    >
      {values.map((value, index) => (
        <button
          key={index}
          className={clsx(
            "grow basis-2 items-center justify-center rounded-full px-3 py-1.5",
            value.value === selectedValue && "bg-white"
          )}
          onClick={() => setValue(value.value)}
        >
          <p
            className={clsx(
              "font-sans text-sm font-bold",
              value.value !== selectedValue && "text-content-secondary"
            )}
          >
            {value.title}
          </p>
        </button>
      ))}
    </div>
  );
}

export default CostAnalysis;
