import { Icon } from "components/Icon";
import { Typo } from "components/Typo";
import { RadioSelect } from "components/RadioSelect";
import { AssetRowType, Row } from "components/AssetRow";
import { AssetType } from "@types";

type Actions = {
  onEdit?: () => void;
  onClick?: () => void;
  onDelete?: () => void;
};

type AssetEvolutionProps = {
  dataset: (Omit<AssetRowType, "type"> & Actions)[];
};

export function AssetEvolution({ dataset }: AssetEvolutionProps) {
  const style = {
    width: "calc(100% - 30px)",
  };

  return (
    <div>
      <ul className="flex flex-col gap-2">
        {dataset.map(({ id, onEdit, onClick, onDelete, ...data }) => {
          const showActions = !!onEdit || !!onClick;

          return (
            <li key={id} className="relative flex items-center">
              <div
                onClick={
                  !data.isUnderManagement ||
                  data.assetType === AssetType.lifeInsurance
                    ? onClick
                    : undefined
                }
                style={showActions ? style : { width: "100%" }}
              >
                <Row
                  type="evolution"
                  {...data}
                  id={id}
                  className={showActions ? "pr-0" : ""}
                />
              </div>

              {showActions && (
                <div className="group relative flex-1">
                  <div
                    className={`group inline-flex items-center justify-center gap-2 ${
                      !data.isUnderManagement ||
                      data.assetType === AssetType.lifeInsurance
                        ? "cursor-pointer hover:opacity-50"
                        : ""
                    } `}
                  >
                    <Icon type="options" className="h-5 w-5" />
                  </div>
                  <div className="bg-purple-300">
                    <RadioSelect
                      hideIcon
                      align="right"
                      position="bottom"
                      className="z-50 w-28"
                      list={
                        data.isUnderManagement
                          ? []
                          : [
                              ...(onEdit
                                ? [
                                    {
                                      element: (
                                        <Typo
                                          children="actions.update"
                                          className="text-black opacity-50 hover:opacity-100"
                                        />
                                      ),
                                      id: "update",
                                    },
                                  ]
                                : []),
                              ...(onDelete
                                ? [
                                    {
                                      element: (
                                        <Typo
                                          children="actions.delete"
                                          className="text-black opacity-50 hover:opacity-100"
                                        />
                                      ),
                                      id: "delete",
                                    },
                                  ]
                                : []),
                            ]
                      }
                      onChange={(value) =>
                        value === "update" ? onEdit?.() : onDelete?.()
                      }
                    />
                  </div>
                </div>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
