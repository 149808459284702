import { ReactElement } from "react";
import { Route, Routes } from "react-router";
import { BrowserRouter, Navigate } from "react-router-dom";

import { isFn } from "./check";
import { UserInterface } from "@types";
import { AuthService } from "core/services";
import { NormalizeTranslationKeys } from "i18n";

export type RouteType = {
  path?: string;
  menuName?: string;
  element?: ReactElement;
  children?: Array<RouteType> | Array<any>;
  auth?: ((user: UserInterface) => boolean) | boolean;
  needPermission?: string;
  label?: NormalizeTranslationKeys;
  menuIcon?: any;
  menuVisibility?:
    | "always"
    | "never"
    | "admin"
    | "tenant"
    | Array<string>
    | ((user?: UserInterface) => boolean);
  menuClassName?: string;
  menuDisabled?: boolean;
  isPremium?: boolean;
};

export type MenuLinkType = {
  name?: string;
  icon?: any;
  to?: string;
  menuClassName?: string;
  menuDisabled?: boolean;
  children?: MenuLinkType[];
  parent?: MenuLinkType;
};

function getRoutes(routeList: Array<RouteType>, authFallBack: string) {
  return routeList.map(({ auth = true, ...route }, i) => {
    const user = AuthService.getUser();
    const key = `${i}-${route.path}`;

    if (auth) {
      if (!AuthService.isAuth() || (isFn(auth) && (!user || !auth(user))))
        return (
          <Route
            key={key}
            path="*"
            element={<Navigate to={authFallBack} replace />}
          />
        );
    }
    if (route.children && route.children.length) {
      return (
        <Route
          key={key}
          {...route}
          children={getRoutes(route.children, authFallBack)}
        />
      );
    }

    return <Route key={key} {...route} />;
  });
}

export function getMenuLinks(
  user: UserInterface,
  routeList: Array<RouteType>,
  visibility: "always" | "never" | "admin" | "tenant" = "always",
  parent?: MenuLinkType
): Array<MenuLinkType> {
  return routeList
    .filter(({ menuVisibility = "always" }) =>
      isFn(menuVisibility)
        ? menuVisibility(user)
        : menuVisibility.includes(visibility) || menuVisibility === "always"
    )
    .map((item) => ({
      name: item.label,
      icon: item.menuIcon,
      to: item.path,
      menuClassName: item.menuClassName,
      menuDisabled: item.menuDisabled,
      parent,
      children: item.children
        ? getMenuLinks(user, item.children, visibility, item)
        : undefined,
    }));
}

export function Navigation({
  routeList,
  authFallBack,
}: {
  routeList: Array<RouteType>;
  authFallBack: string;
}) {
  return (
    <BrowserRouter>
      <Routes>{getRoutes(routeList, authFallBack)}</Routes>
    </BrowserRouter>
  );
}
