import {
  useState,
  Children,
  useEffect,
  cloneElement,
  ReactElement,
  isValidElement,
} from "react";
import { useTranslation } from "react-i18next";
import {
  matchPath,
  useParams,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { AuthService } from "core/services";
import { routes, RoutesType } from "routing";
import { getAssetTypeColor } from "core";
import { tracker, Tracking } from "core/helpers/tracking";

import { clsx } from "core";
import { Header } from "./header";
import { AssetType, UserInterface } from "@types";
import { Sidebar } from "./sidebar";
import { NoDataSync } from "components/NoDataSync";

const useDashboard = () => {
  // State
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [emptyData, setIsEmptyData] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isFirstFetch, setIsFirstFetch] = useState(true);

  // Hooks
  const { t } = useTranslation();
  const location = useLocation();

  // Auth user
  const user = AuthService.getUser();
  const name = user?.name;

  const navigate = useNavigate();

  // Query params
  const params = useParams();
  const search = location.search;
  const querySearch = new URLSearchParams(search);

  // Route/Page params
  const currentPath = location.pathname;
  const currentRouteKey = Object.keys(routes).find((key: RoutesType) =>
    matchPath(routes[key].path, currentPath)
  ) as RoutesType;

  const route = routes[currentRouteKey];

  const isWalletAddress = route.key === routes.addWallet.key;

  const actif = querySearch?.get("actif") as RoutesType;
  const hasActif = querySearch?.has("actif");
  const hasOnBoarding = querySearch?.has("onboarding");

  useEffect(() => {
    const type = (
      [
        routes.home.key,
        routes.other.key,
        routes.heritageObjectives.key,
      ].includes(route.key)
        ? undefined
        : params.type ?? route?.type
    ) as AssetType;

    document.documentElement.style.setProperty(
      "--color-asset",
      getAssetTypeColor(type).color
    );

    if (type) {
      tracker.log(Tracking.AssetTypeEvent.PAGE_VIEW, type);
    }
  }, [route?.path]);

  return {
    t,
    user,
    name,
    route,
    params,

    actif,
    hasActif,
    isLoading,
    emptyData,
    isFirstFetch,
    hasOnBoarding,
    setIsLoading,
    setIsEmptyData,
    setIsFirstFetch,

    sidebarOpen,
    querySearch,
    currentRouteKey,
    isWalletAddress,
    navigate,
    setSidebarOpen,
  };
};

const excludeKeyFromEmptyDisplay = [
  "other",
  "premium",
  "profile",
  "addWallet",
  "heritageObjectives",
  "realEstateDetails",
  "scpi",
];

export function Dashboard({
  children,
  className = "flex gap-y-4 gap-x-4",
}: {
  children: ReactElement | ReactElement[];
  className?: string;
}) {
  const {
    t,
    user,
    name,
    actif,
    route,
    params,
    isLoading,
    emptyData,
    sidebarOpen,
    querySearch,
    isFirstFetch,
    currentRouteKey,
    isWalletAddress,
    navigate,
    setSidebarOpen,
    setIsLoading,
    setIsEmptyData,
    setIsFirstFetch,
  } = useDashboard();

  const props = {
    t: t,
    user: user as UserInterface,
    name: name,
    route: route,
    isHome: false,
    params: params,
    data: undefined,
    isLoading: false,
    children: children,
    className: className,
    sidebarOpen: sidebarOpen,
    querySearch: querySearch,
    currentRouteKey: currentRouteKey,
    isWalletAddress: isWalletAddress,
    navigate: navigate,
    setSidebarOpen: setSidebarOpen,
  };
  const showData = !emptyData || excludeKeyFromEmptyDisplay.includes(route.key);

  return (
    <div>
      <Sidebar {...props} />
      <div
        className={clsx(
          "flex h-screen flex-1 flex-col md_lg:pl-[250px]",
          querySearch?.has("actif") && actif !== "home" && "cardIsOpen"
        )}
      >
        <Header
          {...props}
          setIsLoading={setIsLoading}
          setIsEmptyData={setIsEmptyData}
          setIsFirstFetch={setIsFirstFetch}
          actif={actif}
        />
        <Main
          isLoading={isLoading}
          isFirstFetch={isFirstFetch}
          querySearch={querySearch}
          actif={actif}
          className={className}
          children={children}
          params={params}
          showData={showData}
          currentRouteKey={currentRouteKey}
          navigate={navigate}
        />
      </div>
    </div>
  );
}

type DashboardProps = ReturnType<typeof useDashboard>;

type MainProps = Pick<
  DashboardProps,
  | "isLoading"
  | "isFirstFetch"
  | "querySearch"
  | "actif"
  | "params"
  | "currentRouteKey"
  | "navigate"
> & {
  showData: boolean;
  className?: string;
  children: ReactElement | ReactElement[];
};

function Main({
  isLoading,
  querySearch,
  className,
  children,
  params,
  showData,
  currentRouteKey,
  navigate,
}: MainProps) {
  if (!showData && !isLoading) {
    return <NoDataSync />;
  }

  return (
    <main className="flex-1 overflow-auto bg-grey px-6 py-5 md:px-12">
      <div className={clsx("flex h-full w-full", className)}>
        {Children.map(children, (child) =>
          !isValidElement(child)
            ? child
            : cloneElement(child as ReactElement, {
                params,
                currentRouteKey,
                querySearch,
                navigate,
              })
        )}
      </div>
    </main>
  );
}
