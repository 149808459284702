import type { ReactNode } from "react";
import i18next, { DefaultTFuncReturn } from "i18next";
import { Link, LinkProps } from "react-router-dom";

import { Amount } from "@types";
import { clsx, formatCurrency, isStr } from "core";

type TextAsType = "h1" | "h2" | "h3" | "p" | "span" | "label";

type Color = "primary" | "secondary" | "tertiary" | "white";

interface Props {
  amount?: Amount;
  as?: TextAsType;
  color?: Color;
  htmlFor?: string;
  className?: string;
  disableTranslate?: boolean;
  variant?: "primary" | "secondary" | "tertiary";
  "data-testid"?: string;
}

export type TextPropsWithLabel = Props & {
  label: string | number | JSX.Element | DefaultTFuncReturn;
};
type TextPropsWithChildren = Props & { children: ReactNode };
type TextPropsWithAmount = Props & { amount: Amount };

type LinkPropsWithOptions = Props & LinkProps;
interface LinkPropsType extends LinkPropsWithOptions {
  to: LinkProps["to"];
  forceRefresh?: boolean;
}

export type LinkPropsWithLabel = LinkPropsType & { label: string | number };
type LinkPropsWithChildren = LinkPropsType & { children: ReactNode };

export type TextProps =
  | TextPropsWithLabel
  | TextPropsWithChildren
  | TextPropsWithAmount
  | LinkPropsWithLabel
  | LinkPropsWithChildren;

export const textAs: Record<TextAsType, string> = {
  h1: "mx-0 text-2xl",
  h2: "text-2xl font-semibold", // change 'font-semibold' to 'font-bold' or 'font-extrabold'
  h3: "text-lg",
  p: "text-base",
  span: "text-sm",
  label: "text-sm",
};

export function Text(props: LinkPropsWithLabel): JSX.Element;
export function Text(props: TextPropsWithLabel): JSX.Element;
export function Text(props: TextPropsWithAmount): JSX.Element;
export function Text(props: TextPropsWithChildren): JSX.Element;
export function Text(props: LinkPropsWithChildren): JSX.Element;

export function Text({
  as = "p",
  amount,
  color,
  variant,
  className,
  disableTranslate,
  ...rest
}: TextProps) {
  let content: ReactNode | string = "";
  let props = {} as TextPropsWithLabel | TextPropsWithChildren;

  if ("label" in rest) {
    const { label, ...restProps } = rest as TextPropsWithLabel;
    content = label;
    props = { ...restProps } as TextPropsWithLabel;
  } else {
    const { children, ...restProps } = rest as TextPropsWithChildren;
    content = children;
    props = { ...restProps } as TextPropsWithChildren;
  }

  if (amount) content = formatCurrency(amount);
  else if (isStr(content)) {
    content = disableTranslate
      ? content.toString()
      : i18next.t(content as string);
  }

  if ("to" in rest) {
    const mergedClassName = clsx(
      "text-blue-500 hover:text-blue-700 hover:underline",
      color && `text-${color}`,
      variant && `text-${variant}`,
      className
    );

    if ("forceRefresh" in rest) {
      const { to: href, ...linkProps } = props as LinkPropsWithOptions;

      return (
        <a href={href as string} {...linkProps} className={mergedClassName}>
          {content}
        </a>
      );
    }

    return (
      <Link {...(props as LinkProps)} className={mergedClassName}>
        {content}
      </Link>
    );
  }

  const TagName = as;

  return (
    <TagName
      {...props}
      className={clsx(
        textAs[as],
        color && `text-${color}`,
        variant && `text-${variant}`,
        className
      )}
    >
      {content}
    </TagName>
  );
}
