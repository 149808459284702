/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useState } from "react";

import { useFormBuilder } from "context/formBuilderCtx";
import { FormErrorType, InputTypes } from "@types";
import { clsx } from "core";
import { Error } from "../utils";

export const Fieldset: FC<InputTypes> = ({
  name,
  label,
  error,
  value,
  errorType = "message",
  className = "",
  required,
  register,
  sufix,
  prefix,
  ...props
}) => {
  const [isFocus, setIsFocus] = useState(false);
  const _formBuilderCtx = useFormBuilder();
  const errorMessageType: FormErrorType[] = ["message", "message-bordered"];
  const errorBorderType: FormErrorType[] = ["bordered", "message-bordered"];

  const onFocus = () => setIsFocus(true);
  const onBlur = () => setIsFocus(false);

  return (
    <fieldset
      disabled={props.disabled}
      className={clsx(
        "border border-solid border-primary-200 p-3",
        _formBuilderCtx?.classNames?.input?.wrapper,
        className
      )}
    >
      {!!label && (
        <legend
          className={clsx(
            "block text-sm font-medium text-gray-700",
            _formBuilderCtx?.classNames?.label?.className
          )}
        >
          {isFocus || value ? (
            <span>{label}</span>
          ) : (
            <span className="relative flex items-center">
               
              <span className="absolute left-0 w-full border-b border-inherit" />
            </span>
          )}
        </legend>
      )}
      <div className={_formBuilderCtx?.classNames?.input?.container}>
        <input
          onFocus={onFocus}
          className={clsx(
            "w-full",
            error && errorBorderType.includes(errorType) && "border-red-500",
            _formBuilderCtx?.classNames?.input?.className
          )}
          {...props}
          placeholder={props.placeholder as unknown as string}
          {...(register
            ? register(name, {
                required,
                valueAsNumber: props.type === "number",
              })
            : { name, required: !!required })}
          onBlur={onBlur}
        />
      </div>
      {error && errorMessageType.includes(errorType) && (
        <Error message={error} />
      )}
    </fieldset>
  );
};
