import CryptoJS from "crypto-js";

import { config } from "config";
import { isObj } from "core/helpers/check";

export default class SecureStorageService {
  static key = config?.secureStorage || "this is the key what encrypt content";

  static setItem(name: string, value: string, isSesssionStorage = false) {
    if (isSesssionStorage)
      sessionStorage.setItem(
        name,
        this.encrypt(isObj(value) ? JSON.stringify(value) : value)
      );
    else
      localStorage.setItem(
        name,
        this.encrypt(isObj(value) ? JSON.stringify(value) : value)
      );
    return this.getItem(name);
  }

  static getItem(name: string): string | { [key: string]: any } {
    let value: any = localStorage.getItem(name) || sessionStorage.getItem(name);

    try {
      value = value != null ? this.decrypt(value) : value;
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  }

  static encrypt(value: string): string {
    return CryptoJS.AES.encrypt(value, this.key).toString();
  }

  static decrypt(encrypted: string): string {
    return CryptoJS.AES.decrypt(encrypted, this.key).toString(
      CryptoJS.enc.Utf8
    );
  }
}
