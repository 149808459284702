import { FieldValues } from "react-hook-form";

import { AssetType } from "@types";
import { ucFirst } from "core/helpers";
import { makeMutation } from "./utils";

type UpdateResult = {
  id: string;
};
const updateTypes: Record<AssetType, string> = {
  [AssetType.loan]: "loan",
  [AssetType.exotic]: "exotic",
  [AssetType.crypto]: "crypto",
  [AssetType.stocks]: "stocks",
  [AssetType.savings]: "savings",
  [AssetType.economies]: "economies",
  [AssetType.realEstate]: "realEstate",
  [AssetType.retirements]: "retirement",
  [AssetType.bankAccount]: "bankAccount",
  [AssetType.crowdfunding]: "crowdfunding",
  [AssetType.lifeInsurance]: "lifeInsurance",
  [AssetType.privateCivilRealEstate]: "privateCivilRealestate",
};

export function updateAssetTypeMutation(
  type: AssetType,
  variables: FieldValues
) {
  const key = updateTypes[type];
  const keyUcFirst =
    type === AssetType.crowdfunding ? "Crownfunding" : ucFirst(key);

  return makeMutation<UpdateResult>(`
    mutation ${keyUcFirst}Update($id: String!, $input: ${keyUcFirst}UpdateInput!) {
    ${key}Update(id: $id, input: $input) {
        id
    }
  }
`)(variables);
}
export const updateAssetMutation = makeMutation(`
  mutation UpdateAssetParams($params: AssetUpdate!) {
    updateAssetParams(params: $params) {
      id
      name
    }
  }
`);

export const updateRetirementSimulationFormMutation = makeMutation(`
  mutation UpdateRetirementSimulation($form: RetirementSimulationFormUpdateInput!) {
    updateRetirementSimulation(form: $form) {
      dateOfBirth
    }
  }
`);
