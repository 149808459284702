import { useEffect, useState, VFC } from "react";

import { Widget } from "components";
import { NormalizeTranslationKeys } from "i18n";

interface Tab {
  title: NormalizeTranslationKeys;
  component: JSX.Element;
  icon: any;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}
interface Props {
  tabs: Tab[];
  defaultTab?: number;
}

export const VerticalTabs: VFC<Props> = ({ tabs, defaultTab = 0 }) => {
  const [tabIndex, setTabIndex] = useState(defaultTab);

  useEffect(() => {
    setTabIndex(defaultTab);
  }, [tabs, tabIndex]);

  return (
    <Widget label={tabs[tabIndex].title}>{tabs[tabIndex]?.component}</Widget>
  );
};
