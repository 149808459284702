import { createSlice } from "@reduxjs/toolkit";

import { Period } from "components";

const initialState: Record<"value", Period> = {
  value: 30,
};
export const periodReducer = createSlice({
  name: "period",
  initialState,
  reducers: {
    setPeriod: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setPeriod } = periodReducer.actions;

export default periodReducer.reducer;
