export const amountFormatterOptions: Intl.NumberFormatOptions = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  signDisplay: "auto",
  currencyDisplay: "symbol",
  style: "currency",
  useGrouping: true,
};

export const evolutionAmountFormatterOptions: Intl.NumberFormatOptions = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  style: "currency",
  useGrouping: true,
};

export const evolutionPercentFormatter = Intl.NumberFormat(undefined, {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  signDisplay: "exceptZero",
  style: "percent",
  useGrouping: true,
});

export const percentFormatter = Intl.NumberFormat(undefined, {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  style: "percent",
  useGrouping: true,
});
