import type { FC } from "react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { routes } from "routing";
import { AssetType } from "@types";
import { InfoLine } from "../InfoLine";
import { NormalizeTranslationKeys } from "i18n";
import { dateFormat, numberFormat } from "core";
import { getRealEstateById } from "./Assets.logic";
import { useAssetActions } from "./AssetActions/AssetActions";
import { useDeleteAsset } from "core/helpers/hooks/useDeleteAsset";
import { Icon, ManualUpdateModal, ScrollableContainer } from "components";

type RealEstateViewProps = {
  id: string;
};

const RealEstateView: FC<RealEstateViewProps> = ({ id }) => {
  const realEstateQuery = useQuery(["realEstate", id], () =>
    getRealEstateById(id)
  );

  const navigation = useNavigate();
  const { mutate: deleteAsset } = useDeleteAsset(() => {
    navigation(routes.realEstate.path);
  });
  const { render, state, dispatch } = useAssetActions(["edit", "delete"], {
    onDelete: async () => deleteAsset(id),
  });
  const { t } = useTranslation();

  if (!realEstateQuery.data) {
    return null;
  }

  return (
    <>
      <ScrollableContainer
        title="title.informations"
        className="h-fit"
        Right={render}
      >
        <div className="p-4 pt-0">
          <InfoLine
            label="formLabel.source"
            value={realEstateQuery.data.source}
            icon={<Icon type="marker-pin" />}
          />
          <InfoLine
            label="formLabel.price"
            value={numberFormat(realEstateQuery.data.initialEstimation.value)}
            icon={<Icon type="tag" />}
          />
          <InfoLine
            label="formLabel.buyingDate"
            value={
              realEstateQuery.data.buyingDate
                ? dateFormat(realEstateQuery.data.buyingDate)
                : undefined
            }
            icon={<Icon type="calendar" />}
          />
        </div>
      </ScrollableContainer>
      <ScrollableContainer
        title="title.realEstateDetails"
        className="mt-4 gap-2 p-4"
      >
        <div className="flex flex-col gap-2 p-4 pt-0">
          <InfoLine
            label="formLabel.type"
            value={
              t(
                `formLabel.${realEstateQuery.data.typeId}` as NormalizeTranslationKeys
              ) as string
            }
            icon={<Icon type="home" />}
          />

          <InfoLine
            label="formLabel.area"
            value={`${realEstateQuery.data.area} ㎡`}
          />

          <InfoLine
            label="formLabel.rooms"
            value={realEstateQuery.data.rooms || 0}
          />

          <InfoLine
            label="formLabel.bedrooms"
            value={realEstateQuery.data.bedrooms}
          />

          <InfoLine
            label="formLabel.condition"
            value={
              realEstateQuery.data.condition
                ? t(`formLabel.${realEstateQuery.data.condition}`)
                : undefined
            }
          />

          <InfoLine
            label="formLabel.orientation"
            value={t(`formLabel.${realEstateQuery.data.orientation}`)}
          />
        </div>
        {!!state.edit && (
          <ManualUpdateModal
            selected={realEstateQuery.data}
            editType={"update"}
            queryType={AssetType.realEstate}
            onClose={() => dispatch({ type: "edit" })}
            handleUpdate={realEstateQuery.refetch}
            handleDelete={console.log}
            handleActivityDelete={console.log}
          />
        )}
      </ScrollableContainer>
    </>
  );
};

export default RealEstateView;
