// Types
type assetExcludes = "transactions" | "activities" | "currentActivity";

// Queries
const conditionalRender = (condition: boolean, query: string) =>
  condition ? query : "";
const assets = (excludes?: assetExcludes[]) => `
  asset {
    id
    status
    activities {
      id
      value
      start
      end
    }
    currentActivity {
      value
    }
    performance{
      evolution
      gain {
        value
      }
    }
    ${conditionalRender(
      !excludes || !excludes.includes("transactions"),
      "transactions { id name date amount }"
    )}
  }
`;

// export
export const FRAGMENT_ASSETS = assets(["transactions"]);
export const FRAGMENT_ASSETS_WITH_TRANSACTIONS = assets();
export const FRAGMENT_USER_FIELDS = `
    id
    name
    email
    firstName
    lastName
    location
    phoneNumber
    birthday
    linkedin
    isPremium
    plan
    layoutSettings
    enableCGPManagment
    referenceInstrument
    hasDiscount
`;
