import { useState } from "react";

import { config } from "config";
import { Typo } from "components";
import { clsx, numberFormat } from "core";
import { AuthService } from "core/services";
import { SubscriptionType, UserInterface } from "@types";
import { tracker, Tracking } from "core/helpers/tracking";
import { checkoutSessionURL } from "./ChooseSubscription.logic";

const standardFeatures: JSX.Element[] = [
  <div>
    <span className="text-primary-400">Centralisez</span> tous vos actifs sur la
    même interface.
  </div>,
  <div>
    <span className="text-primary-400">Synchronisation</span> de vos
    investissements 100 % sécurisée.
  </div>,
  <div>Accès au support d’aide et d’accompagnement.</div>,
];
const premiumFeatures: JSX.Element[] = standardFeatures.concat([
  <>
    <span className="text-primary-400">Suivez et analysez</span> votre
    patrimoine en temps réel.
  </>,
  <>Co-construisez l’application qui vous ressemble.</>,
  <>
    <span className="text-primary-400">Un accès</span> à des fonctionnalités
    d’analyse et d’optimisation.
  </>,
  <>Un réseau d’investisseur hyper-actif.</>,
  <>
    Du <span className="text-primary-400">contenu</span> de formation financière
    exclusif.
  </>,
]);
const featuresMap: Record<SubscriptionType, JSX.Element[]> = {
  free: standardFeatures,
  premium_monthly: premiumFeatures,
  premium_yearly: premiumFeatures,
};
type Stripe = { product: string; price: string };
type SubscriptionRecurrence = "none" | "monthly" | "yearly";

interface SubscriptionProduct {
  title: string;
  isPremium: boolean;
  isPopular: boolean;
  recurrence: SubscriptionRecurrence;
  subscriptions: {
    id: SubscriptionType;
    price: { value: number; instrument: string };
    offers: {
      partners: { value: number; instrument: string };
    };
    stripe: Stripe | null;
  }[];
}

export const ChooseSubscription = () => {
  const user = AuthService.getUser();

  const onSubscriptionSubmit = async (id: SubscriptionType) => {
    tracker.log(Tracking.Event.PURCHASE);
    let stripe: Stripe | null = null;
    config.premiumProducts.find((product: SubscriptionProduct) => {
      const subscription = product.subscriptions.find((s) => s.id === id);

      if (subscription?.stripe) {
        stripe = subscription.stripe as Stripe;
        return true;
      }
      return false;
    });

    if (!stripe) {
      console.error("no action for product", id);
      return;
    }

    const data = await checkoutSessionURL({
      product: (stripe as Stripe).product,
      price: (stripe as Stripe).price,
    });

    if (data.checkoutSessionURL) {
      window.location.replace(data.checkoutSessionURL);
    }
  };
  const userSubscription = user?.activeSubsciptions?.[0].id ?? "free";

  return (
    <div className="flex flex-col _700:flex-row _700:space-x-10">
      {config.premiumProducts.map(
        (product: SubscriptionProduct, index: number) => (
          <SubscriptionCard
            key={index}
            {...product}
            user={user as UserInterface}
            userSubscription={userSubscription ?? "free"}
            action={onSubscriptionSubmit}
          />
        )
      )}
    </div>
  );
};

interface SubscriptionCardProps extends SubscriptionProduct {
  user: UserInterface;
  userSubscription: SubscriptionType | null;
  action: (id: SubscriptionType) => void;
}

function SubscriptionCard({
  user,
  subscriptions,
  ...props
}: SubscriptionCardProps) {
  const [currentSubscriptionId, setCurrentSubscriptionId] = useState<
    SubscriptionType | undefined
  >(subscriptions[0]?.id ?? "free");

  const current = subscriptions.find(
    (subscription) => subscription.id === currentSubscriptionId
  );

  if (!current) {
    return null;
  }
  const userSubscription = user?.activeSubsciptions?.[0].id ?? "free";
  const price =
    user?.hasDiscount === true ? current.offers.partners : current.price;

  const isFree = price.value === 0;
  const features = featuresMap[current.id];
  const priceValueText = numberFormat(price.value);
  const isActive = current.id === userSubscription;

  return (
    <div className="relative mb-8 h-fit divide-y overflow-hidden rounded-lg border shadow-sm _700:mb-0">
      <div className="h-24 bg-primary p-5">
        <div className="text-center">
          <Typo
            type="wlc_inter_xl"
            className="text-lg font-bold uppercase leading-6 text-white"
          >
            {props.title}
          </Typo>
        </div>
        {!isFree && (
          <div className="flex justify-center gap-5 pt-2">
            {subscriptions.map(({ id }) => (
              <div
                key={id}
                className={clsx(
                  "cursor-pointer rounded p-[2px]",
                  id == currentSubscriptionId && "bg-indigo-600"
                )}
                onClick={() => setCurrentSubscriptionId(id)}
              >
                <Typo
                  type="wlc_inter_sm"
                  className="flex items-center justify-center text-center text-[8px] text-white"
                >
                  {id === "premium_yearly" ? "Annuel" : "Mensuel"}
                </Typo>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="bg-white px-6 pt-6 pb-8">
        <div className="flex items-center justify-center">
          <Typo
            type="wlc_inter_xxl"
            className="flex items-center justify-center text-center text-primary-400"
          >
            {priceValueText}
          </Typo>

          <Typo
            type="wlc_inter_sm"
            className="flex items-center justify-center text-center text-[8px] text-primary-400"
          >
            {current.id === "premium_yearly" ? "/ an" : "/ mois"}
          </Typo>
        </div>

        <button
          onClick={() => props.action(current.id)}
          disabled={isActive}
          className="m-auto my-8 block w-36 cursor-pointer rounded-xl border-2 border-primary-400 bg-primary-400 py-2 text-center text-sm font-semibold text-white hover:bg-white hover:text-primary-400 disabled:bg-white disabled:text-primary-400 disabled:hover:bg-white"
        >
          {isActive ? "Actuel" : "S'abonner"}
        </button>

        <ul role="list" className="space-y-4">
          {features.map((feature, index) => (
            <li key={index} className="flex space-x-3">
              <span className="text-sm font-medium text-slate-600">
                {feature}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
