import { VFC } from "react";
import { NavigateFunction } from "react-router-dom";

import { CardContent, Modal, RangedCard, Table, Typo } from "components";
import { FormBuilder } from "components/formBuilder";
import {
  FormBuilderProps,
  InverstorProfileExperience,
  InvestmentHorizon,
  InvestmentType,
  IPerformance,
  ITable,
  IYield,
} from "@types";
import { numberFormat } from "core";
import { routes } from "routing";

const formItems: FormBuilderProps = {
  items: [
    {
      className: "grid _700:grid-cols-2 grid-cols-1 gap-4",
      children: [
        { name: "dateOfBirth", type: "date", label: "Date de naissance" },
        {
          name: "startOfActivity",
          type: "date",
          label: "Date de début d'activité",
        },
        {
          name: "endOfActivity",
          type: "date",
          label: "Date de fin d'activité",
        },
        {
          name: "yearsOfInterruption",
          type: "number",
          label: "Années d'interruption",
        },
        { name: "profession", label: "Profession" },
        {
          name: "best25Year",
          type: "number",
          label: "Salaire annuel moyen des 25 meilleurs années",
        },
      ],
    },
  ],
};
const firstInvestType = {
  [InvestmentHorizon.short]: 5,
  [InvestmentHorizon.medium]: 50,
  [InvestmentHorizon.long]: 95,
};
const sedondInvestmentType = {
  [InvestmentType.dynamic]: 2.5,
  [InvestmentType.neutral]: 25,
  [InvestmentType.judicious]: 47.5,
};
const sedondInvestYieldType = {
  [IYield.high]: 2.5,
  [IYield.medium]: 25,
  [IYield.low]: 47.5,
};

const calcSecond = (
  investType: keyof typeof InvestmentType,
  investYield: keyof typeof IYield
) => sedondInvestmentType[investType] + sedondInvestYieldType[investYield];

export const loanFormItems: FormBuilderProps = {
  items: [
    {
      className: "grid _700:grid-cols-2 grid-cols-1 gap-4",
      children: [
        { name: "annualIncome", type: "number", label: "Revenus mensuel" },
        {
          name: "monthlyRepayment",
          type: "number",
          label: "Mensualité de remboursement",
        },
        {
          name: "spending",
          type: "number",
          label: "Dépenses mensuelles moyenne",
        },
        { name: "savingsPerYear", type: "number", label: "Capacité d’épargne" },
      ],
    },
  ],
};
const master: Record<InverstorProfileExperience, number> = {
  beginner: 0,
  intermediate: 50,
  expert: 100,
};

type OpenType = "loan" | "retirement" | false;
interface Props {
  open: OpenType;
  userSettings: any;
  hasLoan: boolean;
  weightData: ITable["data"];
  hasComputeRetirement: boolean;
  totalPerformance?: IPerformance;
  feeAggregation: {
    instrument: string;
    key: string;
    name: string;
    reprensentation: number;
    value: number;
  }[];
  showWealthComplete?: boolean;
  showInvestorComplete?: boolean;
  investorRisk: number | null;
  navigate: NavigateFunction;
  toggleOpen: (type: OpenType) => void;
  onSubmit: (values: any) => void;
  setOpen: (value: OpenType) => void;
}
export const Child: VFC<Props> = ({
  open,
  hasLoan,
  weightData,
  investorRisk,
  userSettings,
  feeAggregation,
  totalPerformance,
  showWealthComplete,
  showInvestorComplete,
  hasComputeRetirement,
  setOpen,
  onSubmit,
  navigate,
  toggleOpen,
}) => (
  <div className="w-full">
    <div className="max-h-full rounded-md">
      <div className="grid grid-cols-1 gap-x-3 gap-y-3 sm_md:grid-cols-2">
        <div className="grid grid-cols-1 gap-x-3 gap-y-3 xl_2xl:grid-cols-2">
          <div className="cards-premium order-1 h-full">
            <RangedCard
              type="wealth"
              showComplete={showWealthComplete}
              firstRange={
                firstInvestType[
                  userSettings?.patrimonialsGoals
                    ?.investmentHorizon as keyof typeof firstInvestType
                ]
              }
              secondRange={calcSecond(
                userSettings?.patrimonialsGoals?.investmentType,
                userSettings?.patrimonialsGoals?.yield
              )}
              onEdit={() => navigate(routes.heritageObjectives.path)}
            />
          </div>
          <div className="cards-premium cp-emprunt order-3 xl_2xl:order-2">
            <CardContent
              type="loan"
              data={{
                title: "Capacité d’emprunt",
                price: userSettings?.loanDetails?.loanCapacity,
                percent: userSettings?.loanDetails?.debtRate,
              }}
              userLoanLiquidity={{
                spending: userSettings?.spending,
                annualIncome: userSettings?.annualIncome,
                savingsPerYear: userSettings?.savingsPerYear,
                monthlyRepayment: userSettings?.monthlyRepayment,
              }}
              showPercentage
              showCta={!hasLoan}
              onClick={() => !hasLoan && setOpen("loan")}
              {...(hasLoan && {
                onEdit: () => setOpen("loan"),
              })}
              info="Montant de l’ensemble des frais adossés à votre emprunt (intérêts, assurance, dossier...)"
            />
          </div>
          <div className="cards-premium order-2 xl_2xl:order-3">
            <RangedCard
              type="investor"
              showComplete={showInvestorComplete}
              firstRange={investorRisk !== null ? 100 - investorRisk : 100}
              secondRange={
                master[
                  userSettings?.investorProfile
                    ?.experience as InverstorProfileExperience
                ] || 0
              }
              onEdit={() => navigate(routes.other.path)}
            />
          </div>
          <div className="cards-premium lv-liquidite order-4">
            <CardContent
              data={{
                title: "Niveau de liquidité",
                price: userSettings?.availableLiquidity,
              }}
              monthlySpend={
                userSettings?.availableLiquidity !== undefined &&
                userSettings.spending
                  ? userSettings?.availableLiquidity / userSettings.spending
                  : false
              }
              info="L’ensemble de vos actifs disponibles sans frais de déblocage"
              type="liquid"
            />
          </div>
        </div>
        <div className="grid auto-rows-fr grid-cols-1 gap-y-3">
          <CardContent
            data={{
              title: "Simulation de votre patrimoine - Coming Soon",
              price: 0,
            }}
            type="monteCarlo"
          />
          <CardContent
            className={hasComputeRetirement ? "" : "w-full"}
            onClick={() => toggleOpen("retirement")}
            data={{
              title: (
                <>
                  <Typo
                    type="wlc_inter_xl"
                    children="Simulation de vos revenus retraite"
                    className="bold mb-2 text-base font-normal text-primary-500"
                  />
                  <Typo type="wlc_inter_l">
                    {hasComputeRetirement ? (
                      <div>
                        Dans votre situation actuelle, vos revenus à la retraite
                        seront de :{" "}
                        <span className="text-_21 font-extrabold text-primary-500">
                          {numberFormat(userSettings.retirementSimulation / 12)}{" "}
                          / mois
                        </span>
                      </div>
                    ) : (
                      "Grâce à un simulateur développé par Wealthcome, renseignez votre historique d’activité pour connaître une estimation de vos revenus à la retraite."
                    )}
                  </Typo>
                </>
              ),

              price: 0,
            }}
            type="retirement"
          />
        </div>
      </div>
      <Modal open={open === "loan"} setOpen={setOpen} className="w-1/2 p-10">
        <div>
          <FormBuilder
            defaultRequired
            {...loanFormItems}
            onSubmit={onSubmit}
            action={() => (
              <>
                <button
                  className="text-secondary mx-4 items-center truncate text-clip rounded-md border border-transparent bg-primary-100 px-4 py-2 font-medium focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  type="submit"
                >
                  Envoyer
                </button>
              </>
            )}
          />
        </div>
      </Modal>
      {
        <Modal
          open={open === "retirement"}
          setOpen={setOpen}
          className="w-1/2 p-10"
        >
          <div>
            <FormBuilder
              defaultRequired
              {...formItems}
              defaultValues={userSettings?.retirementForm}
              onSubmit={onSubmit}
              action={() => (
                <>
                  <button type="submit">Envoyer</button>
                </>
              )}
            />
          </div>
        </Modal>
      }
    </div>
    <div className="mt-7 flex flex-col gap-3 md_lg:flex-row">
      <Table
        label="widget.coast"
        Right={
          <>
            Nous mettons en évidence vos frais cachés (bancaires ou
            d’investissements)
          </>
        }
        className="boxShadowCard sm_md:w-full md_lg:w-1/2"
        type="coast"
        data={feeAggregation?.map((item) => ({
          id: item.key,
          title: item.name,
          price: Math.abs(item.value),
          percent: item.reprensentation * 100,
        }))}
      />
      <Table
        label="widget.weighted"
        totalPerformance={totalPerformance}
        className="boxShadowCard  w-full md_lg:w-1/2"
        type="weighted"
        data={weightData}
        Right={
          <>
            Elle correspond à la pondération des actifs responsables de votre
            plus-value globale
          </>
        }
      />
    </div>
  </div>
);
