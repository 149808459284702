import {
  PoolType,
  Direction,
  GardenType,
  AppartmentType,
  RealEstateView,
  BuildingStanding,
  AppartmentCondition,
  CondominiumCondition,
  RealEstateAmenitiesProximity,
} from "@types";
import { action } from "./commons";
import { ManualFormFields } from "./manual";
import { NormalizeTranslationKeys } from "i18n";
import { GoogleAutoComplete } from "./googleAutoComplete";

export const realEstate: ManualFormFields<NormalizeTranslationKeys> = {
  defaultRequired: true,
  items: [
    {
      className: "grid md:grid-cols-3 grid-cols-1",
      children: [
        {
          name: "name",
          label: "forms.realEstate.name.label",
          placeholder: "forms.realEstate.name.placeholder",
        },
        {
          name: "geoloc",
          type: "custom",
          element: ({ register, setValue }) => (
            <GoogleAutoComplete
              type="text"
              name="geoloc"
              label="forms.realEstate.geoloc.placeholder"
              required={true}
              setValue={setValue}
              register={register}
              className="rounded-xl border border-primary-100 bg-red-400 shadow-md invalid:border-pink-500 disabled:border-slate-200 disabled:shadow-none"
            />
          ),
        },
        {
          name: "price",
          label: "forms.realEstate.price.label",
          placeholder: "forms.realEstate.price.placeholder",
          type: "number",
        },
        {
          name: "buyingDate",
          label: "forms.realEstate.buyingDate.label",
          placeholder: "forms.realEstate.buyingDate.placeholder",
          type: "date",
        },
        {
          name: "ownership",
          type: "number",
          label: "forms.realEstate.ownership.label",
          placeholder: "forms.realEstate.ownership.placeholder",
          min: 0,
          max: 100,
        },
      ],
    },
    {
      children: [
        {
          name: "typeId",
          type: "radio",
          label: "forms.realEstate.typeId.label",

          inline: true,
          options: [
            {
              label: "forms.realEstate.typeId.options.SIMPLEX.label",
              value: AppartmentType.SIMPLEX,
            },
            {
              label: "forms.realEstate.typeId.options.HOUSE.label",
              value: AppartmentType.HOUSE,
            },
          ],
        },
      ],
    },
    {
      conditionalRender: (formData) =>
        formData.typeId === AppartmentType.SIMPLEX,
      className: "grid md:grid-cols-3 grid-cols-1",
      children: [
        {
          name: "area",
          label: "forms.realEstate.area.placeholder",
          placeholder: "forms.realEstate.area.placeholder",
          type: "number",
          sufix: "m²",
        },
        {
          name: "rooms",
          label: "forms.realEstate.rooms.placeholder",
          placeholder: "forms.realEstate.rooms.placeholder",
          type: "number",
          sufix: "pièce(s)",
        },
        {
          name: "bathrooms",
          label: "forms.realEstate.bathrooms.placeholder",
          placeholder: "forms.realEstate.bathrooms.placeholder",
          type: "number",
          sufix: "salle(s)",
        },
        {
          name: "bedrooms",
          label: "forms.realEstate.bedrooms.placeholder",
          placeholder: "forms.realEstate.bedrooms.placeholder",
          type: "number",
          sufix: "chambre(s)",
        },
        {
          name: "floor",
          label: "forms.realEstate.floor.placeholder",
          placeholder: "forms.realEstate.floor.placeholder",
          type: "number",
          sufix: "étage",
        },
        {
          name: "buildingFloors",
          label: "forms.realEstate.buildingFloors.placeholder",
          placeholder: "forms.realEstate.buildingFloors.placeholder",
          type: "number",
          sufix: "étage(s)",
        },
        {
          name: "parkingPlots",
          label: "forms.realEstate.parkingPlots.placeholder",
          placeholder: "forms.realEstate.parkingPlots.placeholder",
          type: "number",
          sufix: "parking(s)",
        },
        {
          name: "areaBalcony",
          label: "forms.realEstate.areaBalcony.placeholder",
          placeholder: "forms.realEstate.areaBalcony.placeholder",
          type: "number",
          sufix: "m²",
        },
      ],
    },
    {
      conditionalRender: (formData) =>
        formData.typeId === AppartmentType.SIMPLEX,
      className: "grid md:grid-cols-3 grid-cols-1",
      children: [
        {
          name: "condition",
          type: "radio",
          label: "forms.realEstate.condition.label",
          options: [
            {
              value: AppartmentCondition.REDONE,
              label: "forms.realEstate.condition.options.REDONE.label",
            },
            {
              value: AppartmentCondition.REFRESHED,
              label: "forms.realEstate.condition.options.REFRESHED.label",
            },
            {
              value: AppartmentCondition.STANDARD,
              label: "forms.realEstate.condition.options.STANDARD.label",
            },
            {
              value: AppartmentCondition.TO_REFRESH,
              label: "forms.realEstate.condition.options.TO_REFRESH.label",
            },
            {
              value: AppartmentCondition.IMPORTANT_WORK,
              label: "forms.realEstate.condition.options.IMPORTANT_WORK.label",
            },
          ],
        },
        {
          name: "buildingStanding",
          type: "radio",
          label: "forms.realEstate.buildingStanding.label",
          options: [
            {
              value: BuildingStanding.PREMIUM,
              label: "forms.realEstate.buildingStanding.options.PREMIUM.label",
            },
            {
              value: BuildingStanding.GOOD,
              label: "forms.realEstate.buildingStanding.options.GOOD.label",
            },
            {
              value: BuildingStanding.NORMAL,
              label: "forms.realEstate.buildingStanding.options.NORMAL.label",
            },
            {
              value: BuildingStanding.LOW,
              label: "forms.realEstate.buildingStanding.options.LOW.label",
            },
          ],
        },
        {
          name: "condominiumCondition",
          type: "radio",
          label: "forms.realEstate.condominiumCondition.label",
          options: [
            {
              value: CondominiumCondition.WELL_MAINTAINED,
              label:
                "forms.realEstate.condominiumCondition.options.WELL_MAINTAINED.label",
            },
            {
              value: CondominiumCondition.STANDARD,
              label:
                "forms.realEstate.condominiumCondition.options.STANDARD.label",
            },
            {
              value: CondominiumCondition.NEGLECTED,
              label:
                "forms.realEstate.condominiumCondition.options.NEGLECTED.label",
            },
          ],
        },
        {
          name: "orientation",
          type: "radio",
          label: "forms.realEstate.orientation.label",
          options: [
            {
              value: Direction.NORTH,
              label: "forms.realEstate.orientation.options.NORTH.label",
            },
            {
              value: Direction.EAST,
              label: "forms.realEstate.orientation.options.EAST.label",
            },
            {
              value: Direction.SOUTH,
              label: "forms.realEstate.orientation.options.SOUTH.label",
            },
            {
              value: Direction.WEST,
              label: "forms.realEstate.orientation.options.WEST.label",
            },
          ],
        },
        {
          name: "vista",
          type: "radio",
          label: "forms.realEstate.vista.label",
          options: [
            {
              value: RealEstateView.VIS_A_VIS,
              label: "forms.realEstate.vista.options.VIS_A_VIS.label",
            },
            {
              value: RealEstateView.OPEN_VIEW,
              label: "forms.realEstate.vista.options.OPEN_VIEW.label",
            },
            {
              value: RealEstateView.OUTSTANDING,
              label: "forms.realEstate.vista.options.OUTSTANDING.label",
            },
          ],
        },
        {
          name: "amenityProximity",
          type: "radio",
          label: "forms.realEstate.amenityProximity.label",
          options: [
            {
              value: RealEstateAmenitiesProximity.VERY_DISTANT,
              label:
                "forms.realEstate.amenityProximity.options.VERY_DISTANT.label",
            },
            {
              value: RealEstateAmenitiesProximity.DISTANT,
              label: "forms.realEstate.amenityProximity.options.DISTANT.label",
            },
            {
              value: RealEstateAmenitiesProximity.STANDARD,
              label: "forms.realEstate.amenityProximity.options.STANDARD.label",
            },
            {
              value: RealEstateAmenitiesProximity.CLOSE,
              label: "forms.realEstate.amenityProximity.options.CLOSE.label",
            },
            {
              value: RealEstateAmenitiesProximity.VERY_CLOSE,
              label:
                "forms.realEstate.amenityProximity.options.VERY_CLOSE.label",
            },
          ],
        },
      ],
    },
    {
      conditionalRender: (formData) => formData.typeId === AppartmentType.HOUSE,
      className: "grid md:grid-cols-3 grid-cols-1",
      children: [
        {
          name: "area",
          label: "forms.realEstate.area.placeholder",
          placeholder: "forms.realEstate.area.placeholder",
          type: "number",
          sufix: "m²",
        },
        {
          name: "areaLand",
          label: "forms.realEstate.areaLand.placeholder",
          placeholder: "forms.realEstate.areaLand.placeholder",
          type: "number",
          sufix: "m²",
        },
        {
          name: "floors",
          label: "forms.realEstate.floors.placeholder",
          placeholder: "forms.realEstate.floors.placeholder",
          type: "number",
        },
        {
          name: "rooms",
          label: "forms.realEstate.rooms.placeholder",
          placeholder: "forms.realEstate.rooms.placeholder",
          type: "number",
          sufix: "pièce(s)",
          required: true,
        },
        {
          name: "bathrooms",
          label: "forms.realEstate.bathrooms.placeholder",
          placeholder: "forms.realEstate.bathrooms.placeholder",
          type: "number",
          sufix: "salle(s)",
          required: true,
        },
        {
          name: "bedrooms",
          label: "forms.realEstate.bedrooms.placeholder",
          placeholder: "forms.realEstate.bedrooms.placeholder",
          type: "number",
          sufix: "chambre(s)",
        },
        {
          name: "parkingPlots",
          label: "forms.realEstate.parkingPlots.placeholder",
          placeholder: "forms.realEstate.parkingPlots.placeholder",
          type: "number",
          sufix: "parking(s)",
        },
        {
          name: "areaBalcony",
          label: "forms.realEstate.areaBalcony.placeholder",
          placeholder: "forms.realEstate.areaBalcony.placeholder",
          type: "number",
          sufix: "m²",
          required: true,
        },
      ],
    },
    {
      conditionalRender: (formData) => formData.typeId === AppartmentType.HOUSE,
      className: "grid md:grid-cols-3 grid-cols-1",
      children: [
        {
          name: "pool",
          type: "radio",
          label: "forms.realEstate.pool.label",
          options: [
            {
              label: "forms.realEstate.pool.options.NONE.label",
              value: PoolType.NONE,
            },
            {
              label: "forms.realEstate.pool.options.STANDARD.label",
              value: PoolType.STANDARD,
            },
            {
              label: "forms.realEstate.pool.options.STANDING.label",
              value: PoolType.STANDING,
            },
          ],
        },
        {
          name: "garden",
          type: "radio",
          label: "forms.realEstate.garden.label",
          options: [
            {
              label: "forms.realEstate.garden.options.LANDSCAPE.label",
              value: GardenType.LANDSCAPE,
            },
            {
              label: "forms.realEstate.garden.options.LANDSCAPED.label",
              value: GardenType.LANDSCAPED,
            },
            {
              label: "forms.realEstate.garden.options.STANDARD_NONE.label",
              value: GardenType.STANDARD_NONE,
            },
            {
              label: "forms.realEstate.garden.options.TO_BE_LANDSCAPED.label",
              value: GardenType.TO_BE_LANDSCAPED,
            },
          ],
        },
        {
          name: "buildingStanding",
          type: "radio",
          label: "forms.realEstate.houseStanding.label",
          options: [
            {
              label: "forms.realEstate.houseStanding.options.PREMIUM.label",
              value: BuildingStanding.PREMIUM,
            },
            {
              label: "forms.realEstate.houseStanding.options.GOOD.label",
              value: BuildingStanding.GOOD,
            },
            {
              label: "forms.realEstate.houseStanding.options.NORMAL.label",
              value: BuildingStanding.NORMAL,
            },
            {
              label: "forms.realEstate.houseStanding.options.LOW.label",
              value: BuildingStanding.LOW,
            },
          ],
          required: true,
        },
        {
          name: "condition",
          type: "radio",
          label: "forms.realEstate.condition.label",
          options: [
            {
              label: "forms.realEstate.condition.options.REDONE.label",
              value: AppartmentCondition.REDONE,
            },
            {
              label: "forms.realEstate.condition.options.REFRESHED.label",
              value: AppartmentCondition.REFRESHED,
            },
            {
              label: "forms.realEstate.condition.options.STANDARD.label",
              value: AppartmentCondition.STANDARD,
            },
            {
              label: "forms.realEstate.condition.options.TO_REFRESH.label",
              value: AppartmentCondition.TO_REFRESH,
            },
            {
              label: "forms.realEstate.condition.options.IMPORTANT_WORK.label",
              value: AppartmentCondition.IMPORTANT_WORK,
            },
          ],
          required: true,
        },
        {
          name: "orientation",
          type: "radio",
          label: "forms.realEstate.orientation.label",
          options: [
            {
              label: "forms.realEstate.orientation.options.NORTH.label",
              value: Direction.NORTH,
            },
            {
              label: "forms.realEstate.orientation.options.EAST.label",
              value: Direction.EAST,
            },
            {
              label: "forms.realEstate.orientation.options.SOUTH.label",
              value: Direction.SOUTH,
            },
            {
              label: "forms.realEstate.orientation.options.WEST.label",
              value: Direction.WEST,
            },
          ],
        },
        {
          name: "vista",
          type: "radio",
          label: "forms.realEstate.vista.label",
          options: [
            {
              label: "forms.realEstate.vista.options.VIS_A_VIS.label",
              value: RealEstateView.VIS_A_VIS,
            },
            {
              label: "forms.realEstate.vista.options.OPEN_VIEW.label",
              value: RealEstateView.OPEN_VIEW,
            },
            {
              label: "forms.realEstate.vista.options.OUTSTANDING.label",
              value: RealEstateView.OUTSTANDING,
            },
          ],
          required: true,
        },
        {
          name: "amenityProximity",
          type: "radio",
          label: "forms.realEstate.amenityProximity.label",
          options: [
            {
              label:
                "forms.realEstate.amenityProximity.options.VERY_DISTANT.label",
              value: RealEstateAmenitiesProximity.VERY_DISTANT,
            },
            {
              label: "forms.realEstate.amenityProximity.options.DISTANT.label",
              value: RealEstateAmenitiesProximity.DISTANT,
            },
            {
              label: "forms.realEstate.amenityProximity.options.STANDARD.label",
              value: RealEstateAmenitiesProximity.STANDARD,
            },
            {
              label: "forms.realEstate.amenityProximity.options.CLOSE.label",
              value: RealEstateAmenitiesProximity.CLOSE,
            },
            {
              label:
                "forms.realEstate.amenityProximity.options.VERY_CLOSE.label",
              value: RealEstateAmenitiesProximity.VERY_CLOSE,
            },
          ],
          required: true,
        },
      ],
    },
  ],
  mode: "onChange",
  action,
  defaultValues: {
    areaBalcony: 0,
    areaTerrace: 0,
    parkingPlots: 0,
    ownership: 100,
  },
  formFormatter: ({ geoloc, ...realEstate }) => ({
    realEstate: {
      ...realEstate,
      ...geoloc,
      ownership: realEstate.ownership / 100,
      name: realEstate.name,
    },
  }),
};
