import { VFC } from "react";
import { gql } from "graphql-request";
import { FieldValues } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { Page } from "@types";
import { Widget } from "components";
import { client } from "core/queries/makeGQLQuery";
import { submitInverstorProfileForm } from "core/queries/mutation";
import { InvestorProfileForm } from "./components/investorProfileForm/investorProfileForm";

export const InverstorProfileFormScreen: VFC<Page> = ({ navigate }) => {
  const queryClient = useQueryClient();
  const investorProfileForm = useQuery("investorProfileForm", () =>
    client
      .request(
        gql`
          query GetInvestorProfileForm {
            getUserInvestorProfileForm
          }
        `
      )
      .then((res) => res.getUserInvestorProfileForm as any)
  );

  const submitMutation = useMutation<unknown, unknown, { input: object }>(
    submitInverstorProfileForm,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("user");
        queryClient.invalidateQueries("investorProfileForm");
        navigate?.(-1);
      },
    }
  );

  const onSubmit = async (input: FieldValues) => {
    submitMutation.mutate({ input });
  };

  if (investorProfileForm.isLoading) {
    return null;
  }

  return (
    <Widget label="widget.investorProfileQuestions" className="h-fit w-full">
      <InvestorProfileForm
        defaultValue={investorProfileForm.data}
        onSubmit={onSubmit}
      />
    </Widget>
  );
};
