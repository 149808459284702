import { AssetType } from "@types";

const assetTypeColor: Record<
  AssetType,
  {
    color: string;
    backgroundColor: string;
  }
> = {
  bankAccount: {
    color: "#6938EF",
    backgroundColor: "#EDE7FD",
  },
  stocks: { color: "#1570EF", backgroundColor: "#E7F0FD" },
  crypto: { color: "#F79009", backgroundColor: "#FEF4E6" },
  lifeInsurance: {
    color: "#22A06B",
    backgroundColor: "#EAFBF4",
  },
  economies: {
    color: "#EC4A0A",
    backgroundColor: "#FEEDE7",
  },
  privateCivilRealEstate: {
    color: "#1D9AAA",
    backgroundColor: "#E9F9FB",
  },
  crowdfunding: {
    color: "#DD2590",
    backgroundColor: "#FCE9F4",
  },
  exotic: { color: "#50843E", backgroundColor: "#F0F7EE" },
  realEstate: {
    color: "#F04438",
    backgroundColor: "#FDE9E7",
  },
  loan: { color: "#9067E5", backgroundColor: "#EFE8FC" },
  retirement: {
    color: "#AB6D10",
    backgroundColor: "#F0DFCB",
  },

  // Savings
  savings: { color: "#F79009", backgroundColor: "#FEF4E6" },
};

export function getAssetTypeColor(AssetType?: AssetType) {
  const defaultColors = { color: "#4761c8", backgroundColor: "#e8eefc" };

  if (!AssetType) return defaultColors;
  const colors = assetTypeColor[AssetType];

  return colors || defaultColors;
}
