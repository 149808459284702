import { FieldValues } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQueryClient, useMutation } from "react-query";

import PremiumCard from "./PremiumCard";
import { Modal } from "components/Modal";
import { FormBuilder } from "components/formBuilder";
import { useUser } from "core/helpers/hooks/useUser";
import { loanFormItems } from "screens/private/other/premium/commons";
import { evolutionAmountFormatterOptions } from "components/localization";
import { numberFormat, saveAction, setLoanDetailsMutation } from "../../core";
import { useAssetActions } from "screens/private/assetById/components/assets/AssetActions/AssetActions";

const LoanCapacity = () => {
  const { render, state, dispatch } = useAssetActions(["edit"]);
  const { t } = useTranslation();
  const { data: user } = useUser();

  if (!user) {
    return null;
  }

  const loanCapacityPercent =
    user.monthlyRepayment && user.annualIncome
      ? user.monthlyRepayment / user.annualIncome
      : 0;
  const loanCapacityPrice =
    user.monthlyRepayment && user.annualIncome
      ? ((user.annualIncome - user.monthlyRepayment) * 35) / 100
      : 0;
  const loanCapacityAmount = (loanCapacityPrice * 100000) / 525;

  return (
    <>
      <PremiumCard title="loanCapacity.title" type="gradient" Right={render}>
        {!user?.loanDetails ? (
          <div>
            <p className="text-reversedContent-primary mt-3 font-sans text-base font-bold">
              {numberFormat(loanCapacityPercent * 100, {
                ...evolutionAmountFormatterOptions,
                //   showCurrency: false,
              })}
            </p>

            <p className="text-content-primary mt-3 font-sans text-xs font-medium">
              {t("loanCapacity.subtitle")}
            </p>

            <p className="text-reversedContent-primary mt-3 font-sans text-2xl font-bold">
              {numberFormat(loanCapacityAmount)}
            </p>
          </div>
        ) : null}
      </PremiumCard>
      <Modal open={!!state.edit} setOpen={() => dispatch({ type: "edit" })}>
        <LoanCapacityForm onClose={() => dispatch({ type: "edit" })} />
      </Modal>
    </>
  );
};

const LoanCapacityForm = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  const { data: user } = useUser();
  const queryClient = useQueryClient();

  const submitMutation = useMutation<unknown, unknown, FieldValues>(
    setLoanDetailsMutation,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["user"]);
        onClose();
      },
    }
  );

  const onSubmit = async (formValues: FieldValues) => {
    submitMutation.mutate(formValues);
  };

  return (
    <div className="px-4">
      <FormBuilder
        props={{ t }}
        {...loanFormItems}
        defaultValues={{
          annualIncome: user?.annualIncome,
          monthlyRepayment: user?.monthlyRepayment,
          spending: user?.spending,
          savingsPerYear: user?.savingsPerYear,
        }}
        action={saveAction}
        onSubmit={onSubmit}
      />
    </div>
  );
};

export default LoanCapacity;
