import { Data, ITable } from "./page";

// Card content
export const CardContentObj = {
  loan: "loan",
  liquid: "liquid",
  wallet: "wallet",
  monteCarlo: "monteCarlo",
  retirement: "retirement",
};
export type CardContentType = keyof typeof CardContentObj;
export const CardContentKeys = Object.keys(CardContentObj) as CardContentType[];

export interface UserLoanLiquidityType {
  spending: number;
  annualIncome: number;
  savingsPerYear: number;
  monthlyRepayment: number;
}
export interface CardContentDetailProps {
  data: Data;
  type: CardContentType;
  showPercentage?: boolean;
  showCta?: boolean;
  userLoanLiquidity?: UserLoanLiquidityType;
  displayValue?: "gain" | "percent";
}
export interface CardContentProps extends Omit<CardContentDetailProps, "type"> {
  to?: string;
  info?: string | JSX.Element;
  icon?: string;
  isHome?: boolean;
  className?: string;
  comingSoon?: boolean;
  type?: CardContentType;
  monthlySpend?: number | false;
  onClick?: () => void;
  onEdit?: (type: CardContentType) => void;
  handleDelete?: ITable["handleDelete"];
  handleSelection?: ITable["handleSelection"];
}

// Range Card
export const RangeCardTypeObj = {
  wealth: "wealth",
  investor: "investor",
};
export type RangeCardType = keyof typeof RangeCardTypeObj;
export const RangeCardKeys = Object.keys(RangeCardTypeObj) as RangeCardType[];
export interface RangeCardProps {
  type: RangeCardType;
  firstRange: number;
  secondRange: number;
  className?: string;
  showComplete?: boolean;
  comingSoon?: boolean;
  info?: string | JSX.Element;
  onEdit?: () => void;
}
