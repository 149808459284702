import { useQuery } from "react-query";
import { useState, useMemo, useEffect } from "react";
import { useParams } from "react-router-dom";

import { AssetType } from "@types";
import { PeriodControl } from "components";
import LoanView from "./components/assets/LoanView";
import { PerioActivityChart } from "components/charts";
import ExoticView from "./components/assets/ExoticView";
import SavingView from "./components/assets/EconomyView";
import { rangeForPeriod } from "core/helpers/convinients";
import { getAssetById } from "./components/assets/Assets.logic";
import RealEstateView from "./components/assets/RealEstateView";
import RetirementView from "./components/assets/RetirementView";
import BankAccountView from "./components/assets/BankAccountView";
import CrowdfundingView from "./components/assets/CrowdfundingView";
import { useAppSelector, setHeader, useAppDispatch } from "_redux";
import LifeInsuranceView from "./components/assets/LifeInsuranceView";
import { PremiumPlaceHolderList } from "components/premiumPlaceholder";
import PlatformAccountView from "./components/assets/PlatformAccountView";
import PrivateCivilRealEstateView from "./components/assets/PrivateCivilRealEstateView";

export const AssetViewById = () => {
  const params = useParams();

  const [featureComponent, setFeatureComponent] = useState<React.ReactNode[]>(
    []
  );

  const { type, id } = params as { type: AssetType; id: string };

  const period = useAppSelector((state) => state.period.value);
  const dispatch = useAppDispatch();

  const range = rangeForPeriod(period);

  const assetQuery = useQuery(["asset", id, period], () =>
    getAssetById(id, range.start, range.end)
  );

  const periodActivities = useMemo(() => {
    return assetQuery.data?.valuationInTime.map((value) => {
      return {
        amount: { value: value.value, instrument: value.instrument },
        date: new Date(value.start),
      };
    });
  }, [assetQuery.data]);

  const [visibleTransactionIndex] = useState<number>(-1);
  const [, setBalance] = useState<{
    date: string;
    balance: number;
  } | null>(null);

  const assetView = useMemo(() => {
    return (
      <AssetView
        id={id}
        type={type}
        visibleTransactionIndex={visibleTransactionIndex}
        initialBalance={assetQuery.data?.currentActivity?.value || 0}
        setBalance={setBalance}
        setFeatureComponent={setFeatureComponent}
      />
    );
  }, [
    id,
    type,
    visibleTransactionIndex,
    assetQuery.data?.currentActivity?.value,
  ]);

  useEffect(() => {
    if (!assetQuery.data) return;

    dispatch(
      setHeader({
        title: assetQuery.data.name,
      })
    );
  }, [assetQuery.data]);

  return (
    <>
      <div className="h-full w-full">
        <div className="flex h-full w-full flex-col gap-x-9 sm_md:flex-row">
          <div className="flex w-full flex-col gap-y-9 sm_md:w-1/3">
            {assetView}
          </div>
          <div className="flex w-full flex-col justify-between gap-y-6 sm_md:w-2/3">
            <div>
              <PerioActivityChart
                dataset={periodActivities}
                hasDecimal={false}
              />
              {period != undefined && <PeriodControl />}
            </div>
            <div className="mt-4 flex-1">
              <PremiumPlaceHolderList list={featureComponent} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

interface AssetViewProps {
  type: AssetType;
  id: string;
  initialBalance: number;
  visibleTransactionIndex: number;
  setBalance: React.Dispatch<
    React.SetStateAction<{
      date: string;
      balance: number;
    } | null>
  >;
  setFeatureComponent: React.Dispatch<React.SetStateAction<React.ReactNode[]>>;
}

function AssetView({
  id,
  type,
  initialBalance,
  visibleTransactionIndex,
  setBalance,
  setFeatureComponent,
}: AssetViewProps) {
  switch (type) {
    case AssetType.bankAccount:
      return (
        <BankAccountView
          id={id}
          shownTransactionIndex={visibleTransactionIndex}
          initialBalance={initialBalance}
          setDynamicBalance={setBalance}
        />
      );

    case AssetType.crypto:
    case AssetType.stocks:
      return (
        <PlatformAccountView
          id={id}
          type={type}
          setFeatureComponent={setFeatureComponent}
        />
      );

    case AssetType.lifeInsurance:
      return <LifeInsuranceView id={id} />;

    case AssetType.economies:
      return <SavingView id={id} />;

    case AssetType.realEstate:
      return <RealEstateView id={id} />;

    case AssetType.retirements:
      return <RetirementView id={id} />;

    case AssetType.crowdfunding:
      return <CrowdfundingView id={id} />;

    case AssetType.loan:
      return <LoanView id={id} />;

    case AssetType.exotic:
      return <ExoticView id={id} />;

    case AssetType.privateCivilRealEstate:
      return <PrivateCivilRealEstateView id={id} />;

    default:
      return null;
  }
}
