import { createSlice } from "@reduxjs/toolkit";

export const appReducer = createSlice({
  name: "app",
  initialState: {
    value: 0,
    currentRoute: "/",
  },
  reducers: {
    setCurrentRoute: (state, action) => {
      state.currentRoute = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCurrentRoute } = appReducer.actions;

export default appReducer.reducer;
