import { AssetType } from "@types";
import { RouteType } from "core";
import {
  loanUrl,
  exoticsUrl,
  economiesUrl,
  retirementUrl,
  bankAccountUrl,
  realEstatesUrl,
  crowdfundingUrl,
  lifeInsurancesUrl,
  stockPlatformAccountsUrl,
  privateCivilRealEstateUrl,
  cryptoPlatformAccountsUrl,
} from "core/queries";
import { privateRoute } from "./privateRoute";
import { NormalizeTranslationKeys } from "i18n";

export interface RouteListType {
  path: string;
  title?: string;
  menuName: string;
  menuClassName?: string;
  menuDisabled?: boolean;
  label: NormalizeTranslationKeys;
  description?: NormalizeTranslationKeys;
  query: <T>(
    variables?: any | undefined
  ) => (() => Promise<T | void | unknown>) | Promise<T | void | unknown> | void;
  type: AssetType;
  key: string;
  customKey: string;
  sign: string;
  menuIcon: string;
  menuVisibility?: RouteType["menuVisibility"];
  color?: string;
  suffix?: string;
}

const defaultRoute: RouteListType = {
  path: "/",
  menuName: "",
  label: "" as NormalizeTranslationKeys,
  customKey: "",
  query: () => {
    return;
  },
  type: AssetType.bankAccount,
  key: "",
  sign: "",
  menuIcon: "",
  color: "",
  suffix: "",
};

export const routes: Record<string, RouteListType> = {
  home: {
    ...defaultRoute,
    key: "home",
    title: "patrimony",
    path: "/",
    menuIcon: "home",
    label: "sidebar.global_assets.title",
  },
  login: {
    ...defaultRoute,
    key: "login",
    path: "/login",
  },
  register: {
    ...defaultRoute,
    key: "register",
    path: "/register",
  },
  forgotPassword: {
    ...defaultRoute,
    key: "forgotPassword",
    path: "/forgotPassword",
  },
  bankAccount: {
    ...defaultRoute,
    title: "bankAccount",
    path: "/bankAccount",
    menuName: "bankAccount",
    label: "sidebar.bankAccount.title",
    query: bankAccountUrl,
    type: AssetType.bankAccount,
    description: "sidebar.bankAccount.description",
    key: "bankAccounts",
    sign: "€",
    menuIcon: "bankAccount",
    suffix: "bancaire",
  },
  stocks: {
    ...defaultRoute,
    title: "stocks",
    path: "/stocks",
    menuName: "stocks",
    label: "sidebar.stocks.title",
    description: "sidebar.stocks.description",
    query: stockPlatformAccountsUrl({ type: AssetType.stocks }),
    customKey: "platformAccounts",
    type: AssetType.stocks,
    key: AssetType.stocks,
    sign: "€",
    color: "rgb(188, 169, 122, 1)",
    menuIcon: "bourse",
    suffix: "bourse",
  },
  crypto: {
    ...defaultRoute,
    title: "crypto",
    path: privateRoute.crypto,
    menuName: "crypto",
    description: "sidebar.crypto.description",
    label: "sidebar.crypto.title",
    query: cryptoPlatformAccountsUrl({ type: AssetType.crypto }),
    type: AssetType.crypto,
    customKey: "platformAccounts",
    key: "crypto",
    sign: "€",
    menuIcon: "crypto",
    suffix: "crypto",
  },
  economies: {
    ...defaultRoute,
    path: "/economies",
    menuName: "economies",
    label: "sidebar.economies.title",
    description: "sidebar.economies.description",
    title: "economies",
    query: economiesUrl,
    type: AssetType.economies,
    key: "economies",
    sign: "€",
    color: "rgb(188, 169, 122, 1)",
    menuIcon: "epargne",
    suffix: "épargne",
  },
  lifeInsurance: {
    ...defaultRoute,
    path: privateRoute.lifeInsurance,
    menuName: "lifeInsurance",
    title: "lifeInsurance",
    label: "sidebar.lifeInsurance.title",
    description: "sidebar.lifeInsurance.description",
    query: lifeInsurancesUrl,
    type: AssetType.lifeInsurance,
    key: "lifeInsurance",
    customKey: "lifeInsurances",
    sign: "€",
    color: "rgb(188, 169, 122, 1)",
    menuIcon: "assurancevie",
    suffix: "assurance vie",
  },
  crowdfunding: {
    ...defaultRoute,
    path: privateRoute.crowdfunding,
    menuName: "crowdfunding",
    description: "sidebar.crowdfunding.description",
    label: "sidebar.crowdfunding.title",
    query: crowdfundingUrl,
    type: AssetType.crowdfunding,
    key: "crowdfunding",
    title: "crowdfunding",
    customKey: "crowdfundings",
    sign: "€",
    color: "rgb(188, 169, 122, 1)",
    menuIcon: "crowdfunding",
    suffix: "crowdfunding",
  },
  realEstate: {
    ...defaultRoute,
    path: privateRoute.realEstate,
    title: "realEstate",
    description: "sidebar.realEstate.description",
    menuName: "realEstate",
    label: "sidebar.realEstate.title",
    query: realEstatesUrl,
    type: AssetType.realEstate,
    key: "realEstate",
    customKey: "realEstates",
    sign: "€",
    color: "rgb(188, 169, 122, 1)",
    menuIcon: "immobilier",
    suffix: "immobilier",
  },
  singleRealEstate: {
    ...defaultRoute,
    title: "realEstate",
    path: privateRoute.singleRealEstate(),
    menuName: "",
    label: "" as NormalizeTranslationKeys,
    key: "realEstateDetails",
    sign: "€",
    color: "rgb(188, 169, 122, 1)",
    menuIcon: "star",
    menuVisibility: "never",
    menuDisabled: true,
    suffix: "",
  },
  singleSCPI: {
    ...defaultRoute,
    title: "privateCivilRealEstate",
    path: privateRoute.singleSCPI(),
    menuName: "",
    label: "" as NormalizeTranslationKeys,
    key: "scpi",
    sign: "€",
    color: "rgb(188, 169, 122, 1)",
    menuIcon: "star",
    menuVisibility: "never",
    menuDisabled: true,
    suffix: "",
  },
  privateCivilRealEstate: {
    ...defaultRoute,
    path: "/privateCivilRealEstate",
    title: "privateCivilRealEstate",
    menuName: "scpi",
    label: "sidebar.privateCivilRealEstate.title",
    query: privateCivilRealEstateUrl,
    type: AssetType.privateCivilRealEstate,
    key: "privateCivilRealEstate",
    customKey: "privateCivilRealestates",
    sign: "€",
    color: "rgb(188, 169, 122, 1)",
    menuIcon: "immobilier",
    suffix: "scpi",
  },
  retirement: {
    ...defaultRoute,
    path: "/retirement",
    menuName: "retirement",
    description: "sidebar.retirement.description",
    label: "sidebar.retirement.title",
    title: "retirement",
    query: retirementUrl,
    type: AssetType.retirements,
    key: "retirement",
    customKey: "retirements",
    sign: "€",
    color: "rgb(188, 169, 122, 1)",
    menuIcon: "retraite",
    suffix: "retraite",
  },
  exotic: {
    ...defaultRoute,
    path: "/exotic",
    menuName: "exotic",
    description: "sidebar.exotic.description",
    label: "sidebar.exotic.title",
    title: "exotic",
    query: exoticsUrl,
    type: AssetType.exotic,
    key: "exotic",
    customKey: "exotics",
    sign: "€",
    color: "rgb(188, 169, 122, 1)",
    menuIcon: "exotic",
    suffix: "",
  },
  loan: {
    ...defaultRoute,
    path: "/loan",
    menuName: "Emprunt",
    label: "sidebar.loan.title",
    title: "loan",
    description: "sidebar.loan.description",
    query: loanUrl,
    type: AssetType.loan,
    key: "loan",
    customKey: "loans",
    sign: "€",
    color: "rgb(188, 169, 122, 1)",
    menuIcon: "loan",
    suffix: "loan",
  },
  isr: {
    ...defaultRoute,
    path: privateRoute.incomeTaxes,
    menuName: "isr",
    label: "sidebar.green_product.title",
    title: "isr",
    query: bankAccountUrl,
    type: AssetType.crypto,
    // description:
    //   "Synchronisation des plus grands exchanges, ajout d'adresses ou manuel.",
    key: "isr",
    sign: "€",
    color: "rgb(188, 169, 122, 1)",
    menuIcon: "isr",
    menuDisabled: true,
    suffix: "ISR",
  },
  other: {
    ...defaultRoute,
    path: "/investorProfile",
    menuName: "investorProfile",
    label: "sidebar.other.title",
    query: bankAccountUrl,
    key: "other",
    sign: "€",
    color: "rgb(188, 169, 122, 1)",
    menuIcon: "autres",
    menuVisibility: "never",
    menuDisabled: true,
    suffix: "",
  },
  addWallet: {
    ...defaultRoute,
    path: "/addWallet",
    menuName: privateRoute.addWallet,
    label: "sidebar.asset_addition.title",
    query: bankAccountUrl,
    type: AssetType.crypto,
    key: "addWallet",
    sign: "€",
    color: "rgb(188, 169, 122, 1)",
    menuIcon: "autres",
    menuVisibility: "never",
    suffix: "",
  },
  heritageObjectives: {
    ...defaultRoute,
    path: privateRoute.heritageObjectives,
    menuName: "other",
    label: "sidebar.other.title",
    query: bankAccountUrl,
    type: AssetType.bankAccount,
    key: "heritageObjectives",
    sign: "€",
    color: "rgb(188, 169, 122, 1)",
    menuIcon: "autres",
    menuVisibility: "never",
    menuDisabled: true,
    suffix: "",
  },
  profile: {
    ...defaultRoute,
    title: "profile",
    path: "/profile",
    menuName: "",
    label: "" as NormalizeTranslationKeys,
    key: "profile",
    sign: "€",
    color: "rgb(188, 169, 122, 1)",
    menuIcon: "star",
    menuVisibility: "never",
    menuDisabled: true,
    suffix: "",
  },
  manually: {
    ...defaultRoute,
    title: "manually",
    path: "/manually",
    menuName: "",
    label: "" as NormalizeTranslationKeys,
    key: "manually",
    sign: "€",
    color: "rgb(188, 169, 122, 1)",
    menuIcon: "star",
    menuVisibility: "never",
    menuDisabled: true,
    suffix: "",
  },
  retool: {
    ...defaultRoute,
    title: "retool",
    path: "/retool",
    menuName: "",
    label: "" as NormalizeTranslationKeys,
    key: "retool",
    sign: "€",
    color: "rgb(188, 169, 122, 1)",
    menuIcon: "star",
    menuVisibility: "never",
    menuDisabled: true,
    suffix: "",
  },
  assetDetails: {
    ...defaultRoute,
    title: "asset",
    path: privateRoute.assetDetails(),
    menuName: "",
    label: "" as NormalizeTranslationKeys,
    key: "asset",
    sign: "€",
    color: "rgb(188, 169, 122, 1)",
    menuIcon: "star",
    menuVisibility: "never",
    menuDisabled: true,
  },
};
export type RoutesType = keyof typeof routes;
