import toast from "react-hot-toast";
import {
  useQuery,
  QueryKey,
  QueryFunction,
  UseQueryOptions,
} from "react-query";

const onError = (error: any) => {
  const has401Error =
    error?.response?.status === 401 ||
    error?.response?.errors?.find((e: any) => e.message == "Unauthorized");

  if (has401Error) toast.error(error.code);
};

export function useGQLQuery(
  queryKey: QueryKey,
  queryFn: QueryFunction,
  options: UseQueryOptions = {}
) {
  return useQuery<any>(queryKey, queryFn, {
    refetchOnWindowFocus: false,
    onError,
    ...options,
  });
}
