import { useEffect, useMemo, useState } from "react";

import { AreasChart } from "./areas/areas";
import { Amount, AssetType } from "@types";
import { SkeletonList } from "components/skeleton/skeletonList";
import { clsx, numberFormat } from "core";

export interface PeriodActivityDataset {
  amount: Amount;
  date: Date | string;
}
interface Props {
  className?: string;
  dataset?: PeriodActivityDataset[];
  hasDecimal?: boolean;
  assetType?: AssetType;
  hideLabels?: boolean;
}

export const PerioActivityChart = ({
  dataset,
  hasDecimal,
  className,
  assetType,
  hideLabels,
}: Props) => {
  const [isFirstLoading, setIsFirstLoading] = useState(true);

  useEffect(() => setIsFirstLoading(false), []);

  const data = useMemo(
    () =>
      dataset?.map((value) => ({
        x: new Date(value.date).getTime(),
        y: value.amount.value,
      })),
    [dataset]
  );

  const minAmount = useMemo(
    () =>
      dataset?.reduce(
        (min, p) => (p.amount.value < min ? p.amount.value : min),
        dataset[0]?.amount.value || 0
      ),
    [dataset]
  );

  const maxAmount = useMemo(
    () =>
      dataset?.reduce(
        (max, p) => (p.amount.value > max ? p.amount.value : max),
        dataset[0]?.amount.value || 0
      ),
    [dataset]
  );

  if (!data || !minAmount || !maxAmount) return <>Aucune données</>;

  const firstRange = ((maxAmount - minAmount) / 3) * 2 + minAmount;
  const secondRange = (maxAmount - minAmount) / 3 + minAmount;

  return (
    <div className={clsx("relative", className)}>
      {!hideLabels ? (
        <div className="absolute right-0 z-50 flex h-full flex-col items-end justify-between py-[22px] pr-1">
          <p className="text-content-tertiary font-sans text-xs font-medium">
            {numberFormat(maxAmount, {
              maximumFractionDigits: 0,
            })}
          </p>
          <p className="text-content-tertiary font-sans text-xs font-medium">
            {numberFormat(firstRange, {
              maximumFractionDigits: 0,
            })}
          </p>
          <p className="text-content-tertiary font-sans text-xs font-medium">
            {numberFormat(secondRange, {
              maximumFractionDigits: 0,
            })}
          </p>
          <p className="text-content-tertiary font-sans text-xs font-medium">
            {numberFormat(minAmount, {
              maximumFractionDigits: 0,
            })}
          </p>
        </div>
      ) : null}

      {isFirstLoading ? (
        <SkeletonList variant="BarChart" />
      ) : dataset?.length ? (
        <AreasChart
          hasDecimal={hasDecimal}
          series={dataset
            ?.filter((e) => e.date)
            .map(({ date, amount }) => ({
              x: new Date(date).getTime(),
              y: amount.value,
            }))}
          assetType={assetType}
        />
      ) : (
        <div>No data</div>
      )}
    </div>
  );
};
