import { useEffect } from "react";

export const useSetTimeout = (
  fn: (args?: any) => any,
  timeout = 1000,
  deps: any[] = []
) => {
  useEffect(() => {
    const timeOut = setTimeout(fn, timeout);

    return () => clearTimeout(timeOut);
  }, [fn, timeout, ...deps]);
};
