import { useState, VFC } from "react";
import { ChevronDownIcon } from "@heroicons/react/solid";

import { clsx, formatDate } from "core/helpers";
import { numberFormat } from "core/helpers/number";
import { Icon } from "components/Icon";

type OrderType = "asc" | "desc";
type OrderIconProps = {
  order: OrderType;
};
const OrderIcon: VFC<OrderIconProps> = ({ order }) => (
  <ChevronDownIcon
    className={`inline-block h-5 w-5 ${
      order === "asc" ? "rotate-0" : "rotate-180"
    }`}
  />
);

const heads = [
  {
    label: "Date",
    name: "date",
    className:
      "py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6",
  },
  {
    label: "Libellé",
    name: "label",
    className:
      "hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell",
  },
  {
    label: "Valeur",
    name: "amount",
    className:
      "relative py-3.5 pl-3 pr-4 sm:pr-6 text-right text-sm font-semibold text-gray-900",
  },
];
interface Props {
  type: string;
  datas: any;
  className?: string;
  showLabel?: boolean;
  handleDelete?: (id: string) => void;
}

const useSortTable = (props: Props) => {
  const type = props.type;
  const { datas, className, showLabel, handleDelete } = props;

  const [data, setData] = useState(datas);
  const [col, setCol] = useState("");
  const [order, setOrder] = useState<OrderType>("asc");

  const toggleOrder = () => setOrder(order === "asc" ? "desc" : "asc");

  const sorting = (col: string) => {
    if (col === "date") {
      if (order === "asc") {
        setData(
          data.sort((a: any, b: any) =>
            formatDate(a.date).localeCompare(formatDate(b.date))
          )
        );
      } else {
        setData(
          data.sort((a: any, b: any) =>
            formatDate(b.date).localeCompare(formatDate(a.date))
          )
        );
      }
    }
    if (col === "amount") {
      if (order === "asc")
        setData(data.sort((a: any, b: any) => a.amount - b.amount));
      else setData(data.sort((a: any, b: any) => b.amount - a.amount));
    }
    setCol(col);
    toggleOrder();
  };

  const onDelete = (id: string) => () => handleDelete?.(id);

  return {
    type,
    datas,
    data,
    col,
    order,
    className,
    showLabel,
    toggleOrder,
    setData,
    setCol,
    setOrder,
    sorting,
    handleDelete,
    onDelete,
  };
};

export function SorTable(props: Props) {
  const {
    type,
    data,
    col,
    order,
    className,
    showLabel,
    sorting,
    handleDelete,
    onDelete,
  } = useSortTable(props);

  return (
    <div className="flex max-h-[300px] w-full flex-col justify-between overflow-y-auto">
      <table
        className={clsx(
          "min-w-full table-auto border-collapse divide-y divide-gray-300 overflow-x-auto ",
          className
        )}
      >
        <thead className="sticky top-0 border-b-_1 border-gray-300 bg-gray-50">
          <tr>
            {heads
              .filter((e) => e.name !== "label" || showLabel)
              .map(({ name, label, className }, index) => (
                <th
                  key={index}
                  scope="col"
                  className={className}
                  onClick={() => sorting(name)}
                >
                  {label}
                  {col === name && <OrderIcon order={order} />}
                </th>
              ))}
            {/* {handleDelete && (
            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
              <span className="sr-only">Edit</span>
            </th>
          )} */}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {data.map((data: any) => (
            <tr
              className={clsx(
                type === "transactions" && data.amount > 0
                  ? "bg-sky-100"
                  : "bg-none"
              )}
            >
              <td className="w-full max-w-0 py-4 pl-4 pr-3 text-left text-sm font-medium text-gray-500 sm:w-auto sm:max-w-none sm:pl-6">
                {formatDate(data.date, "dd/MM/yyyy")}
                {showLabel && (
                  <dl className="font-normal lg:hidden">
                    <dt className="sr-only sm:hidden">Libellé</dt>
                    <dd className="mt-1 truncate text-left text-gray-500 sm:hidden">
                      {data.name.slice(0, 35)}
                    </dd>
                  </dl>
                )}
              </td>
              {showLabel && (
                <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                  {data.name.slice(0, 35)}
                </td>
              )}
              <td
                // className={`relative whitespace-nowrap pl-3  text-left _700:text-right text-sm font-medium right-12 w-1/2 ${
                //   showLabel ? "w-1/2" : "_700:w-full"
                // }`}
                className="break-normal py-4 pl-3 pr-4 text-right text-sm font-medium text-gray-500 sm:pr-6"
              >
                {numberFormat(data.amount)}
              </td>
              {handleDelete && (
                <td className="cursor-pointer py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                  <Icon
                    type="delete"
                    onClick={onDelete(data.id)}
                    className="h-3 w-3"
                  />
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
