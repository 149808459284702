import { isObj } from "core/helpers/check";
import SecureStorageService from "./SecureStorage";

export class SettingsService {
  static rememberMe = false;
  static key = "settings";

  /**
   * Rêcupère l'utilisateur connecté
   *
   * @returns {{id: number, uuid: string, email: string, name: string}} | null
   */
  static getSettings<Type>(): Type {
    const settings = SecureStorageService.getItem(this.key);
    if (!isObj(settings)) return {} as Type;

    return settings as Type;
  }

  static setSettings(setting: string, isSesssionStorage = false) {
    return SecureStorageService.setItem(this.key, setting, isSesssionStorage);
  }

  /**
   * Permet de supprimé le token
   *
   * @returns {void}
   */
  static removeSettings() {
    localStorage.removeItem(this.key);
    sessionStorage.removeItem(this.key);
  }
}
