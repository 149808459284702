import { useMemo } from "react";

import { AssetType } from "@types";
import { Container } from "components";
import { Row } from "components/AssetRow";

interface AssetPalmaresProps {
  assetType: AssetType;
  type: "top" | "flop";
  data?: {
    id: string;
    name: string;
    code: string;
    value: number;
    gain: number;
    evolution: number;
    logo: string;
  }[];
}

export function AssetPalmares({
  type = "top",
  data,
  assetType,
}: AssetPalmaresProps) {
  const filteredData = useMemo(() => {
    return (data ?? [])
      .filter((val) => (type === "top" ? val.evolution > 0 : val.evolution < 0))
      .sort((a, b) => {
        if (a.evolution > b.evolution) {
          return -1;
        } else if (a.evolution < b.evolution) {
          return 1;
        } else {
          return 0;
        }
      });
  }, [type, data]);

  return (
    <Container className="h-full grow p-0 pb-4">
      <div className="p-4">
        <div className="flex h-7 items-center justify-center gap-1.5 rounded-[40px] bg-stone-200 p-4">
          <div className="flex h-7 shrink grow basis-0 items-center justify-center gap-1.5 rounded-2xl px-3 py-1.5">
            <div className="text-center text-sm font-bold leading-none text-slate-600">
              {type === "top" ? "Top" : "Flop"}
            </div>
          </div>
        </div>
      </div>
      {filteredData.map((row) => {
        let src = undefined;

        if (assetType === AssetType.crypto && row.code) {
          src = `https://platforms-assets.s3.fr-par.scw.cloud/instruments/${row.code}.png`;
        } else if (assetType === AssetType.stocks && row.logo) {
          src = `https://platforms-assets.s3.fr-par.scw.cloud/instruments/${row.logo}`;
        }

        return (
          <Row
            key={row.id}
            id={row.id}
            type="evolution"
            assetType={assetType}
            title={row.name}
            value={row.value}
            gain={row.gain}
            evolution={row.evolution}
            Icon={
              <div className="flex h-11 w-11 flex-shrink-0 items-center justify-center rounded-full bg-[#F6F4F3]">
                {src ? <img src={src} className="h-5 w-5" /> : null}
              </div>
            }
          />
        );
      })}
    </Container>
  );
}
