import { Typo } from "components";
import Hypo from "core/helpers/math";
import { ManualForm } from "./manual";
import { SCPIListUrl } from "core/queries";
import { numberFormat } from "core/helpers";
import { FormBuilderProps, Option } from "@types";
import { action, genItem, options } from "./commons";
import { pick, separete } from "core/helpers/object";
import { NormalizeTranslationKeys } from "i18n";

export const othersForms: Partial<ManualForm<NormalizeTranslationKeys>> = {
  bankAccount: {
    items: [
      {
        className: "grid md:grid-cols-3 grid-cols-1",
        children: [
          {
            label: "forms.bankAccount.bank.label",
            name: "bank",
            required: true,
            placeholder: "forms.bankAccount.bank.placeholder",
          },
          {
            label: "forms.bankAccount.name.label",
            name: "name",
            required: true,
            placeholder: "forms.bankAccount.name.placeholder",
          },
          {
            name: "value",
            type: "number",
            label: "forms.bankAccount.value.label",
            placeholder: "forms.bankAccount.value.placeholder",
            step: "any",
            required: true,
          },
          {
            name: "instrument",
            type: "select",
            label: "forms.bankAccount.currency.label",
            options,
          },
          {
            name: "date",
            type: "date",
            label: "forms.bankAccount.date.label",
            required: true,
          },
        ],
      },
    ],
    action,
  },
  loan: {
    defaultRequired: true,
    mode: "onChange",
    items: [
      {
        className: "grid md:grid-cols-3 grid-cols-1",
        children: [
          {
            name: "name",
            label: "forms.loan.name.label",
            placeholder: "forms.loan.name.placeholder",
          },
          {
            name: "type",
            label: "forms.loan.type.label",
            type: "select",
            options: [
              {
                label: "forms.loan.type.options.amortizable",
                value: "amortissable",
              },
              { label: "forms.loan.type.options.endFine", value: "in-fine" },
              {
                label: "forms.loan.type.options.totalDeferred",
                value: "differe-total",
              },
              {
                label: "forms.loan.type.options.partialDeferred",
                value: "differe-partiel",
              },
            ],
          },
          {
            name: "start",
            type: "date",
            label: "forms.loan.start.label",
            placeholder: "forms.loan.start.placeholder",
            required: true,
          },
          {
            name: "value",
            type: "number",
            label: "forms.loan.value.label",
            placeholder: "forms.loan.value.placeholder",
            step: "any",
            required: true,
          },
          {
            name: "duration",
            label: "forms.loan.duration.label",
            placeholder: "forms.loan.duration.placeholder",
            type: "number",
          },

          // { name: "amount", type: "number", label: "Montant emprunté" },
          {
            name: "interests",
            type: "number",
            label: "forms.loan.interests.label",
            placeholder: "forms.loan.interests.placeholder",
          },
          {
            name: "assuranceFee",
            type: "number",
            label: "forms.loan.assuranceFee.label",
            placeholder: "forms.loan.assuranceFee.placeholder",
          },
          {
            name: "monthlyPayment",
            type: "number",
            label: "forms.loan.monthlyPayment.label",
            placeholder: "forms.loan.monthlyPayment.placeholder",
            calcValue: ({ value = 0, duration = 0, interests = 0 }) => {
              if (isNaN(value) || isNaN(duration) || isNaN(interests)) return;

              const t =
                Math.round(
                  (Math.pow(1 + interests / 100, 1 / 12) - 1) * 1000000
                ) / 1000000;
              const capital = Math.abs(value);
              const dec = 2;

              return Hypo.VPM(capital, duration, t, dec);
            },
          },
          // { name: "start", type: "date", label: "Durée totale de l'umprunt" },

          {
            name: "instrument",
            type: "select",
            label: "forms.loan.instrument.label",
            options,
          },
          {
            name: "amountLocked",
            type: "number",
            label: "forms.loan.amountLocked.label",
            placeholder: "forms.loan.amountLocked.placeholder",
          },
          {
            name: "applicationFee",
            type: "number",
            label: "forms.loan.applicationFee.label",
            placeholder: "forms.loan.applicationFee.placeholder",
          },
          {
            name: "total_amount",
            type: "custom",
            className: "flex items-end",
            element: ({ formValues }) => {
              const {
                duration,
                monthlyPayment,
                assuranceFee = 0,
                applicationFee = 0,
              } = formValues;

              if (isNaN(monthlyPayment) || isNaN(duration)) return <></>;

              return (
                <Typo
                  type="wlc_inter_l"
                  children={`Montant estimé de votre emprunt: ${numberFormat(
                    (assuranceFee + monthlyPayment) * duration + applicationFee
                  )}`}
                />
              );
            },
          },
        ],
      },
    ],
    defaultValues: {
      amountLocked: 100,
      assuranceFee: 0,
      applicationFee: 0,
    },
    action,
    formFormatter: (formValue) => {
      const [activity, loan] = separete(formValue, ["value", "instrument"]);

      return {
        loan,
        activity: {
          ...activity,
          date: loan.start,
        },
      };
    },
  },
  crowdfunding: {
    defaultRequired: true,
    items: [
      {
        className: "grid md:grid-cols-3 grid-cols-1",
        children: [
          { name: "name", label: "forms.crowdfunding.name.label" },
          {
            name: "providerName",
            label: "forms.crowdfunding.providerName.label",
          },
          {
            name: "investmentField",
            label: "forms.crowdfunding.investmentField.label",
          },
          {
            name: "subscribeDate",
            type: "date",
            label: "forms.crowdfunding.subscribeDate.label",
          },
          {
            name: "endDate",
            type: "date",
            label: "forms.crowdfunding.endDate.label",
          },
          {
            name: "efficiency",
            type: "number",
            label: "forms.crowdfunding.efficiency.label",
          },
          {
            name: "value",
            type: "number",
            label: "forms.crowdfunding.value.label",
            required: true,
          },
          {
            name: "instrument",
            type: "select",
            label: "forms.crowdfunding.instrument.label",
            options,
          },
        ],
      },
    ],
    action,
    formFormatter: (formValue) => {
      const [activity, crowdfunding] = separete(formValue, [
        "value",
        "instrument",
      ]);

      return {
        crowdfunding,
        activity: {
          ...activity,
          date: new Date(),
        },
      };
    },
  },
  economies: genItem,
  retirement: {
    ...genItem,
    formFormatter: (formValue) => ({
      retirement: pick(formValue, ["name", "bankName", "type"]),
      activity: pick(formValue, ["value", "instrument", "date"]),
    }),
  },
};
export const privateCivilRealEstateCreationForm: FormBuilderProps["items"] = [
  {
    className: "grid md:grid-cols-3 grid-cols-1",
    children: [
      {
        name: "name",
        label: "forms.privateCivilRealEstate.name.label",
        type: "react-select-async",
        placeholder: "forms.privateCivilRealEstate.name.placeholder",
        loadOptions: async () => {
          const data = await SCPIListUrl();

          return data.SCPIList?.map(
            ({
              name,
              subscription_price,
            }: {
              name: string;
              subscription_price: string;
            }) => ({
              value: name,
              label: name,
              price: Number(subscription_price),
            })
          );
        },
        onSearch: async (search) => {
          const data = await SCPIListUrl();

          return data.SCPIList?.map(
            ({
              name,
              subscription_price,
            }: {
              name: string;
              subscription_price: string;
            }) => ({
              value: name,
              label: name,
              price: Number(subscription_price),
            })
          ).filter(({ value }) =>
            (value as string).toLowerCase().includes(search.toLowerCase())
          );
        },
        onChange: (value, params) => {
          (value as Option)?.price !== undefined &&
            params?.form?.setValue?.("unitPrice", (value as Option)?.price);
        },
        className:
          "h-fit my-auto bg-white rounded-xl shadow-md border border-primary-100 disabled:border-slate-200 disabled:shadow-none invalid:border-pink-500",
      },
      {
        name: "type",
        label: "forms.privateCivilRealEstate.type.label",
        type: "select",
        options: [
          {
            label: "forms.privateCivilRealEstate.type.options.full_property",
            value: "full_property",
          },
          {
            label: "forms.privateCivilRealEstate.type.options.property",
            value: "property",
          },
        ],
        className: "border rounded-xl",
      },
      {
        label: "forms.privateCivilRealEstate.quantity.label",
        name: "quantity",
        type: "number",
        placeholder: "10",
      },
      {
        label: "forms.privateCivilRealEstate.unitPrice.label",
        name: "unitPrice",
        type: "number",
        placeholder: "100",
      },
      {
        label: "forms.privateCivilRealEstate.purschaseDate.label",
        name: "purschaseDate",
        type: "date",
        placeholder: "03/03/2020",
      },
    ],
  },
];
