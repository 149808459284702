import { isStr } from "./check";
import { monthDiff } from "./date";
import { Amount, AssetStatus, Loan } from "@types";

export const removePercent = (
  price: string | number,
  percent: string | number
) => {
  const floatPrice = isStr(price) ? parseFloat(price as string) : price;
  const floatPercent = isStr(percent) ? parseFloat(percent as string) : percent;

  return !price || !percent
    ? price
    : floatPrice - (floatPrice * floatPercent) / 100;
};

export function dateFormat(date: Date | string | number) {
  const dateFormatter = new Intl.DateTimeFormat(undefined, {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  return dateFormatter.format(
    typeof date === "string" || typeof date === "number" ? new Date(date) : date
  );
}

const globalAmountFormatting: Intl.NumberFormatOptions = {
  style: "currency",
  maximumFractionDigits: 0,
};

export const formatCurrency = (amount: Amount) => {
  if (amount?.value === undefined || amount.instrument === undefined)
    return "N/A";
  return new Intl.NumberFormat(undefined, {
    ...globalAmountFormatting,
    currency: amount.instrument ?? "EUR",
    minimumFractionDigits: 0, // Aucune décimale
    maximumFractionDigits: 0, // Aucune décimale
  }).format(amount.value);
};

export const calcPercent = (
  price: string | number,
  percent: string | number
) => {
  const floatPrice = isStr(price) ? parseFloat(price as string) : price;
  const floatPercent = isStr(percent) ? parseFloat(percent as string) : percent;

  return !price || !percent ? price : (floatPrice * floatPercent) / 100;
};
export const randomIntFromInterval = (min = 0, max: number) => {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
};

const formatLangConfig = {
  us: {
    name: "en-US",
    style: "currency",
    currency: "USD",
  },
  fr: {
    name: "fr-FR",
    style: "currency",
    currency: "EUR",
  },
};
export const numberFormat = (
  price: number | string,
  {
    lang,
    showCurrency = true,
    ...options
  }: {
    lang?: keyof typeof formatLangConfig;
    showCurrency?: boolean;
  } & Intl.NumberFormatOptions = { lang: "fr", minimumFractionDigits: 2 }
) => {
  const { name, ...props } = formatLangConfig[lang || "fr"];

  const floatPrice = isStr(price) ? parseFloat(price) : price;
  const formatter = new Intl.NumberFormat(name, {
    ...(showCurrency ? props : {}),
    ...options,
  });

  return formatter.format(floatPrice || 0);
};
export const convertDevice = (
  price: string | number,
  device: string | number,
  tofix = 2
) => {
  const floatPrice = isStr(price) ? parseFloat(price as string) : price;
  const floatDevice = isStr(device) ? parseFloat(device as string) : device;

  const result = device ? (floatPrice * floatDevice).toFixed(tofix) : price;

  return !isStr(result) ? "" : result;
};
export const spaceCurrency = (x: string | number, spacer = " ") =>
  x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, spacer) : x;
export const formateWalletAddress = (
  address: string,
  min: number,
  max: number
) => {
  if (!address) return address;

  return `${address.substr(0, min)}...${address.substr(
    address.length - max,
    address.length - 1
  )}`;
};

export const calcLoan = (data: Loan & { asset: { status: AssetStatus } }) => {
  const isManual = data.asset.status === AssetStatus.manual;

  let totalInterst = 0,
    total = 0;

  const capital = Math.abs(data.loanedAmount ?? 0);

  const duration = data.duration;
  const monthlyPayment = data.monthlyPayment;

  if (isManual) {
    totalInterst = monthlyPayment * duration - capital;
    total = capital + totalInterst;
  } else {
    totalInterst = monthlyPayment * duration - capital;
    if (totalInterst < 0) totalInterst = capital + totalInterst;
    total = totalInterst + capital;
  }

  const nbMonthLeftSinceStart = monthDiff(new Date(data.start), new Date());
  const montlyRemaing = monthlyPayment;
  const totalReFundeded = nbMonthLeftSinceStart * montlyRemaing;
  const remaining = total - totalReFundeded;

  const remainingPercent = `${((1 - totalReFundeded / total) * 100).toFixed(
    2
  )}%`;

  const donePercent = (totalReFundeded / total) * 100;

  const startDate = new Date(data.start);
  const endDate = new Date(
    startDate.getFullYear(),
    startDate.getMonth() + data.duration,
    startDate.getDate()
  );

  return {
    total,
    capital,
    duration,
    remaining,
    totalInterst,
    monthlyRemaining: montlyRemaing,
    totalReFundeded,
    remainingPercent,
    donePercent,
    startDate,
    endDate,
  };
};
