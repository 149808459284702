import { createContext, useContext, useState } from "react";

import { MenuLinkType } from "core/helpers";
import { getMenuLinks } from "core/helpers/navigation";
import { BannerPropsType } from "layouts/dashboard/Banner";
import { routeList } from "routing/routeList";
import { AuthService } from "core/services";
import { UserInterface } from "@types";

export type AppContextType = {
  mode: "tenant" | "admin";
  setMode: (mode: "tenant" | "admin") => void;

  bannerProps: BannerPropsType;
  setBannerProps?: (props: BannerPropsType) => void;

  menuLinks: Array<MenuLinkType>;
  currentMenu: MenuLinkType | undefined;
  setCurrentMenu: (menu: MenuLinkType) => void;

  //   mainModalOptions: DrawerOptions;
  //   setMainModalOptions: (opts: DrawerOptions) => void;

  //   mainSlideOverOptions: DrawerOptions;
  //   setMainSlideOverOptions: (opts: DrawerOptions) => void;

  currentLang: string;
  setCurrentLang: (lang: string) => void;
};

export const AppContext = createContext<AppContextType>({
  mode: "tenant",
} as any);

export function AppProvider({ children }: any) {
  const user = AuthService.getUser();

  const [mode, setMode] = useState("tenant");
  const [currentMenu, setCurrentMenu] = useState<MenuLinkType>();
  const [bannerProps, setBannerProps] = useState<BannerPropsType>({});
  //   const [mainModalOptions, setMainModalOptions] = useState<DrawerOptions>();
  //   const [mainSlideOverOptions, setMainSlideOverOptions] =
  // useState<DrawerOptions>();
  const [currentLang, setCurrentLang] = useState(localStorage.getItem("lang"));
  const menuLinks = getMenuLinks(user as UserInterface, routeList, mode as any);

  // useEffect(() => {
  //   if (!currentLang) return;
  //   localStorage.setItem("lang", currentLang);
  //   i18n.changeLanguage(currentLang);
  // }, [currentLang, i18n]);

  return (
    <AppContext.Provider
      value={
        {
          mode,
          setMode,

          menuLinks,
          currentMenu,
          setCurrentMenu,
          //   mainSlideOverOptions,
          //   setMainSlideOverOptions,
          bannerProps,
          setBannerProps,
          //   mainModalOptions,
          //   setMainModalOptions,
          currentLang,
          setCurrentLang,
        } as any
      }
    >
      {children}
    </AppContext.Provider>
  );
}

export function useAppContext(): AppContextType {
  return useContext(AppContext);
}
