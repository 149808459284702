import { useEffect, VFC } from "react";
import { TFunction } from "i18next";
import { NavLink, useLocation } from "react-router-dom";

import { Icon } from "components";
import { RouteListType } from "routing";
import { clsx, MenuLinkType } from "core";
import { AppContextType, useAppContext } from "context/AppContext";

interface MenuProps {
  t: TFunction;
  currentMenu: AppContextType["currentMenu"];
  routeList: MenuLinkType[];
  setCurrentMenu: AppContextType["setCurrentMenu"];
}
interface MenuListProps extends MenuProps {
  search: string;
  unfolded?: boolean;
  route: RouteListType;
}

const MenuList: VFC<MenuListProps> = ({
  search,
  currentMenu,
  unfolded,
  routeList,
  t,
  setCurrentMenu,
  route,
}) => (
  <ul className={clsx("hidden", unfolded && "block")}>
    {routeList.map((item, i) => {
      const { name, to, menuClassName, menuDisabled, children, parent } = item;
      const hasChildren = !!children?.length;
      const isUnfolded =
        currentMenu?.name === name ||
        !!children?.find((child) => child.name === currentMenu?.name);

      return (
        <li
          key={`${name}-${i}`}
          className={clsx(
            "leading-normal",
            isUnfolded && route.key != "premium" && "active"
          )}
        >
          <NavLink
            className={clsx(
              "flex items-center justify-between py-[5px] text-white",
              parent ? "text-[15px]" : "text-lg",
              menuClassName
            )}
            to={!to || menuDisabled ? (hasChildren ? search : "#") : to}
            onClick={() => name && setCurrentMenu(item)}
          >
            {t(name as string)}
            {hasChildren && (
              <Icon
                type="wc_arrowUp"
                className="fill-arrow-menu menuArrowUp"
                rotate={isUnfolded ? 180 : 90}
              />
            )}
          </NavLink>
          {hasChildren && (
            <MenuList
              search={search}
              routeList={children}
              unfolded={isUnfolded}
              currentMenu={currentMenu}
              t={t}
              setCurrentMenu={setCurrentMenu}
              route={route}
            />
          )}
        </li>
      );
    })}
  </ul>
);

export const NavMenu: VFC<{
  t: TFunction;
  route: RouteListType;
}> = ({ t, route }) => {
  const { menuLinks, currentMenu, setCurrentMenu } = useAppContext();
  const { pathname, search } = useLocation();

  useEffect(() => {
    const curr = menuLinks
      .flatMap((m: MenuLinkType) => [m, ...(m.children || [])])
      .find((e) => e.to === pathname);

    if (!curr) return;

    setCurrentMenu(curr);
  }, [pathname]);

  return (
    <nav className="mynav">
      <MenuList
        search={search}
        currentMenu={currentMenu}
        routeList={menuLinks}
        t={t}
        unfolded={true}
        setCurrentMenu={setCurrentMenu}
        route={route}
      />
    </nav>
  );
};
