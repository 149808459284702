import { z } from "zod";
import { UseFormRegister } from "react-hook-form";
import { InvestorProfileFormInputs } from "./investorProfileForm";

import { clsx } from "core";
import { Text } from "components/Text";
import { Label } from "../../../../../components/Label";

interface RadioOrCheckboxProps {
  name: keyof InvestorProfileFormInputs;
  label: string;
  type: "radio" | "checkbox";
  align?: "vertical" | "horizontal";
  subTitle?: string;
  value?: string;
  className?: string;
  defaultValue?: number;
  disabled?: boolean;
  register: UseFormRegister<InvestorProfileFormInputs>;
}

export interface InvestorProfileListItem {
  value: string;
  subTitle?: boolean;
}
export interface InvestorProfileListGraphqItem extends InvestorProfileListItem {
  icon?: string;
  hasTitle?: boolean;
  description?: boolean;
}
interface RadioWithCheckboxProps
  extends Pick<RadioOrCheckboxProps, "name" | "value" | "register" | "align"> {
  list: InvestorProfileListItem[];
  i18nKey: string;
  subTitle?: boolean;
  checkbable?: boolean;
}

interface ListWrapperProps {
  i18nKey: string;
  children: React.ReactNode;
}

// Lists
function ListWrapper({ i18nKey, children }: ListWrapperProps) {
  return (
    <div className="mb-6">
      <Text
        label={`page.profil.form.${i18nKey}.title`}
        className="bg-grey-400 mb-2 pl-5"
      />
      <div className="pl-5">{children}</div>
    </div>
  );
}
export function RadioList({
  name,
  list,
  i18nKey,
  register,
  ...props
}: Omit<RadioWithCheckboxProps, "value">) {
  return (
    <ListWrapper i18nKey={i18nKey}>
      {list.map((item, index) => (
        <InputElement
          key={`${item.value}-${index}`}
          type="radio"
          name={name}
          value={item.value}
          subTitle={
            item.subTitle ? `page.profil.form.${i18nKey}.subTitle` : undefined
          }
          register={register}
          align={props.align}
          label={`page.profil.form.${i18nKey}.${index + 1}`}
        />
      ))}
    </ListWrapper>
  );
}

export function CheckBoxList({
  data,
  i18nKey,
  register,
  ...props
}: {
  data: Record<string, string>;
  register: UseFormRegister<InvestorProfileFormInputs>;
  i18nKey: string;
  align?: RadioOrCheckboxProps["align"];
}) {
  return (
    <ListWrapper i18nKey={i18nKey}>
      {Object.entries(data).map(([key, value], index) => (
        <InputElement
          type="checkbox"
          key={`${key}-${index}`}
          value={value}
          name={key as keyof InvestorProfileFormInputs}
          register={register}
          label={`page.profil.form.${i18nKey}.${index + 1}`}
          {...props}
        />
      ))}
    </ListWrapper>
  );
}

export function RadioWithCheckboxList({
  data,
  i18nKey,
  checkbable = true,
  register,
  ...props
}: {
  data: {
    key: string;
    list: {
      subTitle?: boolean;
      list: InvestorProfileListItem[];
    }[];
  };
  register: UseFormRegister<InvestorProfileFormInputs>;
  i18nKey: string;
  align?: RadioOrCheckboxProps["align"];
  checkbable?: boolean;
}) {
  return (
    <ListWrapper i18nKey={i18nKey}>
      {data.list.map(({ list, subTitle }, index) => {
        const key = `${data.key}.${index + 1}`;

        return (
          <RadioWithCheckbox
            key={key}
            checkbable={checkbable}
            subTitle={subTitle}
            name={key as keyof InvestorProfileFormInputs}
            list={list}
            register={register}
            i18nKey={key}
            {...props}
          />
        );
      })}
    </ListWrapper>
  );
}

// Elements
export function RadioWithCheckbox({
  name,
  list,
  value,
  subTitle,
  checkbable = true,
  i18nKey = "q3a.3",
  register,
  ...props
}: RadioWithCheckboxProps) {
  const _label = `page.profil.form.${i18nKey}.check`;
  const _subTitle = subTitle
    ? `page.profil.form.${i18nKey}.subTitle`
    : undefined;

  return (
    <div
      className={clsx(
        "mb-2",
        props.align === "horizontal" && "flex justify-between"
      )}
    >
      {checkbable ? (
        <InputElement
          type="checkbox"
          value={value}
          name={`${name}Check` as keyof InvestorProfileFormInputs}
          register={register}
          subTitle={_subTitle}
          label={_label}
        />
      ) : (
        <Label
          label={_label}
          subTitle={_subTitle}
          className="cursor-pointer text-base"
        />
      )}
      <div
        className={clsx(
          "ml-4 flex flex-col gap-2 px-2",
          checkbable && "bg-gray-50",
          props.align === "horizontal" && "flex-row items-center"
        )}
      >
        {list.map((item, index) => (
          <InputElement
            key={`${item.value}-${index}`}
            type="radio"
            name={name}
            value={item.value}
            subTitle={
              item.subTitle ? `page.profil.form.${i18nKey}.subTitle` : undefined
            }
            register={register}
            label={`page.profil.form.${i18nKey}.${index + 1}`}
            {...props}
          />
        ))}
      </div>
    </div>
  );
}
function InputElement({
  name,
  type,
  label,
  align = "vertical",
  value,
  subTitle,
  disabled,
  className,
  register,
}: RadioOrCheckboxProps) {
  const id = `${name}-${value}`;

  return (
    <div
      className={clsx(
        "flex gap-2",
        align === "vertical" && "items-center",
        className
      )}
    >
      <input
        type={type}
        id={id}
        value={value}
        {...register(name, {
          disabled,
        })}
      />
      <div>
        <Label
          id={id}
          label={label}
          subTitle={subTitle}
          className="cursor-pointer text-base"
        />
      </div>
    </div>
  );
}

export function RadioWithGraph({
  name,
  list,
  i18nKey,
  register,
  ...props
}: Omit<RadioWithCheckboxProps, "value" | "list"> & {
  list: InvestorProfileListGraphqItem[];
}) {
  return (
    <ListWrapper i18nKey={i18nKey}>
      <div className="flex items-stretch">
        {list.map((item, index) => (
          <Item key={`${item.value}-${item}`} item={item} index={index + 1} />
        ))}
      </div>
    </ListWrapper>
  );

  function Item({
    item,
    index,
  }: {
    index: number;
    item: InvestorProfileListGraphqItem;
  }) {
    return (
      <div key={index} className="flex flex-1 flex-col justify-between ">
        {!!item.hasTitle && (
          <Text
            className="px-2 text-center"
            label={`page.profil.form.${i18nKey}.${index}.title`}
          />
        )}
        <div>
          <img src={item.icon} className="mb-4 block" />
          {item.description && (
            <Label
              label={`page.profil.form.${i18nKey}.${index}.description`}
              className="cursor-pointer text-base"
            />
          )}
          <InputElement
            type="radio"
            name={name}
            value={item.value}
            subTitle={
              item.subTitle ? `page.profil.form.${i18nKey}.subTitle` : undefined
            }
            register={register}
            align={props.align}
            label={`page.profil.form.${i18nKey}.${index}.label`}
          />
        </div>
      </div>
    );
  }
}

// Helpers
export function makeZodBoolFromObject(obj: object) {
  return z.object(
    Object.keys(obj).reduce((prev, curr) => {
      return { ...prev, [curr]: z.boolean() };
    }, {})
  );
}

type DataType = InvestorProfileFormInputs;

type DataTypekey = keyof DataType;

function calculateAverage(data: DataType, key: DataTypekey): number {
  let sum = 0;
  let count = 0;
  const subData = data[key];

  if (subData === null) {
    return 0;
  }

  if (typeof subData === "number") {
    return subData;
  }

  for (const subKey in subData as unknown as object) {
    let value = subData?.[subKey as keyof typeof subData] as unknown as
      | string
      | number;
    if (typeof value === "string") {
      value = Number(value);
    }
    if (!isNaN(value)) {
      sum += value;
      count++;
    }
  }
  return count === 0 ? 0 : sum / count;
}

function calculateSum(data: DataType, key: DataTypekey): number {
  const subData = data[key];

  if (subData === null) {
    return 0;
  }

  if (typeof subData === "number") {
    return subData;
  }

  let sum = 0;

  if (Array.isArray(subData)) {
    for (const value of subData) {
      const numValue = typeof value === "string" ? Number(value) : value;
      if (!isNaN(numValue) && numValue !== false) {
        sum += numValue;
      }
    }
  } else {
    for (const subKey in subData as unknown as object) {
      const value = subData?.[subKey as keyof typeof subData] as unknown as
        | string
        | number;

      const numValue = typeof value === "string" ? Number(value) : value;
      if (!isNaN(numValue)) {
        sum += numValue;
      }
    }
  }

  return sum;
}

export function calcInvestorProfileStats(data?: DataType): {
  risk: number;
  experience: number;
  sensitivity: number;
  negativeImpacts: number;
  environmentActivities: number;
  environmentSocialGoal: number;
} {
  if (!data) {
    data = {} as DataType;
  }

  const valr: { [key: number]: number } = {};
  const nbquestion: { [key: number]: number } = {
    1: 5,
    2: 9,
    3: 4,
    4: 1,
    5: 1,
    6: 1,
  };
  const divisors: { [key: number]: number } = {
    1: 3.6,
    2: 0.84,
    3: 0.93,
    4: 0.66,
    5: 1,
    6: 2,
  };

  valr[1] =
    calculateAverage(data, "q2") +
    calculateSum(data, "q3a" as DataTypekey) +
    calculateSum(data, "q3b" as DataTypekey) +
    calculateSum(data, "q4") +
    calculateSum(data, "q5");
  valr[2] =
    calculateAverage(data, "q1") +
    calculateSum(data, "q6") +
    calculateSum(data, "q7") +
    calculateSum(data, "q8") +
    calculateSum(data, "q9a") +
    calculateSum(data, "q9b") +
    calculateAverage(data, "q14") +
    calculateSum(data, "q15") +
    calculateSum(data, "q16");
  valr[3] =
    calculateSum(data, "q10") +
    calculateSum(data, "q11") +
    calculateSum(data, "q12") +
    calculateSum(data, "q13");
  valr[4] = calculateAverage(data, "q17");
  valr[5] = calculateAverage(data, "q18");
  valr[6] = calculateSum(data, "q19");

  return {
    experience: Math.round(valr[1] / nbquestion[1] / divisors[1]),
    risk: Math.round(valr[2] / nbquestion[2] / divisors[2]),
    sensitivity: Math.round(valr[3] / nbquestion[3] / divisors[3]),
    environmentActivities: Math.round(valr[4] / nbquestion[4] / divisors[4]),
    environmentSocialGoal: Math.round(valr[5] / nbquestion[5] / divisors[5]),
    negativeImpacts: Math.round(valr[6] / nbquestion[6] / divisors[6]),
  };
}
