import { clsx } from "core";
import * as React from "react";

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label: string;
};

const CustomInput = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, label, ...props }, ref) => {
    return (
      <div className="rounded-xl border border-primary-100 bg-white px-1 shadow-md">
        <label
          htmlFor={props.name}
          className="px-2 font-bold tracking-tight text-primary"
        >
          {label}
        </label>
        <input
          type={type}
          id={props.name}
          className={clsx(
            "mt-1 flex w-full rounded-xl border-none px-3 text-sm focus:border-none focus:border-secondary-500 focus:ring-secondary-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
            className
          )}
          ref={ref}
          {...props}
        />
      </div>
    );
  }
);
CustomInput.displayName = "Input";

export { CustomInput };
