import { type FC } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import { routes } from "routing";
import { InfoLine } from "../InfoLine";
import { AssetType, Loan } from "@types";
import { getLoanById } from "./Assets.logic";
import { calcLoan, dateFormat, numberFormat } from "core";
import { useAssetActions } from "./AssetActions/AssetActions";
import { useDeleteAsset } from "core/helpers/hooks/useDeleteAsset";
import { ManualUpdateModal, ScrollableContainer } from "components";

type LoanViewProps = {
  id: string;
};

const LoanView: FC<LoanViewProps> = ({ id }) => {
  const navigation = useNavigate();
  const { mutate: deleteAsset } = useDeleteAsset(() => {
    navigation(routes.loan.path);
  });

  const progressActions = useAssetActions(["delete"], {
    onDelete: async () => deleteAsset(id),
  });
  const { render, state, dispatch } = useAssetActions(["edit"]);

  const loanQuery = useQuery(["loan", id], () => getLoanById(id));

  if (!loanQuery.data) {
    return null;
  }

  // add loan duration (months) to start date
  const computedLoanData = calcLoan(loanQuery.data as unknown as Loan);

  const progress =
    computedLoanData.donePercent > 0 ? computedLoanData.donePercent : 0;

  return (
    <>
      <ScrollableContainer
        title="title.progress"
        Right={progressActions.render}
        className="h-fit pt-0"
      >
        <div className="p-4 pt-0">
          <div className="mb-3">
            <progress
              max="100"
              value={progress}
              className="h-2 w-full overflow-hidden rounded-xl "
            />
          </div>
          <InfoLine
            label="formLabel.loanEndDate"
            value={dateFormat(computedLoanData.endDate)}
          />
        </div>
      </ScrollableContainer>
      <ScrollableContainer
        title="title.informations"
        className="h-fit"
        Right={render}
      >
        <div className="p-4 pt-0">
          <InfoLine
            label="formLabel.total"
            value={numberFormat(computedLoanData.total)}
          />
          <InfoLine
            label="formLabel.capital"
            value={numberFormat(computedLoanData.capital)}
          />
          <InfoLine
            label="formLabel.totalInterst"
            value={numberFormat(computedLoanData?.totalInterst)}
          />
          <InfoLine
            label="formLabel.applicationFee"
            value={numberFormat(loanQuery.data.applicationFee ?? 0)}
          />
          <InfoLine
            label="formLabel.totalReFundeded"
            value={numberFormat(computedLoanData?.totalReFundeded)}
          />
          <InfoLine
            label="formLabel.remaining"
            value={numberFormat(computedLoanData?.remaining)}
          />
          <InfoLine
            label="formLabel.remainingPercent"
            value={computedLoanData.remainingPercent}
          />
        </div>
        {!!state.edit && (
          <ManualUpdateModal
            selected={loanQuery.data}
            editType={"update"}
            queryType={AssetType.loan}
            onClose={() => dispatch({ type: "edit" })}
            handleUpdate={loanQuery.refetch}
            handleDelete={console.log}
            handleActivityDelete={console.log}
          />
        )}
      </ScrollableContainer>
    </>
  );
};

export default LoanView;
