import { overrideTailwindClasses } from "tailwind-override";

export const ucFirst = (name = "", restLowercase = false) =>
  name
    ? name.charAt(0).toUpperCase() +
      (restLowercase ? name.slice(1).toLocaleLowerCase() : name.slice(1))
    : name;
export const pluralize = (name: string, count?: number, suffix = "s") =>
  `${name}${count === undefined || count > 1 ? suffix : ""}`;
export const dashed = (name: string) => name?.split(" ").join("-");
export const unDashed = (name: string) => name?.split("-").join(" ");

export const truncate = (str: string, nb: number) => {
  if (!str) return "";
  const arr = str?.split(" ");
  const truc = arr.slice(0, nb) || [];

  return truc.join(" ")?.concat(arr.length > nb ? "..." : "");
};
export const clsx = (...classNamesString: (string | boolean | undefined)[]) =>
  overrideTailwindClasses(classNamesString.filter(Boolean).join(" "));

export const convertHexToRGBA = (hexCode: string, opacity = 1) => {
  let hex = hexCode.replace("#", "");

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  /* Backward compatibility for whole number based opacity values. */
  if (opacity > 1 && opacity <= 100) {
    opacity = opacity / 100;
  }

  return `rgba(${r},${g},${b},${opacity})`;
};
