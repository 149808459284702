import { isArray } from "./check";

export const toArr = (arg) => (isArray(arg) ? arg : [arg]);
export const toggleArray = (array, name) => {
  let found = false;

  if (!isArray(array)) return [name];

  const data = array.filter((elem) => {
    if (elem === name) found = true;

    return elem !== name;
  });

  if (!found) data.push(name);
  return data;
};

export function generateNumberArray(length = 10) {
  return Array.from(Array(length).keys());
}
