import { FC } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { AssetType } from "@types";
import { numberFormat } from "core";
import { privateRoute } from "routing";
import TransactionRow from "../../../components/transactionRow";

type FavoriteAccountProps = {
  account: {
    id: string;
    name: string;
    currentActivity: {
      value: number;
      instrument: string;
    };
    transactions: {
      id: string;
      name: string;
      date: string;
      amount: number;
    }[];
  };
};

const MAX_SHOwN_TRANSACTIONS = 3;

const FavoriteAccount: FC<FavoriteAccountProps> = ({ account }) => {
  const { t } = useTranslation();

  return (
    <div className="px-1">
      <div className="flex justify-between">
        <Link
          to={privateRoute.assetDetails(AssetType.bankAccount, account.id)}
          className="text-content-secondary"
        >
          <p className="text-content-primary mb-2 font-sans text-base font-bold">
            {account.name}
          </p>
        </Link>
        <p className="text-content-secondary font-sans text-base font-medium">
          {numberFormat(account.currentActivity?.value ?? 0)}
        </p>
      </div>
      <div>
        {account.transactions
          .slice(0, MAX_SHOwN_TRANSACTIONS)
          .map((transaction) => (
            <TransactionRow key={transaction.id} transaction={transaction} />
          ))}
      </div>
      {account.transactions.length === 0 && (
        <div className="h-[50px] items-center justify-center">
          <p className="text-content-secondary font-sans text-xs font-medium">
            {t("empty_data")}
          </p>
        </div>
      )}
    </div>
  );
};

export default FavoriteAccount;
