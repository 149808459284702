import { createSlice } from "@reduxjs/toolkit";

interface Header {
  title?: string;
  icon: string | null;
}
const initialState: Record<"value", Header> = {
  value: {
    title: undefined,
    icon: null,
  },
};
export const headerReducer = createSlice({
  name: "header",
  initialState,
  reducers: {
    setHeader: (state, action) => {
      const { title, icon } = action.payload;

      if (icon !== undefined) {
        state.value.icon = icon;
      }

      if (title !== undefined) {
        state.value.title = title;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { setHeader } = headerReducer.actions;

export default headerReducer.reducer;
