import { config } from "config";
import { Icon } from "components/Icon";
import { Typo } from "components/Typo";
import { CardContentProps } from "@types";
import { clsx, numberFormat } from "core";

interface DoubleLabelProps {
  data: CardContentProps["data"];
  displayValue?: "percent" | "gain";
  className?: string;
}

export default function DoubleLabel({
  data: { percent, gain },
  displayValue = "gain",
  className,
}: DoubleLabelProps) {
  const isPositive = gain != undefined && gain >= 0;
  const maximumFractionDigits = config.percentDecimal ?? 0;

  return (
    <div className="flex w-full items-center justify-between gap-1">
      {displayValue === "gain" && (
        <Typo className={clsx("text-lg font-bold text-neutral-400", className)}>
          {gain !== undefined &&
            numberFormat(gain, {
              signDisplay: "exceptZero",
            })}
        </Typo>
      )}
      {displayValue === "percent" && (
        <Typo
          className={clsx(
            "flex items-center text-right font-bold",
            isPositive ? "text-primary-400" : "text-red-500",
            className
          )}
        >
          <Icon
            type="gain_arrow"
            className="stroke-current"
            rotate={isPositive ? 0 : 180}
          />
          {isFinite(Math.abs(percent as number)) && percent !== undefined
            ? numberFormat(percent, {
                style: "percent",
                signDisplay: "never",
                maximumFractionDigits,
              })
            : "N/A"}
        </Typo>
      )}
    </div>
  );
}
