import { useState, useEffect, useRef } from "react";

type Size = {
  width: number;
  height: number;
};
// Define the hook's return type
type UseSizeReturn = {
  ref: React.RefObject<HTMLDivElement>;
  width: number;
  height: number;
};

function UseSize(): UseSizeReturn {
  const ref = useRef<HTMLDivElement>(null);
  const [size, setWidth] = useState<Size>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    function handleResize() {
      if (ref.current) {
        const width = ref.current.offsetWidth;
        const height = ref.current.offsetHeight;
        setWidth({ width, height });
      }
    }

    // Initial setting
    handleResize();

    // Update on window resize
    window.addEventListener("resize", handleResize);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return { ref, ...size };
}

export default UseSize;
