import ContentLoader from "react-content-loader";

import { clsx } from "core";

// 322 * 542
const Card = () => (
  <>
    <rect x="12" y="12" rx="0" ry="0" width="298" height="298" />
    <rect x="0" y="450" rx="0" ry="0" width="322" height="88" />
    <circle cx="37" cy="413" r="19" />
    <circle cx="275" cy="412" r="25" />
    <rect x="20" y="334" rx="0" ry="0" width="143" height="15" />
    <rect x="270" y="337" rx="0" ry="0" width="27" height="13" />
  </>
);
const BarChart = () => (
  <>
    <rect x="0" y="160" rx="0" ry="0" width="25" height="40" />
    <rect x="30" y="145" rx="0" ry="0" width="25" height="55" />
    <rect x="60" y="126" rx="0" ry="0" width="25" height="74" />
    <rect x="90" y="80" rx="0" ry="0" width="25" height="120" />
    <rect x="120" y="142" rx="0" ry="0" width="25" height="58" />
  </>
);

export const skeletonVariants = {
  Card: {
    component: <Card />,
    props: {
      viewBox: "0 0 322 542",
    },
  },
  BarChart: {
    component: <BarChart />,
    props: {
      // width: 200,
      // height: 200,
      viewBox: "0 0 200 200",
    },
  },
};

export interface SkeletonListProps {
  variant: keyof typeof skeletonVariants;
  className?: string;
  length?: number;
}
export const SkeletonList = ({
  variant,
  className,
  length = 1,
  ...props
}: SkeletonListProps) => (
  <div className={clsx("h-full", className)}>
    {Array(length)
      .fill(null)
      .map((_, index) => (
        <ContentLoader
          key={index}
          speed={2}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          height="100%"
          {...skeletonVariants[variant].props}
          {...props}
        >
          {skeletonVariants[variant].component}
        </ContentLoader>
      ))}
  </div>
);
