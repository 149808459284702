import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";

import { AssetType, Page } from "@types";
import { useAppSelector } from "_redux";
import { NormalizeTranslationKeys } from "i18n";
import { useGQLQuery } from "core/queries/useQuery";
import { PerioActivityChart, PeriodActivityDataset } from "components/charts";
import { rangeForPeriod } from "core/helpers/convinients";
import { PeriodControl, Widget, Loading, Button } from "components";
import { DataType } from "components/charts/brush";
import {
  isDate,
  numberFormat,
  realestateValuation,
  singleRealEstatesUrl,
} from "core";

export interface PeriodItem {
  id: string;
  value: number;
  start: Date;
  end: Date;
}

interface EstateDetailsProps {
  details: {
    label: string;
    value: string | number;
  }[];
}

export const EstateDetails = ({ details }: EstateDetailsProps) => (
  <div className="flex h-auto flex-col justify-between gap-y-3 px-4 pt-4">
    {details.map((detail, i: number) => (
      <div className="flex justify-between border-b" key={i}>
        <p>{detail.label}</p>
        <p className="bold">{detail.value}</p>
      </div>
    ))}
  </div>
);

export const SideDetails = ({ details }: EstateDetailsProps) => (
  <div className="flex w-full flex-row gap-x-3 lg:w-1/4 lg:flex-col lg:gap-y-3">
    {details.map((detail, i: number) => (
      <div
        key={i}
        className="relative flex h-28 w-full cursor-pointer flex-col justify-around rounded-lg bg-gradient-to-t from-primary-1100 to-primary-1000 px-4 pt-2 pb-4 text-center lg:w-auto"
      >
        <h3 className="bold text-l uppercase text-primary-400">
          {detail.label}
        </h3>
        <p className="leading-5 text-white">{detail.value}</p>
      </div>
    ))}
    <Button
      label="actions.back"
      to="/realEstate"
      rounded
      className="mx-auto bg-primary-1100 text-primary-400"
    />
  </div>
);

export const RealEstate = ({ params }: Page) => {
  const id = params?.estate || "";
  const { t } = useTranslation();
  const period = useAppSelector((state) => state.period.value);
  const { data, isLoading } = useGQLQuery(
    "singleRealEstate" + id,
    singleRealEstatesUrl({ id })
  );

  const valuationQuery = useQuery(["realestateValuation", period], async () => {
    const range = rangeForPeriod(period);
    return realestateValuation(id, range.start, range.end);
  });

  const realEstate = data?.realEstate;
  let buyingDate = realEstate?.buyingDate;
  const date = new Date(realEstate?.buyingDate as string);
  buyingDate = isDate(date) ? date.toLocaleDateString() : "N/A";

  if (isLoading || valuationQuery.isLoading) return <Loading />;
  if (valuationQuery.data == null) return <p>Error</p>;
  const periodActivities: PeriodItem[] = [];
  const periodData = { periodActivities };

  for (const period of valuationQuery.data?.realEstate.asset.valuationInTime) {
    periodActivities.push({
      id: period.id,
      value: period.value,
      start: period.start,
      end: period.start,
    });
  }

  return (
    <div className="flex w-full flex-col justify-between lg:flex-row lg:gap-x-3">
      <div className="mb-3 flex w-full flex-col lg:mb-0 lg:w-[75%]">
        <div className="mb-5 flex flex-col justify-between lg:flex-row lg:gap-x-3">
          <Widget
            className="mb-4 h-fit w-full bg-white pb-6 lg:mb-0 lg:w-[49%]"
            label="title.informations"
            children={
              <EstateDetails
                details={[
                  {
                    label: t("formLabel.area"),
                    value: <>{realEstate?.area} ㎡</>,
                  },
                  {
                    label: t(`formLabel.condition`),
                    value: t(
                      `formLabel.${realEstate?.condition}` as NormalizeTranslationKeys
                    ),
                  },
                  {
                    label: t(`formLabel.purchaseDate`),
                    value: buyingDate,
                  },
                  {
                    label: t(`formLabel.ownership`),
                    value: realEstate.ownership * 100 + "%",
                  },
                ]}
              />
            }
          />
          <Widget
            className="mb-4 h-fit w-full bg-white pb-6 lg:mb-0 lg:w-[49%]"
            label="widget.specifications"
            children={
              <EstateDetails
                details={[
                  {
                    label: t(`formLabel.rooms`),
                    value: realEstate?.rooms,
                  },
                  {
                    label: t(`formLabel.bedrooms`),
                    value: realEstate?.bedrooms,
                  },
                  {
                    label: t(`formLabel.orientation`),
                    value: t(
                      `formLabel.${realEstate?.orientation}` as NormalizeTranslationKeys
                    ),
                  },
                ]}
              />
            }
          />
        </div>
        <Widget
          className="w-full"
          label="widget.evolution"
          Right={period != undefined && <PeriodControl />}
          children={
            <PerioActivityChart
              dataset={
                periodData?.periodActivities?.map((e: DataType) => ({
                  date: e.start,
                  amount: {
                    value: e.value,
                  },
                })) as PeriodActivityDataset[]
              }
              hasDecimal={false}
              assetType={AssetType.realEstate}
            />
          }
        />
      </div>
      <SideDetails
        details={[
          {
            label: t(`formLabel.source`),
            value: realEstate?.source,
          },
          {
            label: t(`formLabel.price`),
            value: numberFormat(data.realEstate?.price || 0, {
              maximumFractionDigits: 0,
            }),
          },
        ]}
      />
    </div>
  );
};
