import { gql } from "graphql-request";

import {
  AppartmentCondition,
  AppartmentType,
  Asset,
  AssetById,
  AssetStatus,
  AssetType,
  CondominiumCondition,
  Direction,
  RealEstateAmenitiesProximity,
  RealEstateView,
  Wealth,
} from "@types";
import { client } from "core/queries/makeGQLQuery";

export async function getAssetById(
  id: string,
  start?: Date | null,
  end?: Date | null
): Promise<AssetById> {
  const query = gql`
    query AssetById($id: String!, $start: DateTime, $end: DateTime) {
      asset(id: $id) {
        id
        name
        type
        status
        currentActivity {
          id
          value
          instrument
        }
        performance(start: $start, end: $end) {
          evolution
          gain {
            value
            instrument
          }
        }
        valuationInTime(start: $start, end: $end) {
          id
          value
          start
        }
      }
    }
  `;

  const response = await client.request(query, { id, start, end });
  return response.asset;
}

export async function getBankAccounts() {
  const query = gql`
    query BankAccounts {
      bankAccounts {
        id
        name
        bank
        bankUUID
        asset {
          id
        }
      }
    }
  `;

  const response = await client.request(query);
  return response.bankAccounts as {
    id: string;
    name: string;
    bank: string;
    bankUUID: string;
    asset: Asset;
  }[];
}

export async function getBankAccountById(assetId: string) {
  const query = gql`
    query AssetById($assetId: ID!) {
      bankAccountByAssetId(assetId: $assetId) {
        name
        bank
        bankUUID
        asset {
          currentActivity {
            value
          }
          transactions {
            name
            date
            amount
            id
          }
        }
      }
    }
  `;

  const response = await client.request(query, { assetId });
  return response.bankAccountByAssetId as {
    name: string;
    bank: string;
    bankUUID?: string;
    asset: {
      currentActivity?: {
        value: number;
      };
      transactions: {
        name: string;
        date: string;
        amount: number;
        id: string;
      }[];
    };
  };
}

export async function getEconomyById(assetId: string) {
  const query = gql`
    query EconomyById($assetId: ID!) {
      economyByAssetId(assetId: $assetId) {
        id
        name
        type
        bankName
      }
    }
  `;

  const response = await client.request(query, { assetId });

  return response.economyByAssetId as {
    name: string;
    type: string;
    bankName: string;
  };
}

export async function getRetirementById(assetId: string) {
  const query = gql`
    query RetirementById($assetId: ID!) {
      retirementByAssetId(assetId: $assetId) {
        id
        name
        type
        bankName
      }
    }
  `;

  const response = await client.request(query, { assetId });

  return response.retirementByAssetId as {
    name: string;
    type: string;
    bankName: string;
  };
}

export async function getCrowdfundingById(assetId: string) {
  const query = gql`
    query CrowdfundingById($assetId: ID!) {
      crowdfundingByAssetId(assetId: $assetId) {
        id
        subscribeDate
        endDate
        name
        efficiency
        investmentField
        providerName
        asset {
          currentActivity {
            value
            instrument
          }
        }
      }
    }
  `;
  const response = await client.request(query, { assetId });

  return response.crowdfundingByAssetId as {
    subscribeDate: string;
    endDate: string;
    name: string;
    efficiency: number;
    investmentField: string;
    providerName: string;
  };
}

export async function getExoticById(assetId: string) {
  const query = gql`
    query ExoticById($assetId: ID!) {
      exoticByAssetId(assetId: $assetId) {
        id
        name
        creation
        unitPrice
        category
        purchaseDate
        quantity
        ownership
      }
    }
  `;
  const response = await client.request(query, { assetId });

  return response.exoticByAssetId as {
    name: string;
    creation: string;
    unitPrice: number;
    category: string;
    purchaseDate: string;
    quantity: number;
    ownership: number;
  };
}

export async function getLifeInsuranceById(assetId: string) {
  const query = gql`
    query LifeInsuranceById($assetId: String!) {
      lifeInsuranceByAssetId(assetId: $assetId) {
        id
        name
        insuranceCompany
        transfersAmount
        efficiency
        creation
        asset {
          investments {
            id
            code
            label
            portfolioShare
            unitPrice
            unitValue
            quantity
            valuation
          }
        }
      }
    }
  `;

  const response = await client.request(query, { assetId });
  return response.lifeInsuranceByAssetId as {
    name: string;
    insuranceCompany: string;
    transfersAmount: number;
    efficiency: number;
    creation: string;
    asset: {
      investments: {
        id: string;
        code: string;
        label: string;
        portfolioShare: number;
        unitPrice: number;
        unitValue: number;
        quantity: number;
        valuation: number;
      }[];
    };
  };
}

export async function getRealEstateById(assetId: string) {
  const query = gql`
    query RealEstateById($assetId: ID!) {
      realEstateByAssetId(assetId: $assetId) {
        id
        name
        price
        ownership
        area
        typeId
        area
        rooms
        bedrooms
        bathrooms
        floor
        condition
        buildingFloors
        areaBalcony
        areaTerrace
        parkingPlots
        buildingStanding
        condominiumCondition
        orientation
        vista
        amenityProximity
        buyingDate
        source
        initialEstimation {
          value
        }
      }
    }
  `;

  const response = await client.request(query, { assetId });
  return response.realEstateByAssetId as {
    name: string;
    price: number;
    ownership: number;
    typeId: AppartmentType;
    area?: number;
    rooms?: number;
    bedrooms?: number;
    condition?: AppartmentCondition;
    bathrooms?: number;
    floor?: number;
    buildingFloors?: number;
    areaBalcony?: number;
    areaTerrace?: number;
    parkingPlots?: number;
    condominiumCondition?: CondominiumCondition;
    orientation: Direction;
    vista: RealEstateView;
    amenityProximity: RealEstateAmenitiesProximity;
    buyingDate: string;
    source?: string;
    initialEstimation: {
      value: number;
    };
  };
}

export async function getPrivateCivilRealEstateById(assetId: string) {
  const query = gql`
    query PrivateCivilRealEstateById($assetId: ID!) {
      privateCivilRealestateByAssetId(id: $assetId) {
        id
        name
        unitPrice
        quantity
        type
        purschaseDate
        scpi {
          name
          type
          capitalization
          subscription_price
          dividend
          state
          withdrawal_price
          subscription_fees
          gestion_fees
        }
      }
    }
  `;

  const response = await client.request(query, { assetId });
  return response.privateCivilRealestateByAssetId as {
    name: string;
    unitPrice: number;
    quantity: number;
    type: string;
    purschaseDate: string;
    scpi: {
      name?: string;
      type: string;
      capitalization: string;
      subscription_price?: number;
      dividend?: string;
      state: string;
      withdrawal_price: string;
      subscription_fees: string;
      gestion_fees: string;
    };
  };
}

export async function getLoanById(assetId: string) {
  const query = gql`
    query LoanById($assetId: String!) {
      loanByAssetId(assetId: $assetId) {
        id
        name
        interests
        start
        applicationFee
        type
        monthlyPayment
        assuranceFee
        duration
        amountLocked
        loanedAmount
        asset {
          status
        }
      }
    }
  `;

  const response = await client.request(query, { assetId });
  return response.loanByAssetId as {
    name: string;
    interests: number;
    start: string;
    applicationFee?: number;
    type: string;
    monthlyPayment: number;
    assuranceFee: number;
    duration: number;
    amountLocked?: number;
    loanedAmount?: number;
    asset: {
      status: AssetStatus;
    };
  };
}

export async function getPlatformAccountById(assetId: string) {
  const query = gql`
    query PlatformAccountById($assetId: String!) {
      platformAccountByAssetId(assetId: $assetId) {
        name
        exchange
        type
        stocks {
          id
          code
          name
          quantity
          unitValue
          unitPrice
          valuation
          instrument {
            logo
          }
        }
        cryptos {
          id
          code
          name
          quantity
          unitValue
          unitPrice
          creation
          instrument {
            logo
          }
        }
      }
    }
  `;

  const response = await client.request(query, { assetId });
  return response.platformAccountByAssetId as {
    name: string;
    exchange: string;
    stocks: {
      id: string;
      code: string;
      name: string;
      quantity: number;
      unitValue: number;
      unitPrice: number;
      valuation: number;
      instrument: {
        logo: string;
      };
    }[];
    cryptos: {
      id: string;
      code: string;
      name: string;
      quantity: number;
      unitValue: number;
      unitPrice: number;
      creation: number;
      instrument: {
        logo: string;
      };
    }[];
  };
}

export async function stocksData(): Promise<Asset[]> {
  const query = gql`
    {
      assets(type: crypto) {
        name
        id
        currentActivity {
          value
        }
      }
    }
  `;

  const response = await client.request(query);
  return response.assets as Asset[];
}

export async function createAsset(name: string): Promise<Asset> {
  const mutation = gql`
		mutation {
			assetCreation(name: ${name}, type: bankAccount) {
				id
				name
			}
		}
	`;

  const response = await client.request(mutation);
  return response.assetCreation as Asset;
}

export async function assets(
  type: AssetType,
  start: Date | null,
  end: Date | null
): Promise<Asset[]> {
  const query = gql`
    query Assets($type: AssetType!, $start: DateTime, $end: DateTime) {
      assets(type: $type) {
        name
        id
        isUnderManagement
        currentActivity {
          value
          instrument
        }
        performance(start: $start, end: $end) {
          evolution
          gain {
            value
            instrument
          }
        }
      }
    }
  `;

  const response = await client.request(query, { type, start, end });
  return response.assets;
}

export async function wealthQuery(
  start: Date | null = null,
  end: Date | null = null
): Promise<Wealth[]> {
  const query = gql`
    query WealthPerformance($start: DateTime, $end: DateTime) {
      wealth {
        type
        activity {
          value
          instrument
        }
        performance(start: $start, end: $end) {
          evolution
          gain {
            value
            instrument
          }
        }
      }
    }
  `;
  const variables = {
    start,
    end,
  };

  const response = await client.request(query, variables);
  return response.wealth;
}
