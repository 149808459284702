import { clsx } from "core";

export type Repartition = "global" | "financial";
interface RepartitionToggler {
  value?: Repartition;
  onClick: (state: Repartition) => void;
}

export function RepartitionToggler({
  value = "global",
  onClick,
}: RepartitionToggler) {
  return (
    <div className="mt-2 inline-flex h-8 w-full flex-col items-start justify-start gap-4">
      <div className="inline-flex items-start justify-start gap-2 self-stretch rounded-[40px] bg-stone-200 p-0.5">
        {["global", "financial"].map((item) => {
          const type = item as Repartition;

          const isCurrent = value === item;

          return (
            <Item
              key={type}
              repartition={type}
              isCurrent={isCurrent}
              onClick={() => onClick(type)}
            />
          );
        })}
      </div>
    </div>
  );
}

interface Item {
  repartition: Repartition;
  isCurrent?: boolean;
  onClick: () => void;
}

function Item({ repartition, isCurrent, onClick }: Item) {
  return (
    <div
      className="flex h-7 shrink grow basis-0 items-center justify-center gap-1.5"
      onClick={onClick}
    >
      <div
        className={clsx(
          "flex h-7 shrink grow basis-0 cursor-pointer items-center justify-center gap-1.5 rounded-2xl px-3 py-1.5 text-slate-600",
          isCurrent && "cursor-default bg-white text-slate-900"
        )}
      >
        <div className="text-center text-sm font-bold capitalize leading-none">
          {repartition}
        </div>
      </div>
    </div>
  );
}
