import { ManualFormFields } from "./manual";
import { action, options } from "./commons";
import { NormalizeTranslationKeys } from "i18n";

export const lifeInsurance: ManualFormFields<NormalizeTranslationKeys> = {
  items: [
    {
      className: "grid md:grid-cols-3 grid-cols-1",
      children: [
        {
          label: "forms.lifeInsurance.name.label",
          placeholder: "forms.lifeInsurance.name.placeholder",
          name: "name",
        },
        {
          name: "insuranceCompany",
          label: "forms.lifeInsurance.insuranceCompany.label",
          placeholder: "forms.lifeInsurance.insuranceCompany.placeholder",
        },
        {
          type: "number",
          name: "transfersAmount",
          label: "forms.lifeInsurance.transfersAmount.label",
          placeholder: "forms.lifeInsurance.transfersAmount.placeholder",
        },
        {
          type: "number",
          name: "efficiency",
          label: "forms.lifeInsurance.efficiency.label",
          placeholder: "forms.lifeInsurance.efficiency.placeholder",
        },
        {
          name: "value",
          type: "number",
          label: "forms.lifeInsurance.value.label",
          placeholder: "forms.lifeInsurance.value.placeholder",
          step: "any",
          required: true,
        },
        {
          name: "instrument",
          type: "select",
          label: "forms.lifeInsurance.instrument.label",
          options,
        },
        {
          name: "date",
          type: "date",
          label: "forms.lifeInsurance.date.label",
          placeholder: "forms.lifeInsurance.date.placeholder",
          required: true,
        },
      ],
    },
  ],
  action,
};
