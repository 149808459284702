import { FC } from "react";

import { Icon } from "../../../../../components/Icon";
import { dateFormat, numberFormat } from "core";

type TransactionRowProps = {
  transaction: {
    id: string;
    date: string;
    name: string;
    amount: number;
  };
};

const TransactionRow: FC<TransactionRowProps> = ({ transaction }) => {
  const isPositive = transaction.amount > 0;

  return (
    <div className="flex cursor-pointer items-center rounded-lg py-2 hover:bg-neutral-300">
      <div className="mr-3 flex flex-1 space-x-3 truncate">
        <div
          className="flex h-9 w-9 flex-shrink-0 items-center justify-center rounded-full"
          style={{
            backgroundColor: isPositive ? "#E3FCEF" : "#FCE3E3",
          }}
        >
          <Icon type="transation_arrow" rotate={isPositive ? -90 : 0} />
        </div>
        <div className="ml-3 mr-4 shrink grow justify-between truncate">
          <p
            className="truncate pb-1 text-sm font-normal leading-none text-slate-900"
            title={transaction.name}
          >
            {transaction.name}
          </p>
          <p className="text-xs font-medium leading-none text-zinc-400">
            {dateFormat(transaction.date)}
          </p>
        </div>
      </div>

      <div>
        <div className="ml-auto flex flex-col items-end">
          <span className="text-base font-bold text-neutral-600">
            {numberFormat(transaction.amount, { maximumFractionDigits: 2 })}
          </span>
        </div>
      </div>
    </div>
  );
};

export function TransactionRowSkeleton() {
  return <div className="h-16 flex-row items-center">skeleton</div>;
}

export default TransactionRow;
