import { FC } from "react";
import { Control, FieldValues } from "react-hook-form";
import { FormFieldsVariant, FormSelectProps } from "@types";

import { Fieldset } from "./fieldset";
import { Standard } from "./standard";
import { NormalizeTranslationKeys } from "i18n";

export const Select: FC<
  FormSelectProps<NormalizeTranslationKeys> & {
    variant?: FormFieldsVariant;
    control: Control<FieldValues, any>;
  }
> = ({ variant = "standard", ...props }) => {
  if (variant === "fieldset") return <Fieldset {...props} />;

  return <Standard {...props} />;
};
