import { VFC } from "react";
import { usePlacesWidget } from "react-google-autocomplete";
import { FieldValues, UseFormSetValue } from "react-hook-form";

import { InputTypes } from "@types";
import { config } from "config";
import { useFormBuilder } from "context";
import { clsx } from "core/helpers";
import { FormLabel } from "components";

interface Props extends InputTypes {
  setValue: UseFormSetValue<FieldValues>;
}
export const GoogleAutoComplete: VFC<Props> = ({
  name,
  label,
  setValue,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  register,
  ...props
}) => {
  const _formBuilderCtx = useFormBuilder();

  const { ref } = usePlacesWidget({
    apiKey: config.googleApiKey,
    options: {
      types: ["geocode"],
      componentRestrictions: { country: "fr" },
    },
    onPlaceSelected: ({ formatted_address, geometry: { location } }) =>
      setValue(name, {
        lat: location.lat(),
        lon: location.lng(),
        source: formatted_address,
      }),
  });

  return (
    <div className="rounded-xl border border-primary-100 bg-white shadow-md invalid:border-pink-500 disabled:border-slate-200 disabled:shadow-none">
      {!!label && (
        <FormLabel
          name={name}
          label={label}
          className={_formBuilderCtx?.classNames?.label?.className}
        />
      )}
      <div
        className={clsx(
          "flex w-full items-center overflow-hidden rounded-xl",
          "sm:text-sm ",
          "border-primary-200 shadow-sm invalid:border-pink-500 invalid:text-pink-600 focus-within:border-secondary-500 focus-within:ring-indigo-500 disabled:border-slate-200 disabled:bg-slate-50 disabled:text-slate-500 disabled:shadow-none"
        )}
      >
        <input
          {...(props as any)}
          ref={ref as any}
          className={clsx(
            "w-full flex-1 border-none focus:border-none focus:ring-0",
            _formBuilderCtx?.classNames?.input?.className
          )}
        />
      </div>
    </div>
  );
};
