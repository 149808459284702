import React from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import { QueryClient, QueryClientProvider } from "react-query";

import i18n from "./i18n";
import { App } from "./App";
import { FormBuilderProvider } from "context";
import "./index.css";

const staleTime = 2.5 * 60 * 1000; // Set stale time to 2.5 minutes in milliseconds

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime,
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <QueryClientProvider client={queryClient}>
        <FormBuilderProvider>
          <App />
        </FormBuilderProvider>
      </QueryClientProvider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
