import { VFC } from "react";

import { ManualUpdate } from "./manualUpdate";
import { AssetType, ManualUpdateModalProps } from "@types";
import { ManualAddModalWithActivity } from "./manualAddWithActivity";
import { ManualAddModalWithoutActivity } from "./manualAddWithoutActivity";

export const ManualUpdateModal: VFC<ManualUpdateModalProps> = (props) => {
  if (props.editType === "update") return <ManualUpdate {...props} />;
  else if (
    [
      AssetType.exotic,
      AssetType.crypto,
      AssetType.stocks,
      AssetType.realEstate,
      AssetType.privateCivilRealEstate,
    ].includes(props.queryType as AssetType)
  )
    return <ManualAddModalWithoutActivity {...props} />;
  return <ManualAddModalWithActivity {...props} />;
};
