import { DialogProps } from "@radix-ui/react-dialog";
import { DefaultTFuncReturn } from "i18next";
import { ReactNode } from "react";
import { createPortal } from "react-dom";

import { clsx } from "core";
import { DialogContent, DialogHeader, Dialog as ShadcnDialog } from "./element";

interface CustomDialogProps extends DialogProps {
  header?: JSX.Element | DefaultTFuncReturn | ReactNode;
  className?: string;
}

export function Dialog({
  children,
  header,
  className,
  ...props
}: CustomDialogProps) {
  return (
    <ShadcnDialog
      open={props.open}
      onOpenChange={props.onOpenChange}
      modal={false}
    >
      {props.open &&
        createPortal(
          <div className="custom-shadcn-overlay data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 fixed inset-0 z-50 bg-black/80" />,
          document.body
        )}
      <DialogContent
        className={clsx("max-h-[80vh] overflow-auto bg-white", className)}
      >
        {header && (
          <DialogHeader className="text-xl font-bold">{header}</DialogHeader>
        )}
        {children}
      </DialogContent>
    </ShadcnDialog>
  );
}
