import { FC } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import { routes } from "routing";
import { AssetType } from "@types";
import { InfoLine } from "../InfoLine";
import { getExoticById } from "./Assets.logic";
import { numberFormat, dateFormat } from "core";
import { useAssetActions } from "./AssetActions/AssetActions";
import { useDeleteAsset } from "core/helpers/hooks/useDeleteAsset";
import { ScrollableContainer, Icon, ManualUpdateModal } from "components";

type ExoticViewProps = {
  id: string;
};

const ExoticView: FC<ExoticViewProps> = ({ id }) => {
  const exoticQuery = useQuery(["exotic", id], () => getExoticById(id));

  const navigation = useNavigate();
  const { mutate: deleteAsset } = useDeleteAsset(() => {
    navigation(routes.realEstate.path);
  });
  const { render, state, dispatch } = useAssetActions(["edit", "delete"], {
    onDelete: async () => deleteAsset(id),
  });

  if (!exoticQuery.data) {
    return null;
  }

  return (
    <>
      <ScrollableContainer
        title="title.informations"
        className="h-fit"
        Right={render}
      >
        <div className="p-4 pt-0">
          <InfoLine label="formLabel.name" value={exoticQuery.data.name} />
          <InfoLine
            label="formLabel.category"
            value={exoticQuery.data.category}
          />
          <InfoLine
            label="formLabel.price"
            value={numberFormat(exoticQuery.data.unitPrice)}
          />
          <InfoLine
            label="formLabel.buyingDate"
            value={
              exoticQuery.data.creation
                ? dateFormat(exoticQuery.data.creation)
                : undefined
            }
            icon={<Icon type="calendar" />}
          />
        </div>
        {!!state.edit && (
          <ManualUpdateModal
            selected={exoticQuery.data}
            editType={"update"}
            queryType={AssetType.exotic}
            onClose={() => dispatch({ type: "edit" })}
            handleUpdate={exoticQuery.refetch}
            handleDelete={console.log}
            handleActivityDelete={console.log}
          />
        )}
      </ScrollableContainer>
    </>
  );
};

export default ExoticView;
