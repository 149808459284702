import { gql } from "graphql-request";

import { client } from "core/queries/makeGQLQuery";

export enum UserDocumentStatus {
  WAITING = "WAITING",
  COMPLETED = "COMPLETED",
  SIGNATURE_REQUIRED = "SIGNATURE_REQUIRED",
  CONFIRMATION_REQUIRED = "CONFIRMATION_REQUIRED",
}

export interface Document {
  id: string;
  name: string;
  url?: string;
}
export interface UserDocumentCategory {
  key: string;
  name: string;
  customerVisibility: boolean;
}

export interface UserDocument {
  id: string;
  name: string;
  extension: string;
  digital: boolean;
  type?: string;
  status: UserDocumentStatus;
  category: UserDocumentCategory;
  created: Date;
}
export interface Envelope {
  id: string;
  name: string;
  documentList: UserDocument[];
}

interface EnvelopeResponse {
  id: string;
  name: string;
}
interface DocumentListResponse {
  documentList: UserDocument[];
  envelopeList: EnvelopeResponse[];
}

interface DocumentCategoriesListResponse {
  documentCategoriesList: UserDocumentCategory[];
}
interface DocumentListQueryResponse {
  categories: UserDocumentCategory[];
  documents: any;
}

// Queries
const documentListQuery = async (
  company: string
): Promise<DocumentListQueryResponse> => {
  const queryDocuments = gql`
    query DocumentList {
      documentList {
        id
        name
        status
        digital
        category {
          key
          name
          customerVisibility
        }
        extension
        created
      }
      envelopeList(categories: ["arbitrary"]) {
        id
        name
      }
    }
  `;

  const response = (await client.request(
    queryDocuments
  )) as DocumentListResponse;

  const documentsByCategory: Record<string, Document[]> = {};
  response.documentList.forEach((doc) => {
    const categoryName = doc.category.key;
    if (!documentsByCategory[categoryName]) {
      documentsByCategory[categoryName] = [];
    }
    documentsByCategory[categoryName].push(doc);
  });

  if (response.envelopeList[0]) {
    documentsByCategory["arbitrary"] = [
      {
        id: response.envelopeList[0].id,
        name: response.envelopeList[0].name,
        documentList: documentsByCategory["arbitrary"],
      } as Envelope,
    ];
  } else {
    documentsByCategory["arbitrary"] = [];
  }

  const queryCategories = gql`
    query DocumentCategoriesList($company: String!) {
      documentCategoriesList(company: $company) {
        key
        name
        customerVisibility
      }
    }
  `;
  const responseCategories = (await client.request(queryCategories, {
    company,
  })) as DocumentCategoriesListResponse;

  const result = {
    categories: responseCategories.documentCategoriesList,
    documents: documentsByCategory,
  };

  return result;
};

const documentQuery = async (id: string): Promise<Document> => {
  const query = gql`
    query Document($id: String!) {
      document(id: $id) {
        id
        url
        name
      }
    }
  `;

  const response = (await client.request(query, {
    id,
  })) as Document;

  return response;
};

const documentFileQuery = async (id: string): Promise<string> => {
  const query = gql`
    mutation DocumentFile($id: String!) {
      documentFile(id: $id)
    }
  `;

  const response = (await client.request(query, {
    id,
  })) as {
    documentFile: string;
  };

  const documentRaw = await fetch(response.documentFile);
  const documentBuffer = await documentRaw.arrayBuffer();
  const documentBlob = new Blob([documentBuffer], {
    type: "application/pdf",
  });

  return URL.createObjectURL(documentBlob);
};

const documentPortalQuery = async (id: string): Promise<string> => {
  const query = gql`
    mutation DocumentPortal($id: String!) {
      documentPortal(id: $id)
    }
  `;

  const response = (await client.request(query, {
    id,
  })) as {
    documentPortal: string;
  };

  return response.documentPortal;
};

// Mutations
interface UserDocumentUpdate {
  storageName: string;
}
const documentFileUpdate = async (
  id: string,
  input: UserDocumentUpdate
): Promise<UserDocument> => {
  const query = gql`
    mutation DocumentFileUpdate($id: String!, $input: UserDocumentUpdate!) {
      documentFileUpdate(id: $id, input: $input) {
        id
      }
    }
  `;

  const response = (await client.request(query, {
    id,
    input,
  })) as {
    documentFileUpdate: UserDocument;
  };

  return response.documentFileUpdate;
};

interface UploadAccess {
  url: string;
  validity: Date;
  name: string;
}
export interface FileUploadRequest {
  name: string;
  MIME: string;
}
const documentFileUpload = async (
  request: FileUploadRequest
): Promise<UploadAccess> => {
  const query = gql`
    mutation DocumentFileUpload($request: FileUploadRequest!) {
      documentFileUpload(request: $request) {
        url
        name
      }
    }
  `;

  const response = (await client.request(query, {
    request,
  })) as {
    documentFileUpload: UploadAccess;
  };

  return response.documentFileUpload;
};

const documentFileAdd = async (
  companyID: string,
  input: UserDocumentUpdate,
  category: string
): Promise<UploadAccess> => {
  const query = gql`
    mutation UserDocumentAdd(
      $companyID: String!
      $input: UserDocumentUpdate!
      $category: String!
    ) {
      documentFileAdd(company: $companyID, input: $input, category: $category) {
        id
      }
    }
  `;

  const response = (await client.request(query, {
    companyID,
    input,
    category,
  })) as {
    DocumentAdd: UploadAccess;
  };

  return response.DocumentAdd;
};

// Exports
export const documentLogicQueries = {
  documentQuery,
  documentListQuery,
  documentFileQuery,
  documentPortalQuery,
};

export const documentLogicMutations = {
  documentFileUpload,
  documentFileUpdate,
  documentFileAdd,
};
