export const pick = (object = {}, keys = []) =>
  keys.reduce((obj, key) => {
    if (object && Object.prototype.hasOwnProperty.call(object, key)) {
      // eslint-disable-next-line no-param-reassign
      obj[key] = object[key];
    }

    return obj;
  }, {});
export const unPick = (object = {}, keys) =>
  pick(
    object,
    Object.keys(object).filter((e) => !keys.includes(e))
  );
export const separete = (object = {}, keys = []) => [
  pick(object, keys),
  unPick(object, keys),
];
export const arrayToObject = (arr = []) =>
  arr?.reduce((a, v) => ({ ...a, [v]: v }), {});
