import { Icon } from "./Icon";
import PremiumBlur from "./PremiumModules/PremiumBlur";

export function EmptyPlaceHolder() {
  return (
    <div className="cursor-pointer rounded-xl bg-white p-5">
      <div className="flex min-h-[174px] flex-1 items-center justify-center rounded-xl  border border-dotted border-asset bg-stone-100">
        <Icon type="add" className="stroke-asset" />
      </div>
    </div>
  );
}

interface PremiumPlaceHolderListProps {
  title?: string;
  list: React.ReactNode[];
}
export function PremiumPlaceHolderList({
  title = "",
  list,
}: PremiumPlaceHolderListProps) {
  const data = !list.length
    ? [<EmptyPlaceHolder />, <EmptyPlaceHolder />]
    : list;

  return (
    <PremiumBlur title={title}>
      <div className="flex h-full items-start gap-4 rounded-xl">
        {data.map((asset, index) => (
          <div key={index} className="w-full">
            {asset}
          </div>
        ))}
      </div>
    </PremiumBlur>
  );
}
