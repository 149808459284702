import ReactLottie from "lottie-react";

import * as types from "./types";

interface AnimationInterface {
  type: keyof typeof types;
  loop?: boolean;
  width?: number | string | undefined;
  height?: number | string | undefined;
  autoplay?: boolean;
  isClickToPauseDisabled?: boolean | undefined;
  [key: string]: any;
}

export const SvgAnimation = ({
  type,
  loop = true,
  autoplay = true,
  height = 400,
  width = 400,
  isClickToPauseDisabled = true,
  props,
}: AnimationInterface) => {
  const defaultOptions = {
    loop,
    autoplay,
    animationData: types[type],
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <ReactLottie
      {...defaultOptions}
      height={height}
      width={width}
      isClickToPauseDisabled={isClickToPauseDisabled}
      {...props}
    />
  );
};
