import { FieldValues } from "react-hook-form";

import { AssetType } from "@types";
import { action } from "./commons";
import { ManualFormFields } from "./manual";
import {
  createPlatform,
  instrumentUrl,
  manualPlatformAccountsUrl,
} from "core/queries";
import { NormalizeTranslationKeys } from "i18n";

export const stockOrCrypto: ManualFormFields<NormalizeTranslationKeys> = {
  items: [
    {
      children: [
        ({ props }) => {
          const isCrypto = props?.type === AssetType.crypto;

          return {
            name: "name",
            label: "forms.stockOrCrypto.name.label",
            type: "react-select-async",
            placeholder: isCrypto
              ? "forms.stockOrCrypto.name.placeholder.crypto"
              : "forms.stockOrCrypto.name.placeholder.stock",
            loadOptions: async (contains: string) => {
              const data = await instrumentUrl({
                contains,
                category: isCrypto ? props?.type : "stock",
              })();

              return data?.instruments
                ?.filter(
                  ({ name }) =>
                    contains.length &&
                    name?.toLowerCase().includes(contains?.toLowerCase())
                )
                .map(({ name, code }) => ({
                  value: name,
                  label: name,
                  code,
                }));
            },
            onSearch: async (contains: string) => {
              const data = await instrumentUrl({
                contains,
                category: isCrypto ? props?.type : "stock",
              })();

              return data?.instruments
                ?.filter(
                  ({ name }) =>
                    contains.length &&
                    name?.toLowerCase().includes(contains?.toLowerCase())
                )
                .map(({ name, code }) => ({
                  value: name,
                  label: name,
                  code,
                }));
            },
          };
        },
        ({ props: { type } }: { props: { type: AssetType } }) => ({
          name: "platformId",
          label: "forms.stockOrCrypto.platformId.label",
          placeholder: "forms.stockOrCrypto.platformId.placeholder",
          type: "react-select-async",
          loadOptions: async () => {
            const data = await manualPlatformAccountsUrl({
              type,
            })();

            return data?.manualPlatformAccounts?.map(({ name, id }) => ({
              value: id,
              label: name,
            }));
          },
          onCreate: async (value: string) => {
            const { createPlaform } = await createPlatform({
              input: {
                name: value,
                exchange: value,
                type,
              },
            });

            return {
              label: createPlaform.name,
              value: createPlaform.id,
            };
          },
        }),
        {
          label: "forms.stockOrCrypto.quantity.label",
          name: "quantity",
          step: "any",
          type: "number",
          placeholder: "forms.stockOrCrypto.quantity.placeholder",
        },
        {
          type: "number",
          name: "unitPrice",
          placeholder: "forms.stockOrCrypto.unitPrice.placeholder",
          label: "forms.stockOrCrypto.unitPrice.label",
        },
        {
          type: "date",
          name: "creation",
          label: "forms.stockOrCrypto.creation.label",
        },
      ],
    },
  ],
  action,
  formFormatter: (
    { platformId, name, ...input }: FieldValues,
    type: AssetType
  ) => ({
    platformId,
    input: {
      ...input,
      name: name.value,
      code: name.code,
      ...(type === AssetType.stocks
        ? { valuation: input.unitPrice * input.quantity }
        : {}),
    },
  }),
};
