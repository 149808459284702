import { AssetType } from "@types";
import { ucFirst } from "core/helpers";
import { makeMutation } from "./utils";

const deleteTypes: Record<AssetType, string> = {
  [AssetType.loan]: "loanDelete",
  [AssetType.exotic]: "exoticDelete",
  [AssetType.crypto]: "cryptoDelete",
  [AssetType.stocks]: "StocksDelete",
  [AssetType.savings]: "savingsDelete",
  [AssetType.economies]: "economiesDelete",
  [AssetType.realEstate]: "realEstateDelete",
  [AssetType.retirements]: "retirementDelete",
  [AssetType.bankAccount]: "bankAccountDelete",
  [AssetType.crowdfunding]: "crowdfundingDelete",
  [AssetType.lifeInsurance]: "lifeInsuranceDelete",
  [AssetType.privateCivilRealEstate]: "privateCivilRealestateDelete",
};

export const removeActivityMutation = makeMutation(`
  mutation DeleteActivity($id: String!) {
    deleteActivity(id: $id) {
      id
    }
  }
`);
export const deletePlatformMutation = makeMutation(`
  mutation DeletePlatform($id: String!) {
    deletePlatform(id: $id) {
      id
    }
  }
`);
export const deleteAssetTypeMutation = (type: AssetType) =>
  makeMutation(`
    mutation ${ucFirst(deleteTypes[type])}($id: String!) {
        ${deleteTypes[type]}(id: $id) {
            id
        }
    }
`);
export const deleteAcccountMutation = makeMutation(`
  mutation  {
    delete {
      id
    }
  }
`);
