import { AssetType } from "@types";
import { clsx, getAssetTypeColor } from "core";
import { ExclamationCircleIcon } from "@heroicons/react/outline";

type AssetIconProps = {
  asset: AssetType;
  size?: "small" | "medium" | "big";
  background: "asset" | "blank";
  className?: string;
  icon?: string;
  isHome?: boolean;
  isConnected?: boolean;
};

export const AssetIcon = ({
  asset,
  size = "big",
  background = "asset",
  className,
  isHome = false,
  icon,
  isConnected = true,
}: AssetIconProps) => {
  return (
    <div
      className={clsx(
        "relative flex flex-shrink-0 items-center justify-center rounded-full",
        size === "small" && "h-8 w-8",
        size === "medium" && "h-10 w-10",
        size === "big" && "h-11 w-11",
        className
      )}
    >
      <div
        className={clsx(
          "absolute flex h-full w-full rounded-full",
          background === "asset" ? "bg-asset" : "bg-white",
          size === "small" && "h-8 w-8",
          size === "medium" && "h-10 w-10",
          size === "big" && "h-11 w-11"
        )}
        style={
          isHome
            ? {
                backgroundColor: icon
                  ? "#F6F4F3"
                  : getAssetTypeColor(asset).backgroundColor,
              }
            : {}
        }
      />
      {icon ? (
        <img src={icon} className={clsx("z-20 block h-5 w-5")} />
      ) : (
        <img
          src={`/svg/asset_${asset}.svg`}
          className={clsx("z-20 block h-5 w-5")}
        />
      )}
      {isConnected === false && (
        <div className="absolute -right-0.5 -top-1.5">
          <ExclamationCircleIcon width={20} height={20} color="#FF0000" />
        </div>
      )}
    </div>
  );
};
