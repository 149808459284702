import { PlatformAccount, UserInterface } from "@types";
import { gql } from "graphql-request";

import { makeMutation } from "./utils";
import { client } from "./makeGQLQuery";
import { FRAGMENT_USER_FIELDS } from "./fragment";

interface Auth {
  token: string;
  user: UserInterface;
}

export const authWithTokenMutation = makeMutation<{
  authWithToken: Auth;
}>(`
  mutation AuthWithToken($token: String!, $discountCode: String) {
    authWithToken(token: $token, discountCode: $discountCode) {
      token
      user {
        ${FRAGMENT_USER_FIELDS}
      }
    }
  }
`);
export const createRetirementSimulationMutation = makeMutation(`
  mutation CreateRetirementSimulation($form: RetirementSimulationInput!) {
    createRetirementSimulation(form: $form)
  }
`);
export const setLoanDetailsMutation = makeMutation(`
  mutation setLoanDetails(
    $annualIncome: Float!,
    $spending: Float!,
    $savingsPerYear: Float!,
    $monthlyRepayment: Float
  ) {
    setLoanDetails(
      annualIncome: $annualIncome,
      spending: $spending,
      savingsPerYear: $savingsPerYear,
      monthlyRepayment: $monthlyRepayment) {
        loanCapacity
        debtRate
      }
  }
`);
export const setPatrimonialGoalsMutation = makeMutation(`
  mutation setPatrimonialGoals(
    $investmentCategories: [String!]!
    $investmentHorizon: InvestmentHorizon!
    $investmentType: InvestmentType!
    $yield: Yield!
  ) {
    setPatrimonialGoals(
      investmentCategories: $investmentCategories,
      investmentHorizon: $investmentHorizon,
      investmentType: $investmentType,
      yield: $yield
    ) {
      yield
    }
  }
`);
export const addActivityMutation = makeMutation(`
  mutation AddActivity($input: ActivityCreationInput!, $assetId: String!) {
    addActivity(input: $input, assetId: $assetId)
  }
`);
export const bankAccountCreationMutation = makeMutation(`
  mutation BankAccountCreation($name: String!, $bank: String!, $activity: ActivityCreationInput!) {
    bankAccountCreation(name: $name, bank: $bank, activity: $activity) {
      name
    }
  }
`);
export const privateCivilRealEstateCreationMutation = makeMutation(`
  mutation CreatePrivateCivilRealestate($input: PrivateCivilRealestateInput!) {
    createPrivateCivilRealestate(input: $input) {
      name
    }
  }
`);

export const createCrypto = makeMutation(`
mutation CreateCrypto(
  $input: CryptoCreation!
  $platformId: String!
) {
  createCrypto(
    input: $input
    platformId: $platformId
  ) {
    id
  }
}
`);

export const setUserSettings = makeMutation(`
  mutation SetUserSettings($referenceInstrument: String!, $layoutSettings: String!){
    setUserSettings(settings: {referenceInstrument: $referenceInstrument, layoutSettings: $layoutSettings}){
      ${FRAGMENT_USER_FIELDS}
    }
  }
  `);
export const createLifeInsurance = makeMutation(`
  mutation CreateLifeInsurance(
      $name: String!
      $activity: ActivityCreationInput!
      $insuranceCompany: String!
      $efficiency: Float!
      $transfersAmount: Float!
    ) {
      createLifeInsurance(
        name: $name
        activity: $activity
        insuranceCompany: $insuranceCompany
        efficiency: $efficiency
        transfersAmount: $transfersAmount
      ) {
        id
        name
        insuranceCompany
        transfersAmount
        efficiency
        creation
      }
    }
`);

export const createStocks = makeMutation(`
  mutation CreateStocks(
    $input: StockCreationInput!
    $platformId: String!
  ) {
    createStocks(
      input: $input
      platformId: $platformId
    ) {
      id
    }
  }
`);

export const economiesCreation = makeMutation(`
  mutation EconomiesCreation($economies: EconomiesCreation!, $activity: ActivityCreationInput!) {
    economiesCreation(economies: $economies, activity: $activity) {
      id
    }
  }
`);

export const createRealEstate = makeMutation(`
  mutation CreateRealEstate($realEstate: RealEstateCreation!) {
    createRealEstate(realEstate: $realEstate) {
      id
    }
  }
`);

export const createRetirement = makeMutation(`
  mutation RetirementCreation($retirement: RetirementCreation!, $activity: ActivityCreationInput!) {
    retirementCreation(retirement: $retirement, activity: $activity) {
      id
    }
  }
`);
export const createLoan = makeMutation(`
  mutation LoanCreation($loan: LoanCreation!, $activity: ActivityCreationInput!) {
    loanCreation(loan: $loan, activity: $activity) {
      id
    }
  }
`);
export const createCrowdfunding = makeMutation(`
  mutation CrowdfundingCreation($crowdfunding: CrowdfundingCreation!, $activity: ActivityCreationInput!) {
    crowdfundingCreation(crowdfunding: $crowdfunding, activity: $activity) {
      id
    }
  }
`);
export const createExotic = makeMutation(`
  mutation CreateExotic($input: ExoticInput!, $activity: ActivityCreationInput!) {
    createExotic(input: $input, activity: $activity) {
      id
    }
  }
`);

export const createPlatform = makeMutation<{ createPlaform: PlatformAccount }>(`
  mutation CreatePlaform($input: PlatformInput!) {
    createPlaform(input: $input) {
      id
      name
      type
      exchange
    }
  }
`);
export const deleteAccount = makeMutation<{ delete: { id: string } }>(`
  mutation DeleteAccount {
    delete {
      id
    }
  }
`);

export const submitInverstorProfileForm = makeMutation(`
  mutation submitInverstorProfileForm($input: JSON!) {
    submitInverstorProfileForm(input: $input)
  }
`);

export const setUserSettingsSimulationMutation = makeMutation<{
  setUserSettings: UserInterface;
}>(`
  mutation SetUserSettings($settings: SettingsInput!) {
    setUserSettings(settings: $settings) {
      ${FRAGMENT_USER_FIELDS}
    }
  }
`);

export const setUserInformationsDetailsMutation = makeMutation<{
  setInformationsDetails: boolean;
}>(`
  mutation SetUserDetailsForm($input: JSON!) {
    setInformationsDetails(input: $input)
  }
`);

export const setUserInformationsGeneralMutation = makeMutation<{
  setInformationsDetails: boolean;
}>(`
  mutation SetUserGeneralForm($input: JSON!) {
    setInformationsGeneral(input: $input)
  }
`);

export function acceptProCode(code: string) {
  const query = gql`
    mutation AcceptPro($code: String!) {
      invitationProAccept(code: $code)
    }
  `;

  return client.request<{ invitationProAccept: boolean }>(query, { code });
}
