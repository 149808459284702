import { useState } from "react";
import { FieldValues } from "react-hook-form";

import {
  Asset,
  AssetType,
  LifeInsurance,
  PlatformAccount,
  FormBuilderProps,
  ManualUpdateModalProps,
} from "@types";
import {
  isFn,
  manualForm,
  getAssetTypeColor,
  manualFormCRUDHandler,
  updateAssetTypeMutation,
} from "core";
import { Modal } from "components/Modal";
import { pick, unPick } from "core/helpers/object";
import { FormBuilder } from "components/formBuilder";
import { useTranslation } from "react-i18next";

type EditAssetParams = ((LifeInsurance | Asset | PlatformAccount) & {
  unitValue: { value: number };
  quantity: number;
}) & {
  queryType: AssetType;
};
const exludedFields = [
  "date",
  "value",
  "price",
  "endDate",
  "creation",
  "quantity",
  "ownership",
  "platformId",
  "buyingDate",
  "instrument",
  "purchaseDate",
  "purschaseDate",
  "subscribeDate",
  "budgetInsightId",
];
const formatExcludedFields = [
  "id",
  "asset",
  "amount",
  "currentActivity",
  "instrument",
];
export function getAssetEditDefaultValue(
  queryType: AssetType,
  args: EditAssetParams
) {
  let format = (value: FieldValues) => unPick(value, formatExcludedFields);
  let defaultValues: FieldValues = args || {};

  switch (queryType) {
    case AssetType.bankAccount:
      return {
        defaultValues,
        format: (value: FieldValues) =>
          pick(value, [
            "creation",
            "budgetInsightId",
            "userId",
            "assetId",
            "name",
            "bank",
            "bic",
          ]),
      };
    case AssetType.economies:
    case AssetType.retirements:
      return {
        defaultValues,
        format: (value: FieldValues) =>
          pick(value, ["name", "bankName", "type"]),
        pickFields: ["name", "bankName", "type"],
      };
    case AssetType.stocks:
    case AssetType.crypto:
      return {
        defaultValues,
        format: ({ name, unitPrice }: FieldValues) => {
          let data = {};
          if (name?.label) {
            data = {
              name: name?.label,
              code: name?.code,
            };
          } else {
            data = { name };
          }

          return {
            ...data,

            unitPrice,
          };
        },
        pickFields: ["name", "unitPrice", "code"],
      };

    case AssetType.lifeInsurance:
      const { asset, ...rest } = args as LifeInsurance;

      return {
        defaultValues: {
          ...rest,
          value: asset?.currentActivity?.value,
        },
        format: (value: FieldValues) =>
          pick(value, ["name", "insuranceCompany", "efficiency"]),
      };
    case AssetType.privateCivilRealEstate:
      defaultValues = pick(defaultValues, ["name", "unitPrice", "type"]);
      return { defaultValues, format };

    case AssetType.loan:
      const pickFields = [
        "name",
        "type",
        "start",
        "duration",
        "interests",
        "loanedAmount",
        "assuranceFee",
        "amountLocked",
        "applicationFee",
        "monthlyPayment",
      ];
      defaultValues = pick(defaultValues, pickFields);
      format = (value: FieldValues) => pick(value, pickFields);
      return { defaultValues, format };

    case AssetType.realEstate:
      defaultValues.geoloc = defaultValues.source;
      format = (value: FieldValues) =>
        pick(value, [
          "name",
          "area",
          "rooms",
          "floor",
          "vista",
          "source",
          "typeId",
          "bedrooms",
          "bathrooms",
          "condition",
          "orientation",
          "areaBalcony",
          "parkingPlots",
          "buildingFloors",
          "amenityProximity",
          "buildingStanding",
          "condominiumCondition",
        ]);
      return { defaultValues, format };
    case AssetType.crowdfunding:
      defaultValues = pick(defaultValues, [
        "name",
        "efficiency",
        "providerName",
        "investmentField",
      ]);
      return { defaultValues, format };

    case AssetType.exotic:
      return {
        defaultValues,
        format: (value: FieldValues) =>
          pick(value, ["name", "category", "unitPrice"]),
      };

    default:
      return { defaultValues, format };
  }
}

export function ManualUpdate({
  queryType,
  onClose,
  selected,
  handleUpdate,
}: ManualUpdateModalProps) {
  const [isSubmiting, setIsSubmiting] = useState(false);

  const form = manualForm[queryType] || { items: [] };
  const items: FormBuilderProps["items"] = form.items || [];
  const { defaultValues, format } = getAssetEditDefaultValue(
    queryType as AssetType,
    selected as EditAssetParams
  );
  const { t } = useTranslation();

  const handleSumbit = async (input: FieldValues) => {
    try {
      const query = manualFormCRUDHandler[queryType];

      if (!query || isSubmiting || !selected) return;
      setIsSubmiting(true);

      if (format) input = format(input);

      await updateAssetTypeMutation(queryType as AssetType, {
        id: selected.id,
        input,
      });

      onClose();
      handleUpdate?.();
      setIsSubmiting(false);
    } catch (error) {
      setIsSubmiting(false);
      console.error("ManualAdd: ", { error });
    }
  };

  return (
    <Modal open={true} setOpen={onClose}>
      <FormBuilder
        {...form}
        action={({ submit }) => (
          <button
            type="submit"
            onClick={submit}
            style={{
              backgroundColor: getAssetTypeColor(queryType as AssetType).color,
            }}
            className="mx-4 items-center truncate text-clip rounded-md border border-transparent bg-asset px-4 py-2 font-medium text-white"
          >
            {t("actions.update")}
          </button>
        )}
        items={items?.map((item) => ({
          ...item,
          children: item.children?.filter((child) =>
            isFn(child)
              ? !exludedFields.includes(
                  child({
                    props: {
                      type: queryType,
                    },
                  })?.name
                )
              : !exludedFields.includes(child.name)
          ),
        }))}
        defaultValues={defaultValues}
        onSubmit={handleSumbit}
        props={{
          type: queryType,
        }}
      />
    </Modal>
  );
}
