import { FC } from "react";

import { Fieldset } from "./fieldset";
import { Standard } from "./standard";
import { InputPropsTypes } from "@types";
export { Incrementer } from "./incrementer";

export const Input: FC<InputPropsTypes> = ({
  variant = "standard",
  type = "text",
  ...props
}) => {
  switch (variant) {
    case "fieldset":
      return <Fieldset type={type} {...props} />;
    default:
      return <Standard type={type} {...props} />;
  }
};
