import { useQuery } from "react-query";
import { rangeForPeriod } from "../convinients";
import { wealthQuery } from "../../../screens/private/assetById/components/assets/Assets.logic";
import { Period } from "components";

export function useWealth(period: Period | null) {
  const range = period ? rangeForPeriod(period) : { start: null, end: null };

  return useQuery(["wealth", period], () =>
    wealthQuery(range.start, range.end)
  );
}
