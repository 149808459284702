import { FC } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import { routes } from "routing";
import { AssetType } from "@types";
import { InfoLine } from "../InfoLine";
import { getEconomyById } from "./Assets.logic";
import { useAssetActions } from "./AssetActions/AssetActions";
import { useDeleteAsset } from "core/helpers/hooks/useDeleteAsset";
import { ManualUpdateModal, ScrollableContainer } from "components";

type EconomyViewProps = {
  id: string;
};

const EconomyView: FC<EconomyViewProps> = ({ id }) => {
  const navigation = useNavigate();
  const informationActions = useAssetActions(["edit", "delete"], {
    onDelete: async () => deleteAsset(id),
  });

  const { mutate: deleteAsset } = useDeleteAsset(() => {
    navigation(routes.economies.path);
  });

  const economyQuery = useQuery(["economy", id], () => getEconomyById(id));

  if (!economyQuery.data) {
    return null;
  }

  return (
    <ScrollableContainer
      title="title.informations"
      Right={informationActions.render}
    >
      <div className="p-4 pt-0">
        <InfoLine
          label="formLabel.bankName"
          value={economyQuery.data.bankName}
        />
        <InfoLine label="formLabel.name" value={economyQuery.data.name} />
      </div>
      {!!informationActions.state.edit && (
        <ManualUpdateModal
          selected={economyQuery.data}
          editType={"update"}
          queryType={AssetType.economies}
          onClose={() => informationActions.dispatch({ type: "edit" })}
          handleUpdate={economyQuery.refetch}
          handleDelete={console.log}
          handleActivityDelete={console.log}
        />
      )}
    </ScrollableContainer>
  );
};

export default EconomyView;
