import { FC, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { clsx } from "core";
import { NormalizeTranslationKeys } from "i18n";
import { Container, Icon, Typo } from "components";

interface ModalProps {
  setOpen: (open: boolean | any) => void;
  open: boolean;
  className?: string;
  title?: NormalizeTranslationKeys;
}
export const Modal: FC<ModalProps> = ({
  open,
  title,
  children,
  className,
  setOpen,
}) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className={clsx("fixed inset-0 z-50 overflow-y-auto", className)}
        open={open}
        onClose={() => setOpen(false)}
      >
        <div className="flex min-h-screen items-center justify-center p-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block min-w-[450px] transform rounded-md bg-gray-1200 text-left align-bottom shadow-xl transition-all sm:my-8 sm:align-middle">
              <Container className="p-4 pt-0">
                <Typo className="py-2 text-lg font-bold" children={title} />
                <div>
                  <div className="absolute top-0 right-0 pt-4 pr-4 sm:block">
                    <Icon
                      type="close"
                      onClick={() => setOpen(false)}
                      className="h-4 w-4 cursor-pointer"
                    />
                  </div>
                  <div className="h-full w-full text-center sm:mt-0 sm:text-left">
                    <div className="flex h-full w-full flex-col rounded">
                      {children}
                    </div>
                  </div>
                </div>
              </Container>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
