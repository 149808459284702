import { FC, Fragment, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NormalizeTranslationKeys } from "i18n";

import { AssetType } from "@types";
import { useAppSelector } from "_redux";
import { Modal } from "../../../components/Modal";
import { Row } from "../../../components/AssetRow";
import { Loader } from "../../../components/Loader";
import { useFavoriteAssets } from "core/helpers/hooks";
import { generateNumberArray } from "core/helpers/array";
import { ScrollableContainer } from "../../../components/Container";
import { FavoriteAssets } from "screens/private/home/FavoriteAssets";
import { useAssetActions } from "../assetById/components/assets/AssetActions/AssetActions";
import {
  Repartition,
  RepartitionToggler,
} from "./components/repartitionToggler";
import { rangeForPeriod } from "core/helpers/convinients";

const AssetsTypeFilterMapping = {
  global: {
    filter: null,
  },
  financial: {
    filter: [
      AssetType.bankAccount,
      AssetType.crypto,
      AssetType.stocks,
      AssetType.economies,
      AssetType.lifeInsurance,
      AssetType.retirements,
      AssetType.crowdfunding,
    ] as AssetType[],
  },
} satisfies {
  [key in Repartition]: {
    filter: AssetType[] | null;
  };
};
interface FavoriteAssetsProps {
  hideContent?: boolean;
}
const FavoriteAssetsModule: FC<FavoriteAssetsProps> = ({
  hideContent = false,
}) => {
  const [repartition, setRepartition] = useState<Repartition>("global");
  const { state, render, dispatch } = useAssetActions(["repartition", "add"], {
    defaultState: {
      repartition: false,
    },
  });
  const { t } = useTranslation();

  const period = useAppSelector((state) => state.period.value);

  const queryPeriodArgs = rangeForPeriod(period);

  const { favoriteAssets, isLoading: favoriteAssetsLoading } =
    useFavoriteAssets(queryPeriodArgs.start, queryPeriodArgs.end);

  const formatedRowList = useMemo(() => {
    let total = 0;

    if (state.repartition && favoriteAssets) {
      total = favoriteAssets.reduce(
        (acc, asset) => acc + asset.amount.value,
        0
      );
    }

    return favoriteAssets
      ?.filter((wealth) => {
        if (wealth.type === AssetType.savings) return false;
        const filter = AssetsTypeFilterMapping[repartition].filter;

        if ((filter && filter.includes(wealth.type)) || !filter) return true;

        return false;
      })
      ?.map((asset) => {
        if (state.repartition) {
          const value = asset.amount.value ?? 0;

          return (
            <Link key={asset.type} to={`/${asset.type}`} className="my-1 block">
              <Row
                title={
                  t(`title.${asset.type}` as NormalizeTranslationKeys) as string
                }
                value={asset.amount.value}
                assetType={asset.type}
                percent={value / total}
                type="repartition"
              />
            </Link>
          );
        }

        return (
          <Link key={asset.type} to={`/${asset.type}`}>
            <Row
              id={asset.type}
              className="px-0"
              type="evolution"
              assetType={asset.type}
              value={asset.amount.value}
              instrument={asset.performance.gain.instrument}
              gain={asset.performance.gain.value}
              evolution={asset.performance.evolution || 0}
            />
          </Link>
        );
      });
  }, [favoriteAssets, state.repartition, repartition, period]);

  return (
    <>
      <ScrollableContainer
        title="title.favorite_assets"
        height={"auto"}
        Right={render}
        hideContent={hideContent}
        SubHead={
          state.repartition ? (
            <RepartitionToggler value={repartition} onClick={setRepartition} />
          ) : undefined
        }
        onTitleClick={() =>
          dispatch({
            type: "add",
          })
        }
      >
        {favoriteAssetsLoading ? (
          <div className="min-h-[200px]">
            <FavoriteSkeleton />
          </div>
        ) : favoriteAssets && favoriteAssets?.length > 0 ? (
          <div id="favorite-assets" className="px-3">
            {formatedRowList}
          </div>
        ) : null}
      </ScrollableContainer>
      <Modal
        open={!!state.add}
        setOpen={() =>
          dispatch({
            type: "add",
          })
        }
        title="title.favorite_assets"
      >
        <FavoriteAssets />
      </Modal>
    </>
  );
};

function FavoriteSkeleton() {
  return (
    <Loader className="min-h-[200px]">
      {generateNumberArray(4).map((i) => {
        const yOffset = i * 60; // 16px space between each set

        return (
          <Fragment key={i}>
            <circle cx="31" cy={22 + yOffset} r="22" />
            <rect x="64" y={yOffset} rx="2" ry="2" width="99" height="15" />
            <rect
              x="64"
              y={22 + yOffset}
              rx="2"
              ry="2"
              width="99"
              height="15"
            />
            <rect x="195" y={yOffset} rx="2" ry="2" width="99" height="15" />
            <rect
              x="195"
              y={22 + yOffset}
              rx="2"
              ry="2"
              width="99"
              height="15"
            />
          </Fragment>
        );
      })}
    </Loader>
  );
}

export default FavoriteAssetsModule;
