import { useState } from "react";
import { PlusIcon, MinusIcon } from "@heroicons/react/solid";

export const Incrementer = ({}) => {
  const [num, setNum] = useState(0);

  return (
    <div className="mb-4 flex gap-x-2">
      <input
        type="text"
        className="block rounded-md border-primary-200 shadow-sm focus:border-secondary-500 focus:ring-indigo-500"
        value={num}
      />
      <div className="my-auto -ml-20">
        <button
          className="-ml-1 mr-1 h-8 rounded-md border-[0.5px] border-primary-200 bg-white px-2 shadow-sm hover:bg-gray-50"
          onClick={() => setNum(num + 1)}
        >
          <PlusIcon className="w-4" />
        </button>
        <button
          className="h-8 rounded-md border-[0.5px] border-primary-200 bg-white px-2 shadow-sm hover:bg-gray-50"
          onClick={num >= 1 ? () => setNum(num - 1) : () => setNum(0)}
        >
          <MinusIcon className="w-4" />
        </button>
      </div>
    </div>
  );
};
